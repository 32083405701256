import React, { useEffect, useRef, useState } from "react";
import {
  Typography,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Switch,
  Tooltip,
  Autocomplete,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CardContent,
  useTheme,
  Card,
  IconButton,
} from "@mui/material";
import { Brightness1 as ViewColorIcon } from "@mui/icons-material";
const DrawingComponent = (props) => {
  const canvasRef = useRef(null);
  const [isDrawing, setIsDrawing] = useState(false);
  const [imageUri, setImageUri] = useState(null);
  const [imageLoaded, setImageLoaded] = useState(null);
  const [lineColor, setLineColor] = useState("red");
  useEffect(() => {
    if (props?.selectedImageUri) setImageUri(props?.selectedImageUri);
  }, [props.selectedImageUri]);
  useEffect(() => {
    const canvas = canvasRef.current;
    if (imageUri && props?.selectedImageUri && canvas) {
      const context = canvas.getContext("2d");
      const imageObj = new Image();
      imageObj.src = URL.createObjectURL(props?.selectedImageUri);
      imageObj.onload = () => {
        context.drawImage(imageObj, 0, 0, 600, 400);
        //   draw(context);
      };
    }
  }, [imageUri, canvasRef, imageLoaded]);

  const startDrawing = (event) => {
    setIsDrawing(true);
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    context.beginPath();
    context.moveTo(x, y);
  };

  const draw = (event) => {
    if (!isDrawing) return;
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    context.strokeStyle = lineColor;
    context.lineTo(x, y);
    context.stroke();
  };

  const endDrawing = () => {
    setIsDrawing(false);
  };

  const clearCanvas = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.clearRect(0, 0, canvas.width, canvas.height);
    setImageLoaded(!imageLoaded);
  };

  const saveCanvas = () => {
    if (!canvasRef.current) {
      return;
    }
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    const imageData = context.getImageData(0, 0, canvasRef.current.width, canvasRef.current.height);
    const combinedCanvas = document.createElement("canvas");
    combinedCanvas.width = canvasRef.current.width;
    combinedCanvas.height = canvasRef.current.height;
    const combinedContext = combinedCanvas.getContext("2d");
    // Create a new image element to load the original image
    // Draw the original image on the new canvas
    const imageObj = new Image();
    imageObj.src = URL.createObjectURL(props.selectedImageUri);
    imageObj.onload = () => {
      combinedContext.drawImage(imageObj, 0, 0);
      // Draw the user's drawing on top of the image
      combinedContext.putImageData(imageData, 0, 0);
      // Convert canvas data to blob
      combinedCanvas.toBlob((blob) => {
        // Create a File object from the blob
        const randomNumber = Math.random().toString(36).slice(2);
        const lastIndex = props?.selectedImageUri?.name.lastIndexOf(".");

        const fileName = lastIndex === -1 ? "image_with_drawing" : props?.selectedImageUri.name.slice(0, lastIndex);
        const file = new File([blob], (randomNumber || "pic_") + "with_drawing_" + (fileName + ".png"), {
          type: "image/jpeg",
        });
        // Now you can use the 'file' object as needed, for example, to upload it to a server
        setIsDrawing(false);
        setImageUri(null);
        props.onClickDoneEditing(file);
      }, "image/png");
    };
  };
  const handleColorSelection = (colorItem) => {
    setLineColor(colorItem);
  };

  const cancelSelection = () => {
    setIsDrawing(false);
    setImageUri(null);
    props.closeDrawingComponent();
  };

  return (
    <div>
      <Dialog
        open={props.isDrawingComponentOpen}
        PaperProps={{
          style: {
            maxWidth: "1100px",
            minWidth: "500px",
          },
        }}
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
      >
        <div style={{ paddingLeft: 40, paddingRight: 40, paddingTop: 20, paddingBottom: 20 }}>
          {props?.selectedImageUri && (
            <canvas
              ref={canvasRef}
              width={600}
              height={400}
              onMouseDown={(event) => startDrawing(event)}
              onMouseUp={() => endDrawing()}
              onMouseMove={(event) => draw(event)}
            />
          )}
        </div>
        <Stack pb={2}>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} px={2}>
            <Typography variant="subtitle1">Draw over image and click {props?.buttonText || "Done"} to save.</Typography>
            <Stack direction={"row"}>
              <Button onClick={() => cancelSelection()}>Cancel</Button>
              <Button onClick={() => clearCanvas()}>Clear</Button>
              <Button onClick={() => saveCanvas()}>{props?.buttonText || "Done"}</Button>
            </Stack>
          </Stack>
          <Stack direction={"row"} justifyContent={"center"} sx={{ backgroundColor: "#00000040", py: 1 }}>
            {["red", "black", "green", "blue", "white"].map((colorItem) => (
              <Button
                variant={colorItem === lineColor ? "outlined" : "text"}
                startIcon={<ViewColorIcon style={{ color: colorItem, marginLeft: 15 }} />}
                onClick={() => handleColorSelection(colorItem)}
                style={{ borderColor: colorItem, borderWidth: 2, padding: 1, width: 30 }}
              ></Button>
            ))}
          </Stack>
        </Stack>
      </Dialog>
    </div>
  );
};

export default DrawingComponent;
