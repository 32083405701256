import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  HomeOutlined,
  PictureInPicture,
  List as ListIcon,
  ListAlt,
  Task,
  Schedule,
  WebAsset,
  VerifiedUser,
  People,
  Build,
  Shop,
  Recommend as RecommendIcon,
  AddAlert as ActionRequiredIcon,
  LocalOffer as AssignTechnicianIcon,
  CrisisAlert as PendingIcon,
  AutoAwesomeMosaic as MachinModelIcon,
} from "@mui/icons-material";

import { Box, Drawer, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography, useTheme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import styled from "@emotion/styled";
import AssignmentRoundedIcon from "@mui/icons-material/AssignmentRounded";
import BarChartIcon from "@mui/icons-material/BarChart";
import TimelineIcon from "@mui/icons-material/Timeline";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Links } from "utils/pageLinkNames";
import config from "app/config";
import sideBarCss from "stylesheet/appStyles.module.css";
import MoreTimeOutlinedIcon from "@mui/icons-material/MoreTimeOutlined";

const navItems = [
  // Dashboards
  {
    text: "Dashboards",
  },
  // Plant Overview
  {
    text: "Plant Overview",
    navigateTo: Links.PLANT_OVERVIEW,
    icon: <AssignmentRoundedIcon />,
  },
  // Breakdown Hours
  {
    text: "Breakdown Hours",
    navigateTo: Links.BREAKDOWN,
    icon: <TimelineIcon />,
  },
  // Tickets Overview
  {
    text: "Tickets Overview",
    navigateTo: Links.SERVICE_DASHBOARD,
    icon: <HomeOutlined />,
  },
  // Service Tickets
  {
    text: "Service Tickets",
  },
  {
    text: "Active",
    navigateTo: Links.ASSIGN_TECHNICIAN_SERVICE_TICKETS,
    icon: <AssignTechnicianIcon />,
  },
  {
    text: "Action Required",
    navigateTo: Links.ACTION_REQUIRED_SERVICE_TICKETS,
    icon: <ActionRequiredIcon />,
  },
  {
    text: "Pending",
    navigateTo: Links.PENDING_SERVICE_TICKETS,
    icon: <PendingIcon />,
  },

  {
    text: "Periodic",
    navigateTo: Links.PERIODIC_SERVICE_TICKETS,
    icon: <ListIcon />,
  },
  {
    text: "One Time",
    navigateTo: Links.ONE_TIME_SERVICE_TICKETS,
    icon: <MoreTimeOutlinedIcon />,
  },
  // Data
  {
    text: "Data",
  },
  {
    text: "Reports",
    navigateTo: Links.REPORTS,
    icon: <Task />,
  },
  {
    text: "Scheduling",
    navigateTo: Links.SCHEDULING,
    icon: <Schedule />,
  },
  {
    text: "Assets",
    navigateTo: Links.ASSETS,
    icon: <WebAsset />,
  },
  {
    text: "Users",
    navigateTo: Links.USER,
    icon: <People />,
  },
  {
    text: "Vendors",
    navigateTo: Links.VENDORS,
    icon: <Shop />,
  },
  {
    text: "Spare Parts",
    navigateTo: Links.INVENTORY,
    icon: <ListAlt />,
  },
  {
    text: "Locations",
    navigateTo: Links.LOCATIONS,
    icon: <LocationOnIcon />,
  },
  {
    text: "Templates",
    navigateTo: Links.TEMPLATES,
    icon: <PictureInPicture />,
  },
  {
    text: "Machine Models",
    navigateTo: Links.MACHINE_MODELS,
    icon: <MachinModelIcon />,
  },
];

const navItemsFinance = [
  // Finance role navigation items...
  {
    text: "Data",
  },
  {
    text: "Assets",
    navigateTo: Links.ASSETS,
    icon: <WebAsset />,
  },
];

const navItemsSvcTech = [
  // Svc Technician role navigation items...
  {
    text: "Data",
  },
  {
    text: "Service Tickets",
    navigateTo: Links.ALL_SERVICE_TICKETS,
    icon: <WebAsset />,
  },
];

const navItemsStoresUser = [
  // Stores user role navigation items...
  {
    text: "Service Tickets",
  },
  {
    text: "Assigned",
    navigateTo: Links.ASSIGNED,
    icon: <ActionRequiredIcon />,
  },
  {
    text: "Spares Required",
    navigateTo: Links.SPARES_REQUIRED,
    icon: <ActionRequiredIcon />,
  },
  {
    text: "Spares Recommended",
    navigateTo: Links.SPARES_RECOMMENDED,
    icon: <RecommendIcon />,
  },

  {
    text: "Data",
  },
  {
    text: "Spare Parts",
    navigateTo: Links.INVENTORY,
    icon: <ListAlt />,
  },
];

const navItemsIt = [
  // IT role navigation items...
  {
    text: "Data",
  },
  {
    text: "Users",
    navigateTo: Links.USER,
    icon: <People />,
  },
];

const Container = styled("Box")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

const Sidebar = (props) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const { appConstants } = useSelector((state) => state.service);
  const { user } = useSelector((state) => state.auth);
  const { mode } = useSelector((state) => state.globalState);

  let navigate = useNavigate();

  const theme = useTheme();
  const [active, setActive] = useState("");

  useEffect(() => {
    setActive(pathname.substring(1));
  }, [pathname]);

  const onClickItem = (navigateTo) => {
    navigate(`/${navigateTo}`);
    setActive(navigateTo);
  };

  return (
    <div>
      <Box component="nav" sx={{ width: { sm: props.drawerWidth }, flexShrink: { sm: 0 } }} aria-label="mailbox folders">
        <Drawer
          open
          variant="permanent"
          anchor="left"
          transitionDuration={500}
          sx={{
            display: { xs: "none", sm: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: props.drawerWidth,
              backgroundColor: theme.palette.appColors.navBarBackground,
            },
          }}
        >
          <Box width="100%" height="100%">
            <Box m="1.5rem 0rem 0rem 1rem">
              <Container>
                <div class="notranslate">
                  <Typography variant="h2" fontWeight="bold" sx={{ color: theme.palette.appColors.sideBarAppLogo }}>
                    {config?.appName || ""}
                  </Typography>
                </div>
              </Container>
            </Box>
            <List>
              {user.roles[0] === appConstants?.allTypes?.USER_ROLES.ROLE_MAINTENANCE_SUPERVISOR && (
                <>
                  {navItems.map(({ text, icon, navigateTo }) => (
                    <ListItem key={text} disablePadding>
                      <ListItemButton
                        onClick={() => (navigateTo ? onClickItem(navigateTo) : null)}
                        sx={{
                          py: 0.7,
                          pr: "2rem",
                          backgroundColor: active === navigateTo ? theme.palette.secondary[300] : "transparent",
                          color: active === navigateTo ? "#fff" : theme.palette.secondary[100],
                        }}
                      >
                        {icon && (
                          <ListItemIcon
                            sx={[
                              {
                                ml: "1rem",
                                mr: -2,
                                color: theme.palette.appColors.sideBarText,
                              },
                            ]}
                          >
                            {icon}
                          </ListItemIcon>
                        )}
                        <ListItemText
                          primary={text}
                          primaryTypographyProps={{ variant: "body1", style: { fontWeight: !icon ? "bold" : "normal" } }}
                          sx={{
                            mb: 1,
                            color: active === navigateTo ? "#fff" : theme.palette.appColors.sideBarText,
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </>
              )}
              {user.roles[0] === appConstants?.allTypes?.USER_ROLES.ROLE_FINANCE && (
                <>
                  {navItemsFinance.map(({ text, icon, navigateTo }) => (
                    <ListItem key={text} disablePadding>
                      <ListItemButton
                        onClick={() => (navigateTo ? onClickItem(navigateTo) : null)}
                        sx={{
                          py: 0.7,
                          pr: "2rem",
                          backgroundColor: active === navigateTo ? theme.palette.secondary[300] : "transparent",
                          color: active === navigateTo ? "white" : theme.palette.secondary[100],
                        }}
                      >
                        {icon && (
                          <ListItemIcon
                            sx={[
                              {
                                ml: "1rem",
                                mr: -2,
                                color: theme.palette.appColors.sideBarText,
                              },
                            ]}
                          >
                            {icon}
                          </ListItemIcon>
                        )}
                        <ListItemText
                          primary={text}
                          primaryTypographyProps={{ variant: "body1", style: { fontWeight: !icon ? "bold" : "normal" } }}
                          sx={[{ marginRight: "50px", color: active === navigateTo ? "#fff" : theme.palette.appColors.sideBarText }]}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </>
              )}
              {user.roles[0] === appConstants?.allTypes?.USER_ROLES.ROLE_STORES && (
                <div style={{ marginTop: 20 }}>
                  {navItemsStoresUser.map(({ text, icon, navigateTo }) => (
                    <ListItem key={text} disablePadding sx={{ mt: 1 }}>
                      <ListItemButton
                        onClick={() => (navigateTo ? onClickItem(navigateTo) : null)}
                        sx={{
                          py: 0.7,
                          pr: "2rem",
                          backgroundColor: active === navigateTo ? theme.palette.secondary[300] : "transparent",
                          color: active === navigateTo ? "#fff" : theme.palette.secondary[100],
                        }}
                      >
                        {icon && (
                          <ListItemIcon
                            sx={[
                              {
                                ml: "1rem",
                                mr: -2,
                                color: theme.palette.appColors.sideBarText,
                              },
                            ]}
                          >
                            {icon}
                          </ListItemIcon>
                        )}
                        <ListItemText
                          primary={text}
                          primaryTypographyProps={{ variant: "body1", style: { fontWeight: !icon ? "bold" : "normal" } }}
                          sx={{ color: active === navigateTo ? "#fff" : theme.palette.appColors.sideBarText }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </div>
              )}
              {user.roles[0] === appConstants?.allTypes?.USER_ROLES.ROLE_IT && (
                <>
                  {navItemsIt.map(({ text, icon, navigateTo }) => (
                    <ListItem key={text} disablePadding>
                      <ListItemButton
                        onClick={() => (navigateTo ? onClickItem(navigateTo) : null)}
                        sx={{
                          py: 0.7,
                          pr: "2rem",
                          backgroundColor: active === navigateTo ? theme.palette.secondary[300] : "transparent",
                          color: active === navigateTo ? "white" : theme.palette.secondary[100],
                        }}
                      >
                        {icon && (
                          <ListItemIcon
                            sx={[
                              {
                                ml: "1rem",
                                mr: -2,
                                color: theme.palette.appColors.sideBarText,
                              },
                            ]}
                          >
                            {icon}
                          </ListItemIcon>
                        )}
                        <ListItemText
                          primary={text}
                          primaryTypographyProps={{ variant: "body1", style: { fontWeight: !icon ? "bold" : "normal" } }}
                          sx={[{ marginRight: "50px", color: active === navigateTo ? "#fff" : theme.palette.appColors.sideBarText }]}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </>
              )}
              {user.roles[0] === appConstants?.allTypes?.USER_ROLES.ROLE_SERVICE_TECHNICIAN && (
                <>
                  {navItemsSvcTech.map(({ text, icon, navigateTo }) => (
                    <ListItem key={text} disablePadding>
                      <ListItemButton
                        onClick={() => (navigateTo ? onClickItem(navigateTo) : null)}
                        sx={{
                          py: 0.7,
                          pr: "2rem",
                          backgroundColor: active === navigateTo ? theme.palette.secondary[300] : "transparent",
                          color: active === navigateTo ? "#fff" : theme.palette.secondary[100],
                        }}
                      >
                        {icon && (
                          <ListItemIcon
                            sx={[
                              {
                                ml: "1rem",
                                mr: -2,
                                color: theme.palette.appColors.sideBarText,
                              },
                            ]}
                          >
                            {icon}
                          </ListItemIcon>
                        )}
                        <ListItemText
                          primary={text}
                          primaryTypographyProps={{ variant: "body1", style: { fontWeight: !icon ? "bold" : "normal" } }}
                          sx={{
                            mb: 1,
                            color: active === navigateTo ? "#fff" : theme.palette.appColors.sideBarText,
                          }}
                        />
                      </ListItemButton>
                    </ListItem>
                  ))}
                </>
              )}
            </List>
          </Box>
        </Drawer>
      </Box>
    </div>
  );
};

export default Sidebar;
