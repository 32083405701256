import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import { TimespentByServicetTicketTableAttributes } from "data/dataTovisualize";
import { readTimespentByServicetTicketId } from "features/service/service.slice";
import { OutlinedInput, IconButton, InputAdornment, Box, Stack } from "@mui/material";
import { Search } from "@mui/icons-material";

import moment from "moment";
import DataGridTable from "component/DataGridTable";
import Header from "component/Header";

const TimespentByServicetTicket = () => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  const { timespentByServicetTicket } = useSelector((state) => state.service);
  const [serviceTickets, setServiceTickets] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };
  useEffect(() => {
    if (timespentByServicetTicket) {
      const modified = timespentByServicetTicket.map((ticket) => {
        return {
          ...ticket,
          dateCreated: moment(ticket.dateCreated).format("DD MMM YYYY"),
          scheduledDate: moment(ticket.scheduledDate).format("DD MMM YYYY"),
        };
      });
      setServiceTickets(modified?.sort((a, b) => b.ticketId - a.ticketId) || []);
    }
  }, [timespentByServicetTicket]);

  useEffect(() => {
    dispatch(readTimespentByServicetTicketId(state.initialDateRange));
  }, [dispatch]);

  return (
    <div>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header titles={[`Time spent on tickets for last ${state.initialDateRange} day${state.initialDateRange === 1 ? "" : "s"}`]} />
      </Stack>
      <Box sx={{ mx: 2 }}>
        <Stack direction={"row"} gap={1} alignItems={"center"} my={2}>
          <OutlinedInput
            id="outlined-adornment-password"
            value={searchValue}
            onChange={(event) => handleSearchChange(event)}
            placeholder="Search..."
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" edge="end">
                  <Search />
                </IconButton>
              </InputAdornment>
            }
            sx={{ minWidth: 300, maxWidth: 600, height: 35 }}
          />
        </Stack>
        <DataGridTable
          rowId={"ticketId"}
          data={serviceTickets.filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchValue.toLowerCase()))}
          columnAttributes={TimespentByServicetTicketTableAttributes}
        />
      </Box>
    </div>
  );
};

export default TimespentByServicetTicket;
