import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import {
  Card,
  Stack,
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField,
  IconButton,
  DialogTitle,
  useTheme,
  Checkbox,
  Tooltip,
  Paper,
  Box,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { Add as AddIcon, Edit as EditIcon } from "@mui/icons-material";
import { Links } from "utils/pageLinkNames";
import {
  getAllTemplates,
  getServiceTicketById,
  completeServiceTicket,
  readServiceTicketMessages,
  updateTemplate,
} from "features/service/service.slice";
import Header from "component/Header";
import TemplateForm from "../TemplateForm";
import EquipmentSupportForm from "../EquipmentSupportForm";
import moment from "moment";

const SubmitTicketForm = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { ticketId } = useParams();
  useEffect(() => {
    dispatch(getAllTemplates());
    dispatch(getServiceTicketById(ticketId));
    dispatch(readServiceTicketMessages(ticketId));
  }, [dispatch]);
  const { allTemplates, serviceTicketById, appConstants, serviceTicketMessages, isLoading } = useSelector((state) => state.service);
  const { mode } = useSelector((state) => state.globalState);
  const { user } = useSelector((state) => state.auth);

  const [ticketFormData, setTicketFormData] = useState(null);
  const [templateId, setTemplateId] = useState(null);
  const [recommendation, setRecommendation] = useState(null);
  const [showCompleteTicketModal, setShowCompleteTicketModal] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  useEffect(() => {
    if (serviceTicketById?.additionalNotes?.templateId) setTemplateId(serviceTicketById?.additionalNotes?.templateId);
  }, [serviceTicketById]);

  const submitServiceTicket = () => {
    setSubmitDisabled(true);
    // for (let item of allTemplates.data) {
    //   const templateSections = item.sections.map((sitem) => {
    //     const randomSectId = Math.floor(Math.random() * (999999 - 100000 + 1)) + 100000;
    //     return {
    //       ...sitem,
    //       tasks: sitem?.tasks?.length
    //         ? sitem.tasks.map((titem) => {
    //             const randomTaskId = Math.floor(Math.random() * (9999999 - 1000000 + 1)) + 1000000;
    //             return {
    //               ...titem,
    //               id: randomTaskId,
    //             };
    //           })
    //         : [],
    //       id: randomSectId,
    //     };
    //   });
    //   const temObj = {
    //     active: true,
    //     name: item.name,
    //     templateId: item.id,
    //     sections: templateSections,
    //     periodicity: item?.periodicity || appConstants?.periodicity.none,
    //     vendor: item.vendor,
    //     modelId: item.modelId ? item.modelId.id : null,
    //   };
    //   console.log(temObj);
    //   const formDataToPost = new FormData();
    //   formDataToPost.append("template", JSON.stringify(temObj));
    //   dispatch(updateTemplate(formDataToPost)).then((res) => console.log(res));
    // }

    const signedByUser = appConstants?.appUsers.find((item) => item.userName === user.username);
    const formData = {
      serviceTicketId: serviceTicketById.id,
      recommendation,
      sparesRequired: false,
      sparesRecommended: false,
      vendorSupport: false,
      signedBy: signedByUser.name,
      signedByUserName: signedByUser.userName,
      designation: signedByUser.userRole,
      userMobileNumber: signedByUser.userMobileNumber,
      complaintStatus: appConstants.allTypes.SERVICE_TICKET_STATUS.COMPLETED,
    };
    if (ticketFormData?.name !== "Equipment Support Form" && ticketFormData.id) {
      const errMsg = {};
      const templateSections = ticketFormData.sections.map((section) => {
        const updatedTasks = section.tasks.map((item) => {
          if (item?.images?.length) {
            const taskTktImages = serviceTicketMessages.filter((tktMsg) => {
              const templateInfo = tktMsg.templateInfo ? JSON.parse(tktMsg.templateInfo) : null;
              return templateInfo && templateInfo.taskId == item.id && templateInfo.sectionId == section.id;
            });
            if (!taskTktImages.length) errMsg[section.id + "" + item.id] = "Please add an image!";
          }
          if (item.reason) formData.complaintStatus = appConstants.allTypes.SERVICE_TICKET_STATUS.NOT_COMPLETED;
          return {
            remarks: item.remarks || "",
            reason: item.taskStatus === appConstants?.taskStatus.NOT_ACCEPTABLE ? item.reason : null,
            task: item.task,
            taskStatus: item.taskStatus ? item.taskStatus : appConstants?.taskStatus.ACCEPTABLE,
            order: parseInt(item.index) + 1,
          };
        });
        return {
          ...section,
          tasks: updatedTasks,
        };
      });
      if (errMsg && Object.keys(errMsg).length) {
        setErrorMsg(errMsg);
        setShowCompleteTicketModal(false);
        setSubmitDisabled(false);
        return toast.warn("Please add an image!");
      }

      formData.templateId = ticketFormData.id;
      formData.templateName = ticketFormData.name;
      formData.sections = templateSections;
      formData.complaint = serviceTicketById.ticketCategory;
    } else {
      if (
        !ticketFormData?.complaint ||
        ticketFormData?.resolution ||
        !ticketFormData?.complaint.trim() ||
        !ticketFormData?.resolution.trim()
      )
        return toast.warn("Please enter required fields!", {
          autoClose: 1000,
        });
      formData.complaint = ticketFormData.complaint;
      formData.resolution = ticketFormData.resolution;
    }
    formData.signedDate = moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ssZ");
    const reportObj = new FormData();
    reportObj.append("report", JSON.stringify(formData));
    reportObj.append("serviceTicketId", "" + serviceTicketById.id);
    dispatch(completeServiceTicket(reportObj)).then((res) => {
      setSubmitDisabled(false);
      toast.success("Service ticket completed.", {
        autoClose: 1000,
      });
      navigate(`/${Links.SERVICE_TICKET}/` + serviceTicketById.id);
    });
  };
  const updateData = (data) => {
    setTicketFormData(data);
  };
  return (
    <div>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header titles={[`Ticket Report`, "Ticket : " + serviceTicketById.id, ticketFormData?.name || ""]} />
      </Stack>
      <div>
        <Dialog open={showCompleteTicketModal}>
          <DialogTitle sx={{ mt: 2, mx: 1 }} fontWeight={"bold"}>
            Confirm
          </DialogTitle>
          <DialogContent sx={{ mx: 1 }}>
            <Typography mb={1}>
              Please click <span style={{ fontWeight: "bold" }}>"BACK"</span>, if you need to cross check the data you entered.
            </Typography>
            <Typography>
              Please click <span style={{ fontWeight: "bold" }}>"SUBMIT"</span> to complete the ticket.
            </Typography>
          </DialogContent>
          <Stack direction={"row"} justifyContent={"flex-end"} columnGap={2} mb={2} px={2}>
            <Button
              variant="contained"
              onClick={() => {
                setSubmitDisabled(false);
                setShowCompleteTicketModal(false);
              }}
            >
              Back
            </Button>
            <Button variant="contained" disabled={submitDisabled} onClick={() => submitServiceTicket()}>
              Submit
            </Button>
          </Stack>
        </Dialog>
      </div>
      <Stack sx={{ mr: 5, mb: 1, pl: 2 }}>
        <Card variant="outlined" sx={{ p: 3 }}>
          <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} mr={1} mb={2}>
            <TextField
              fullWidth
              // value={""}
              sx={{ minWidth: 350, maxWidth: 600 }}
              onChange={(e) => setRecommendation(e.target.value)}
              label="Recommendation"
            />
            {!serviceTicketById?.additionalNotes?.templateId && (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                onChange={(event, newValue) => {
                  if (newValue.name !== "Equipment Support Form") setTemplateId(newValue.id);
                  else setTemplateId(null);
                  updateData(newValue);
                }}
                isOptionEqualToValue={(option, value) => option.name == value.name}
                getOptionLabel={(option) => option.name}
                options={
                  [{ name: "Equipment Support Form" }, ...allTemplates?.data.filter((item) => item.active)].sort((a, b) =>
                    a.name.localeCompare(b.name)
                  ) || []
                }
                sx={{ minWidth: 350, maxWidth: 600 }}
                renderInput={(params) => <TextField {...params} label="Template" />}
              />
            )}
            <Button
              variant="contained"
              disabled={isLoading}
              onClick={() => {
                if (ticketFormData?.name === "Equipment Support Form") {
                  if (
                    !ticketFormData?.complaint ||
                    !ticketFormData?.resolution ||
                    !ticketFormData?.complaint.trim() ||
                    !ticketFormData?.resolution.trim()
                  )
                    return toast.warn("Please enter required fields!", {
                      autoClose: 1000,
                    });
                } else setShowCompleteTicketModal(true);
              }}
            >
              Continue
            </Button>
          </Stack>
          {templateId && (
            <TemplateForm errorMsg={errorMsg} templateInfo={ticketFormData} updateTemplateInfo={updateData} templateId={templateId} />
          )}
          {ticketFormData?.name === "Equipment Support Form" &&
            serviceTicketById.ticketCategory === appConstants?.allTypes?.SERVICE_CATEGORY.EQUIPMENT_SUPPORT && (
              <EquipmentSupportForm ticketFormData={ticketFormData} updateEqspData={updateData} />
            )}
        </Card>
      </Stack>
    </div>
  );
};

export default SubmitTicketForm;
