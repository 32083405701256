import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import * as Yup from "yup";

import { Box, Checkbox, FormControlLabel, IconButton, InputAdornment, Stack, TextField, Alert, useTheme } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { Icon } from "@iconify/react";
import { motion } from "framer-motion";
import { login } from "features/auth/auth.slice";
import { useSelector, useDispatch } from "react-redux";
import { Links } from "utils/pageLinkNames";

let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
  opacity: 1,
  y: 0,
  transition: {
    duration: 0.6,
    ease: easing,
    delay: 0.16,
  },
};

const LoginForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { appConstants, isLoading } = useSelector((state) => state.service);

  const theme = useTheme();
  const location = useLocation();
  const [authAlert, setauthAlert] = useState(false);
  const from = location.state?.from?.pathname || `/${Links.PLANT_OVERVIEW}`;

  const [showPassword, setShowPassword] = useState(false);


  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      // .email("Provide a valid email address")
      .required("User name is required"),
    password: Yup.string().required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
      remember: true,
    },
    validationSchema: LoginSchema,
    onSubmit: async (values) => {
      const resp = await dispatch(login({ username: values.email.toLowerCase().trim(), password: values.password.trim() }));
      if (resp.meta.requestStatus === "fulfilled") {
        if (user.roles[0] = appConstants?.allTypes?.USER_ROLES?.ROLE_MAINTENANCE_SUPERVISOR) {
          navigate(from, { replace: true });
        } else if (user.roles[0] = appConstants?.allTypes?.USER_ROLES?.ROLE_FINANCE) {
          navigate(Links.ASSETS)
        } else if (user.roles[0] = appConstants?.allTypes?.USER_ROLES?.ROLE_STORES) {
          navigate(Links.SPARES_REQUIRED)
        } else if (user.roles[0] = appConstants?.allTypes?.USER_ROLES?.ROLE_IT) {
          navigate(Links.USER)
        } else if (user.roles[0] = appConstants?.allTypes?.USER_ROLES?.ROLE_SERVICE_TECHNICIAN) {
          navigate(Links.ALL_SERVICE_TICKETS)
        }
      } else {
        setauthAlert(true);
      }
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box
          component={motion.div}
          animate={{
            transition: {
              staggerChildren: 0.55,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 3,
            }}
            component={motion.div}
            initial={{ opacity: 0, y: 40 }}
            animate={animate}
          >
            <TextField
              fullWidth
              autoComplete="username"
              type="text"
              label="User Name"
              {...getFieldProps("email")}
              error={Boolean(touched.email && errors.email)}
              helperText={touched.email && errors.email}
              inputProps={{ style: { color: "black" } }}
            />

            <TextField
              fullWidth
              autoComplete="current-password"
              type={showPassword ? "text" : "password"}
              label="Password"
              {...getFieldProps("password")}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setShowPassword((prev) => !prev)}>
                      {showPassword ? <Icon icon="eva:eye-fill" /> : <Icon icon="eva:eye-off-fill" />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Box>

          <Box component={motion.div} initial={{ opacity: 0, y: 20 }} animate={animate}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ my: 2 }}>
              {/* <FormControlLabel control={<Checkbox {...getFieldProps("remember")} checked={values.remember} />} label="Remember me" /> */}

              {/* <Link
                component={RouterLink}
                variant="subtitle2"
                to="#"
                underline="hover"
              >
                Forgot password?
              </Link> */}
            </Stack>

            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              loading={isSubmitting}
              sx={{ color: "white", fontWeight: "bold" }}
            >
              {isSubmitting ? "loading..." : "Login"}
            </LoadingButton>
            {authAlert ? <Alert severity="error">Username or password is incorrect!</Alert> : null}
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
};

export default LoginForm;
