import React, { useState, useEffect } from "react";

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  CircularProgress,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  Stack,
  Box,
  Link,
  useTheme,
  Tooltip,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { ZoomIn as ViewIcon, Edit as EditIcon } from "@mui/icons-material";

import { getMachines, deleteMachine, readAsyncStorageValues } from "features/service/service.slice";
import "../../../App.css";
import "../../../stylesheet/charts.css";
import { Links } from "utils/pageLinkNames";
import Header from "component/Header";
import DataGridTable from "component/DataGridTable";
import QRCodeIcon from "@mui/icons-material/QrCode";
import jsPDF from "jspdf";
import { LinearProgress } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import { exportToCsv } from "utils/utilFunctions";

const Machines = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { appConstants } = useSelector((state) => state.service);
  const { mode } = useSelector((state) => state.globalState);

  const theme = useTheme();
  const gridMachineAction = (args) => {
    const data = `
Code: ${args.row.maintenanceCode}
Mfg: ${args.row.manufacturer}
Model: ${args.row.model}
SN: ${args.row.serialNumber}
Location: ${args.row.department}
`;

    const handleQRCodeClick = async () => {
      const qrCodeApiUrl = `https://api.qrserver.com/v1/create-qr-code/?size=400x400&data=${encodeURIComponent(data)}`;

      try {
        const response = await fetch(qrCodeApiUrl);
        if (!response.ok) {
          console.error("Failed to generate QR code.");
          return;
        }

        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);

        const downloadLink = document.createElement("a");
        downloadLink.href = blobUrl;
        const custName = appConstants?.customers
          ?.filter((customer) => customer.id == 5284)[0]
          ?.customerName.split(" ")
          .join("_");
        downloadLink.download = `${custName}_qr_code_machine_${args.row.maintenanceCode}.png`;

        downloadLink.click();

        URL.revokeObjectURL(blobUrl);
      } catch (error) {
        console.error("An error occurred:", error);
      }
    };
    return (
      <div>
        <Link component="button" variant="body2" sx={{ mr: 2.5 }} onClick={handleQRCodeClick}>
          <Tooltip title="Download QR">
            <QRCodeIcon sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
          </Tooltip>
        </Link>
        {user.roles[0] === appConstants?.allTypes?.USER_ROLES.ROLE_MAINTENANCE_SUPERVISOR ? (
          <IconButton sx={{ mr: 1 }} onClick={() => navigate(`/${Links.EDIT_MACHINE}/` + args.row.id)}>
            <Tooltip title="View">
              <ViewIcon sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
            </Tooltip>
          </IconButton>
        ) : null}
        <IconButton sx={{ mr: 1 }} onClick={() => navigate(`/${Links.EDIT_MACHINE}/` + args.row.id, { state: { enableEditing: true } })}>
          <Tooltip title="Edit">
            <EditIcon sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
          </Tooltip>
        </IconButton>
      </div>
    );
  };
  const tableGrids = [
    {
      field: "maintenanceCode",
      headerName: "Maintenance Code",
      minWidth: 50,
      flex: 0.3,
    },
    {
      field: "manufacturer",
      headerName: "Manufacturer",
      minWidth: 50,
      flex: 0.3,
    },
    {
      field: "model",
      headerName: "Model",
      minWidth: 50,
      flex: 0.3,
    },
    {
      field: "serialNumber",
      headerName: "Serial Number",
      minWidth: 50,
      flex: 0.25,
    },
    {
      field: "manufacturingYear",
      headerName: "Year",
      minWidth: 50,
      flex: 0.15,
    },
    {
      field: "assetNumber",
      headerName: "Asset Number",
      minWidth: 50,
      flex: 0.2,
    },
    {
      field: "machineType",
      headerName: "Machine Type",
      minWidth: 50,
      flex: 0.25,
    },
    {
      field: "department",
      headerName: "Location",
      minWidth: 50,
      flex: 0.3,
    },
    {
      minWidth: 60,
      flex: 0.35,
      sortable: false,
      filterable: false,
      renderCell: gridMachineAction,
    },
  ];
  const downloadCsv = (e) => {
    e.preventDefault();
    const headers = ["Id,Maintenance code, Serial number, Model, Manufacturer, Manufacturing year ,Asset number, Machine type, Department"];
    const keys = [
      "id",
      "maintenanceCode",
      "serialNumber",
      "model",
      "manufacturer",
      "manufacturingYear",
      "assetNumber",
      "machineType",
      "department",
    ];
    if (selectedItems.length) {
      const machinesDataToCSV = machinesData.filter((item) => selectedItems.includes(item.id));
      setSelectedItems([]);
      return exportToCsv(headers, keys, machinesDataToCSV, "Machines");
    }
    return exportToCsv(headers, keys, machinesData, "Machines");
  };
  const [showDialog, setShowDialog] = useState(false);

  const { machinesData, isLoading } = useSelector((state) => state.service);

  useEffect(() => {
    dispatch(getMachines());
  }, [dispatch]);

  function handleClick() {
    navigate(`/${Links.ADD_MACHINE}`);
  }

  const selectionsettings = { checkboxMode: "ResetOnRowClick" };
  const toolbarOptions = [{ text: "Add", click: handleClick }, { text: "Delete" }];

  const [searchValue, setSearchValue] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [downloadProgress, setDownloadProgress] = useState(null);
  const [hideProgress, setHideProgress] = useState(true);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  if (isLoading) {
    return (
      <div
        style={{
          textAlign: "center",
          paddingTop: 200,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }

  const onConfirmDelete = async () => {
    setShowDialog(false);
    setSelectedItems([]);
    dispatch(deleteMachine({ machineIds: selectedItems })).then((res) => {
      if (res && res.error) return toast.error("Something went wrong!");
      if (res && res.payload && res.payload.status === 200) {
        dispatch(getMachines()).then((res) => {
          toast.success("Asset(s) deleted successfully!", {
            autoClose: 1000,
          });
        });
      } else return toast.error("Something went wrong!");
    });
  };
  const generateQRCodeImage = async (data) => {
    const qrCodeApiUrl = `https://api.qrserver.com/v1/create-qr-code/?size=400x400&data=${encodeURIComponent(data)}`;
    const response = await fetch(qrCodeApiUrl);
    const blob = await response.blob();
    return URL.createObjectURL(blob);
  };

  const generatePDF = async () => {
    let qrCodeData;
    if (selectedItems.length > 0) {
      qrCodeData = machinesData.filter((machine) => selectedItems.some((selectedItem) => machine.id === selectedItem));
    } else {
      qrCodeData = machinesData;
    }
    const filteredMachinesData = qrCodeData.filter((machine) => {
      return (
        machine.model.toLowerCase().indexOf("temp") === -1 &&
        machine.serialNumber.toLowerCase().indexOf("temp") === -1 &&
        machine.maintenanceCode.toLowerCase().indexOf("temp") === -1
      );
    });

    const doc = new jsPDF();

    const qrCodeWidth = 76.2;
    const qrCodeHeight = 76.2;
    const qrCodesPerRow = 2;
    const rowsPerPage = 3;
    const marginLeft = 10;
    const marginTop = 10;
    const spacingX = 95;
    const spacingY = 95;

    const totalQRCodeCount = filteredMachinesData.length;
    let currentQRCodeCount = 0;

    setHideProgress(false);

    for (let i = 0; i < filteredMachinesData.length; i += qrCodesPerRow * rowsPerPage) {
      if (i !== 0) {
        doc.addPage();
      }

      for (let row = 0; row < rowsPerPage; row++) {
        for (let col = 0; col < qrCodesPerRow; col++) {
          const currentIndex = i + row * qrCodesPerRow + col;
          if (currentIndex < filteredMachinesData.length) {
            const machine = filteredMachinesData[currentIndex];
            const formattedData = `
Code: ${machine.maintenanceCode}
Mfg: ${machine.manufacturer}
Model: ${machine.model}
SN: ${machine.serialNumber}
Location: ${machine.department}
            `;

            const qrCodeImageUrl = await generateQRCodeImage(formattedData);
            const x = marginLeft + col * spacingX;
            const y = marginTop + row * spacingY;

            doc.text("QR Code for Service Ticket", x, y - 3);

            doc.text(machine.maintenanceCode.toUpperCase(), x, y + qrCodeHeight + 5);
            doc.addImage(qrCodeImageUrl, "PNG", x, y, qrCodeWidth, qrCodeHeight);

            currentQRCodeCount++;
            const progress = (currentQRCodeCount / totalQRCodeCount) * 100;
            setDownloadProgress(progress);
          }
        }
      }
    }
    const custName = appConstants?.customers
      ?.filter((customer) => customer.id == 5284)[0]
      ?.customerName.split(" ")
      .join("_");
    doc.save(`${custName}_qr_codes_assets_${moment().format("DD-MM-YYYY_HH:mm")}.pdf`);

    setDownloadProgress(100);
    setHideProgress(true);
  };

  return (
    <div>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header titles={["Assets"]} />
      </Stack>
      <Box sx={{ mx: 2 }}>
        <div>
          <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
            <DialogTitle>Alert</DialogTitle>
            <DialogContent>
              <Typography>Please press "Confirm Delete" to delete the selected item(s).</Typography>
            </DialogContent>
            <DialogActions>
              <div>
                <Button onClick={() => setShowDialog(false)}>Cancel</Button>
                <Button sx={{ height: "self" }} variant="contained" onClick={onConfirmDelete}>
                  Confirm Delete
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </div>
        <Stack direction="row" justifyContent="space-between" alignItems="flex-start" my={2}>
          <Stack direction="row" gap={1} alignItems="center">
            {user.roles[0] === appConstants?.allTypes?.USER_ROLES.ROLE_MAINTENANCE_SUPERVISOR ? (
              <>
                <Box>
                  <Button
                    startIcon={<AddIcon />}
                    sx={{ height: "self" }}
                    autoCapitalize="none"
                    variant="contained"
                    onClick={() => navigate(`/${Links.ADD_MACHINE}/`)}
                  >
                    Add
                  </Button>
                </Box>
                <Box>
                  <Button
                    disabled={!selectedItems.length}
                    startIcon={<DeleteIcon />}
                    sx={{ height: "self" }}
                    autoCapitalize="none"
                    variant="contained"
                    onClick={() => setShowDialog(true)}
                  >
                    Delete
                  </Button>
                </Box>
              </>
            ) : null}
            <OutlinedInput
              id="outlined-adornment-password"
              value={searchValue}
              onChange={(event) => handleSearchChange(event)}
              placeholder="Search..."
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" edge="end">
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
              sx={{ minWidth: 300, maxWidth: 600, height: 35 }}
            />
          </Stack>
          <Stack direction={"row"}>
            <Stack height={45}>
              <Button
                startIcon={<DownloadIcon />}
                sx={{ height: "self", mr: 2 }}
                autoCapitalize="none"
                variant="contained"
                onClick={generatePDF}
                disabled={!hideProgress}
              >
                Download QR
              </Button>
              {downloadProgress !== null && !hideProgress && (
                <Box sx={{ width: "90%" }}>
                  <LinearProgress variant="determinate" value={downloadProgress} />
                  <Typography variant="body2" color="textSecondary">{`${downloadProgress.toFixed(2)}%`}</Typography>
                </Box>
              )}
            </Stack>
            <Stack>
              <Button startIcon={<DownloadIcon />} autoCapitalize="none" variant="contained" onClick={downloadCsv}>
                Export to csv
              </Button>
            </Stack>
          </Stack>
        </Stack>

        <DataGridTable
          checkboxSelection={true}
          rowId={"id"}
          data={machinesData.filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchValue.toLowerCase()))}
          columnAttributes={tableGrids}
          onSelectRow={setSelectedItems}
        />
      </Box>
    </div>
  );
};
export default Machines;
