import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Typography, useTheme } from "@mui/material";
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label, Cell, ResponsiveContainer } from "recharts";
import { useNavigate } from "react-router-dom";

import { getActiveMachinesByStatus } from "features/service/service.slice";
import { Links } from "utils/pageLinkNames";

const ActiveMachinesByStatus = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const navigate = useNavigate();
  const [machinesByStatus, setMachinesByStatus] = useState([]);
  const { activeMachinesByStatus, isLoading } = useSelector((state) => state.service);
  const { mode } = useSelector((state) => state.globalState);

  const fetchData = () => {
    dispatch(getActiveMachinesByStatus());
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (activeMachinesByStatus) {
      const data = [
        {
          status: "Operational",
          ticketStatus: "",
          count: activeMachinesByStatus?.operational?.length || 0,
          color: theme.palette.OPERATIONAL,
          machines: activeMachinesByStatus.operational,
        },
        {
          status: "Under Observation",
          ticketStatus: "Under Observation",
          count: activeMachinesByStatus?.underObservation?.length || 0,
          color: theme.palette.UNDER_OBSERVATION,
          machines: activeMachinesByStatus.underObservation,
        },
        {
          status: "Breakdown",
          ticketStatus: "Assigned",
          count: activeMachinesByStatus?.breakDown?.length || 0,
          color: theme.palette.BREAKDOWN,
          machines: activeMachinesByStatus.breakDown,
        },
      ];

      setMachinesByStatus(data);
    }
  }, [activeMachinesByStatus]);

  const goToServiceTickets = (e) => {
    if (e.ticketStatus) {
      let queryParam = `?ticketCategory[]=Equipment+Support&ticketCategory[]=Preventive+Maintenance&ticketStatus[]=${e.ticketStatus}`;
      e.machines.forEach((machine) => {
        queryParam = queryParam + `&maintenanceCode[]=${machine.maintenanceCode}`;
      });
      navigate(`/${Links.SERVICE_TICKETS}${queryParam}`);
    }
  };

  return (
    <Paper elevation={8} sx={{ padding: 1 }}>
      {/* <Typography variant="h5" align="center" sx={{ mb: 1, mt: 1 }}>
        Critical Machines by Status
      </Typography> */}
      <ResponsiveContainer width="99%" aspect={1.2}>
        <ComposedChart
          data={machinesByStatus ? machinesByStatus : []}
          margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
          cursor="pointer"
        >
          <CartesianGrid stroke="#d6d6d6" />
          <XAxis dataKey="status" stroke={mode === "dark" ? "#fff" : "#000"} />
          <YAxis allowDecimals={false} stroke={mode === "dark" ? "#fff" : "#000"}>
            <Label angle={270} position="insideLeft" offset={10} value="Count" stroke={mode === "dark" ? "#fff" : "#7F7F7F"} />
          </YAxis>
          <Tooltip />
          <Legend />
          <Bar
            dataKey="count"
            name="Machines Count"
            barSize={50}
            label={{ position: "top" }}
            isAnimationActive={false}
            onClick={goToServiceTickets}
          >
            {machinesByStatus?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default ActiveMachinesByStatus;
