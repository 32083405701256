import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import {
  getMachines,
  readAsyncStorageValues,
  getServiceTicketById,
  updateServiceTicket,
  approveServiceTicket,
  completeInternalAudit,
  getSparesPartsFromServiceTicket,
  getAllTemplates,
  issueSparesPartsForServiceTicket,
  completeServiceTicket,
} from "features/service/service.slice";
import {
  Card,
  Stack,
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField,
  IconButton,
  DialogTitle,
} from "@mui/material";
import moment from "moment";
import TicketNotes from "pages/Service/TicketNotes";
import Gallery from "pages/Gallery";
import Timelogs from "pages/Service/Timelogs";
import CreateUpdateTicket from "pages/Service/CreateUpdateTicket";
import CloseIcon from "@mui/icons-material/Close";

import { toast } from "react-toastify";
import Header from "component/Header";
import { Links } from "utils/pageLinkNames";
import { DataGrid } from "@mui/x-data-grid";
import { Field } from "formik";
import DataGridTable from "component/DataGridTable";

const ServiceTicket = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ticketId } = useParams();
  const { state } = useLocation();
  const [machineInfo, setMachineInfo] = useState({ manufacturer: "", model: "", serialNumber: "" });
  const [showDialog, setShowDialog] = useState(false);
  const [showAlertDialog, setShowAlertDialog] = useState(false);
  const [showDialogAction, setShowDialogAction] = useState(false);
  const [showDialogVendor, setShowDialogVendor] = useState(false);
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [additionalNotes, setAdditionalNotes] = useState(null);
  const [sparePartsData, setSparePartsData] = useState([]);
  const [confirmAuditModal, setConfirmAuditModal] = useState(false);
  const [editTicketEnabled, setEditTicketEnabled] = useState(false);

  const { user } = useSelector((state) => state.auth);
  const { allTemplates, machinesData, serviceTicketById, appConstants, serviceTimeLogs, serviceTicketSpareParts, isLoading } = useSelector(
    (state) => state.service
  );
  useEffect(() => {
    dispatch(readAsyncStorageValues());
    dispatch(getMachines());
    dispatch(getAllTemplates());
    if (ticketId) {
      dispatch(getServiceTicketById(ticketId));
      dispatch(getSparesPartsFromServiceTicket(ticketId));
    }
  }, [dispatch, editTicketEnabled]);

  const onChangeSparePartIssued = (event, item, field) => {
    const enteredValue = parseInt(event.target.value || 0);
    const currentReturned = parseInt(item?.returned);
    const currentIssued = parseInt(item?.issued);
    const sparePartIndex = sparePartsData.findIndex((sparePart) => sparePart.id === item.id);
    if ((enteredValue > item.currentStock || enteredValue + currentIssued > item.requested) && field === "issueQuantity")
      return setShowAlertDialog(field);
    if (enteredValue + currentReturned > currentIssued && field === "returnQuantity") return setShowAlertDialog(field);
    if (enteredValue <= item.currentStock && field === "issueQuantity") {
      sparePartsData[sparePartIndex][field] = enteredValue;
      setSparePartsData([...sparePartsData]);
    } else if (enteredValue + currentReturned <= currentIssued && field === "returnQuantity") {
      sparePartsData[sparePartIndex][field] = enteredValue;
      setSparePartsData([...sparePartsData]);
    }
  };

  const renderIssuedField = (data) => {
    return (
      <div>
        <input
          disabled={serviceTicketSpareParts.isLoading}
          min="0"
          type="number"
          value={sparePartsData.find((item) => item.id == data.row.id)?.issueQuantity || ""}
          onChange={(event) => onChangeSparePartIssued(event, data.row, "issueQuantity")}
        />
      </div>
    );
  };
  const renderReturnField = (data) => {
    return (
      <div>
        <input
          disabled={serviceTicketSpareParts.isLoading}
          min="0"
          type="number"
          value={sparePartsData.find((item) => item.id == data.row.id)?.returnQuantity || ""}
          onChange={(event) => onChangeSparePartIssued(event, data.row, "returnQuantity")}
        />
      </div>
    );
  };

  const renderLocation = (data) => {
    return (
      <div
        title={`Rack ${data?.row?.location?.rack || ""} Level ${data?.row?.location?.level || ""}
      Bin ${data?.row?.location?.bin || ""}, ${data?.row?.location?.storageRoom || ""}`}
      >
        {`Rack ${data?.row?.location?.rack || ""} Level ${data?.row?.location?.level || ""}
        Bin ${data?.row?.location?.bin || ""}, ${data?.row?.location?.storageRoom || ""}`}
      </div>
    );
  };

  const renderSparePartInfo = (data) => {
    return (
      <div title={` ${data?.row.partCode || ""}  ${data?.row?.description || ""}`}>
        {` ${data?.row?.partCode || ""}  ${data?.row?.description || ""}`}
      </div>
    );
  };
  const sparePartsColumns = [
    { field: "partCode", headerName: "Spare Part", flex: 1, renderCell: renderSparePartInfo, headerClassName: { fontWeight: "700" } },
    { field: "location", headerName: "Storage Room", flex: 1, renderCell: renderLocation },
    { field: "currentStock", headerName: "Current Stock", flex: 0.7 },
    { field: "requested", headerName: "Requested", flex: 0.7 },
    { field: "issued", headerName: "Issued", flex: 0.7 },
    { field: "returned", headerName: "Returned", flex: 0.7 },
    { field: "issueQuantity", headerName: "Issue", flex: 1, renderCell: renderIssuedField },
    { field: "returnQuantity", headerName: "Return", flex: 1, renderCell: renderReturnField },
  ];

  useEffect(() => {
    if (serviceTicketSpareParts.data) {
      const copiedTicketSpareParts = structuredClone(serviceTicketSpareParts.data);
      setSparePartsData(copiedTicketSpareParts);
    }
  }, [serviceTicketSpareParts]);

  useEffect(() => {
    if (Object.keys(appConstants).length && Object.keys(serviceTicketById).length && machinesData.length) {
      const machine = machinesData.find((item) => item.id === serviceTicketById.machine?.id);
      if (machine) setMachineInfo(machine);
    }
    if (!ticketId || state?.enableEditing) setEditTicketEnabled(true);
    if (serviceTicketById.additionalNotes) {
      let additionalNotesObject = serviceTicketById.additionalNotes;
      setAdditionalNotes(additionalNotesObject);
      if (additionalNotesObject && additionalNotesObject.spareParts) {
        setSparePartsData(additionalNotesObject.spareParts);
      }
    }
  }, [dispatch, appConstants, serviceTicketById, machinesData]);

  const assigneeInfo = (assignee) => {
    const assigneUser = appConstants.allAppUsers?.find((user) => user.userId === assignee.id);
    return assigneUser ? `${assigneUser.name} (${assigneUser.userName})` : "-";
  };

  const onPressConfirmApprove = () => {
    const updateObj = {
      dateCreated: serviceTicketById.dateCreated,
      status: appConstants.allTypes.SERVICE_TICKET_STATUS.SUPERVISOR_APPROVED,
      ticketCategory: serviceTicketById.ticketCategory,
      scheduledDate: moment(serviceTicketById.scheduledDate).format("YYYY-MM-DDTHH:mm:ssZ"),
      machineId: serviceTicketById.machine.id,
      assignees: serviceTicketById.assignees.map((a) => a.id) || [],
      serviceTicketId: serviceTicketById.id,
      priority: serviceTicketById.priority,
      dueDate: serviceTicketById.dueDate ? moment(serviceTicketById.dueDate).format("YYYY-MM-DDTHH:mm:ssZ") : null,
      problemCategory: serviceTicketById.problemCategory,
      vendor: serviceTicketById.vendor?.id || null,
      description: serviceTicketById.description,
    };
    dispatch(approveServiceTicket(serviceTicketById.id)).then((updateResponse) => {
      if (!updateResponse) toast.error("Something went wrong!");
      if (updateResponse && updateResponse.error) toast.error("Please try again!");
      if (updateResponse && updateResponse.payload.data && updateResponse.payload.status === 200) {
        dispatch(getServiceTicketById(serviceTicketById.id));
        toast.success("Ticket approved successfully!", {
          autoClose: 1000,
        });
      } else toast.error("Something went wrong!");
    });
    setShowDialog(false);
  };

  const onPressConfirmApproveAction = (action) => {
    let updateObj = {
      dateCreated: serviceTicketById.dateCreated,
      ticketCategory: serviceTicketById.ticketCategory,
      scheduledDate: moment(serviceTicketById.scheduledDate).format("YYYY-MM-DDTHH:mm:ssZ"),
      machineId: serviceTicketById.machine.id,
      assignees: serviceTicketById.assignees.map((a) => a.id) || [],
      serviceTicketId: serviceTicketById.id,
      priority: serviceTicketById.priority,
      dueDate: serviceTicketById.dueDate ? moment(serviceTicketById.dueDate).format("YYYY-MM-DDTHH:mm:ssZ") : null,
      problemCategory: serviceTicketById.problemCategory,
      vendor: serviceTicketById.vendor?.id || null,
      description: serviceTicketById.description,
    };
    let message;
    if (action === "spares") {
      if (serviceTicketById.status === appConstants?.allTypes?.SERVICE_TICKET_STATUS?.SPARES_RECOMMENDED) {
        updateObj.status = appConstants?.allTypes?.SERVICE_TICKET_STATUS?.SPARES_RECOMMENDED_PENDING;
        message = "Ticket status is changed to spares recommended pending";
      } else {
        updateObj.status = appConstants?.allTypes?.SERVICE_TICKET_STATUS?.SPARES_REQUIRED_PENDING;
        message = "Ticket status is changed to spares required pending";
      }
    } else if (action === "assigned") {
      updateObj.status = appConstants?.allTypes?.SERVICE_TICKET_STATUS?.ASSIGNED;
      message = "Ticket status is changed to 'Assigned'";
    } else if (action === "vendor") {
      if (selectedVendor) {
        updateObj.status = appConstants?.allTypes?.SERVICE_TICKET_STATUS?.ASSIGNED;
        updateObj.vendor = selectedVendor ? appConstants.customers.filter((user) => user.customerName === selectedVendor)[0].id : null;
        message = "Ticket status is changed to Assigned";
      } else {
        updateObj.status = appConstants?.allTypes?.SERVICE_TICKET_STATUS?.VENDOR_SUPPORT_PENDING;
        message = "Ticket status is changed to vendor support pending";
      }
    }
    dispatch(updateServiceTicket(updateObj)).then((updateResponse) => {
      if (!updateResponse) toast.error("Something went wrong!");
      if (updateResponse && updateResponse.error) toast.error("Please try again!");
      if (updateResponse && updateResponse.payload.data && updateResponse.payload.status === 200) {
        dispatch(getServiceTicketById(serviceTicketById.id));
        toast.success(message, {
          autoClose: 1000,
        });
      } else toast.error("Something went wrong!");
    });
    setShowDialogAction(false);
    setShowDialogVendor(false);
    setSelectedVendor("");
  };

  const assigner = appConstants?.appUsers?.find((user) => user.userId === serviceTicketById?.assigner?.id);

  const renderLabel = (label, value) => (
    <Stack direction={"row"}>
      <Typography sx={{ fontWeight: "bold" }}>{label}</Typography>
      <Typography sx={{ ml: 0.5 }}>
        <span style={{ fontWeight: "bold" }}>: </span> {value}
      </Typography>
    </Stack>
  );
  const ticketDetails = () => (
    <>
      {isLoading && appConstants && Object.keys(appConstants).length && Object.keys(serviceTicketById).length ? (
        <div
          style={{
            textAlign: "center",
            paddingTop: 50,
            paddingBottom: 50,
          }}
        >
          <CircularProgress color="secondary" />
        </div>
      ) : (
        <Stack sx={{ flexWrap: "wrap" }}>
          <Stack flexDirection="row" justifyContent={"space-between"} sx={{ flex: 1, flexWrap: "wrap" }}>
            <Stack sx={{ flex: 2, mb: 1, gap: 1 }}>
              {renderLabel("Creator ", assigner ? `${assigner.name} (${assigner.userRole})` : null)}
              {renderLabel("Created ", moment(serviceTicketById.dateCreated).format("DD MMM YYYY"))}
              {renderLabel("Scheduled ", moment(serviceTicketById.scheduledDate).format("DD MMM YYYY"))}
              {renderLabel("Due date ", serviceTicketById.dueDate ? moment(serviceTicketById.dueDate).format("DD MMM YYYY") : "N.A")}
              {renderLabel("Ticket category ", serviceTicketById.ticketCategory)}
              {serviceTicketById?.safetyTicket ? (
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                  {appConstants?.tabConstants?.safetyTicket || ""}
                </Typography>
              ) : null}
              {renderLabel("Description ", serviceTicketById.description)}
            </Stack>
            <Stack sx={{ flex: 2, mb: 1, gap: 1 }}>
              {renderLabel("Status ", serviceTicketById.status)}
              <Stack flexDirection="row" flexWrap={"wrap"}>
                <Typography sx={{ fontWeight: "bold", mr: 1 }}>Technician(s) : </Typography>
                <Stack flexDirection="row" flexWrap={"wrap"} sx={{}}>
                  {serviceTicketById.assignees?.length
                    ? serviceTicketById.assignees?.map((assignee, index) => <Typography>{assigneeInfo(assignee)}</Typography>)
                    : "Not Assigned"}
                </Stack>
              </Stack>
              {renderLabel("Problem category ", serviceTicketById.problemCategory?.slice(1, -1) || "N.A")}
              {renderLabel(
                "Vendor ",
                appConstants && appConstants.customers && serviceTicketById.vendor?.id
                  ? appConstants.customers.find((user) => user.id === serviceTicketById.vendor?.id)?.customerName
                  : " Internal"
              )}
              {renderLabel(
                "Machine ",
                ` ${machineInfo.manufacturer || "N.A"} / ${machineInfo.model || "N.A"} / ${machineInfo.serialNumber || "N.A"}`
              )}
              {additionalNotes?.templateId && allTemplates.data
                ? renderLabel("Template ", allTemplates?.data.find((item) => item.id === additionalNotes?.templateId)?.name || "-")
                : null}
            </Stack>
            {appConstants && Object.keys(appConstants).length ? (
              <Stack spacing={2} sx={{ flex: 0.5, alignItems: "flex-end" }}>
                {(user?.roles?.includes(appConstants?.allTypes?.USER_ROLES?.ROLE_MAINTENANCE_SUPERVISOR) ||
                  user?.roles?.includes(appConstants?.allTypes?.USER_ROLES?.ROLE_SERVICE_TECHNICIAN)) &&
                ![appConstants?.allTypes?.SERVICE_CATEGORY?.INTERNAL_AUDIT].includes(serviceTicketById.ticketCategory) &&
                serviceTicketById.status === appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGNED &&
                serviceTicketById.assignees?.length &&
                serviceTicketById.assignees[0].id === appConstants?.appUsers?.find((users) => users.userName == user.username)?.userId ? (
                  <Button
                    sx={{ width: "10rem", height: "2rem" }}
                    variant="contained"
                    onClick={() => {
                      if (serviceTimeLogs.length) {
                        if (serviceTimeLogs.find((entry) => !entry.endTime))
                          toast.warn(
                            <div>
                              Please add punch out time!
                              <br />
                              Click <span style={{ fontWeight: "bold" }}>"Add"</span> in
                              <span style={{ fontWeight: "bold" }}> "Timelogs"</span> to punch out.
                            </div>
                          );
                        else navigate(`/${Links.SERVICE_TICKET}/${serviceTicketById.id}/${Links.SUBMIT_TICKET_FORM}`, {});
                      } else toast.warn("Please add timelog!");
                    }}
                  >
                    Complete ticket
                  </Button>
                ) : null}

                {additionalNotes?.serviceTicketReportPdf?.length ? (
                  <Button
                    sx={{ width: "10rem", height: "2rem" }}
                    variant="contained"
                    onClick={() => navigate(`/${Links.REPORTS}/` + serviceTicketById.id)}
                  >
                    View report
                  </Button>
                ) : null}

                {user.roles[0] === appConstants?.allTypes?.USER_ROLES.ROLE_MAINTENANCE_SUPERVISOR && (
                  <Button sx={{ width: "10rem", height: "2rem" }} variant="contained" onClick={() => setEditTicketEnabled(true)}>
                    {editTicketEnabled ? "Save" : "Edit"}
                  </Button>
                )}
                {user?.roles?.includes(appConstants?.allTypes?.USER_ROLES?.ROLE_MAINTENANCE_SUPERVISOR) &&
                serviceTicketById.status === appConstants.allTypes.SERVICE_TICKET_STATUS.COMPLETED ? (
                  <Button sx={{ width: "10rem", height: "2rem" }} variant="contained" onClick={() => setShowDialog(true)}>
                    Approve
                  </Button>
                ) : null}

                {
                  // serviceTicketById.status === appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_REQUIRED ||
                  // serviceTicketById.status === appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_RECOMMENDED ||
                  user?.roles?.includes(appConstants?.allTypes?.USER_ROLES?.ROLE_MAINTENANCE_SUPERVISOR) &&
                  serviceTicketById.status === appConstants.allTypes.SERVICE_TICKET_STATUS.VENDOR_SUPPORT_REQUIRED ? (
                    <Stack>
                      <Button sx={{ width: "10rem", height: "2rem" }} variant="contained" onClick={() => setShowDialogAction(true)}>
                        Approve
                      </Button>
                      <Typography
                        sx={{
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        {serviceTicketById.status === appConstants.allTypes.SERVICE_TICKET_STATUS.VENDOR_SUPPORT_REQUIRED
                          ? "Vendor Support Request"
                          : // : serviceTicketById.status === appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_RECOMMENDED
                            // ? "Spares Recommended Request"
                            // : serviceTicketById.status === appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_REQUIRED
                            // ? "Spares Required Request"
                            null}
                      </Typography>
                    </Stack>
                  ) : null
                }
              </Stack>
            ) : null}
          </Stack>
        </Stack>
      )}
    </>
  );

  const onClickSubmitIssueSpare = () => {
    const sparePartObj = {
      serviceTicketId: serviceTicketById.id,
      issueSpareParts: sparePartsData.map((item) => ({
        id: item.id,
        issueQuantity: item?.issueQuantity || 0,
        returnQuantity: item?.returnQuantity || 0,
      })),
    };
    dispatch(issueSparesPartsForServiceTicket(sparePartObj)).then((res) => {
      if (res && res.error) return toast.error("Something went wrong!");
      if (res && res.payload && res.payload.status === 200) {
        dispatch(getSparesPartsFromServiceTicket(ticketId));
        dispatch(getServiceTicketById(ticketId));
        toast.success("Spare part(s) issued!", {
          autoClose: 1000,
        });
      } else return toast.error("Something went wrong!");
    });
  };

  const renderSerialNumber = (params) => {
    return params.api.getSortedRowIds().indexOf(params.id) + 1;
  };

  const renderPartCodeDescription = (params) => {
    return (
      <span>
        {params.row.part_code} / {params.row.part_description}
      </span>
    );
  };

  const handleActualStockChange = (event, row) => {
    const newValue = event.target.value;
    setSparePartsData((prevData) => prevData.map((item) => (item.id === row.id ? { ...item, actual_stock: newValue } : item)));
  };

  const renderActualStockField = (data) => {
    const spareInfo = sparePartsData.find((item) => item.id === data.row.id);
    const actualStockValue = spareInfo ? (spareInfo?.actual_stock || spareInfo?.actual_stock == 0 ? spareInfo?.actual_stock : "") : "";
    // return <Typography>{actualStockValue}</Typography>;
    return user.roles[0] === appConstants?.allTypes?.USER_ROLES.ROLE_STORES &&
      serviceTicketById.status == appConstants?.allTypes?.SERVICE_TICKET_STATUS?.ASSIGNED ? (
      <div>
        <TextField
          variant="outlined"
          size="small"
          type="number"
          InputProps={{ inputProps: { min: 0 } }}
          value={actualStockValue}
          onChange={(event) => handleActualStockChange(event, data.row)}
        />
      </div>
    ) : (
      <Typography>{actualStockValue}</Typography>
    );
  };

  const onClickSaveSparePartsData = () => {
    const signedByUser = appConstants?.appUsers.find((item) => item.userName === user.username);

    const updateSparePart = {
      serviceTicketId: serviceTicketById.id,
      spareParts: sparePartsData.map((item) => ({
        id: item.id,
        part_code: item?.part_code || 0,
        current_stock: item?.current_stock || 0,
        actual_stock: item?.actual_stock || 0,
        part_description: item?.part_description || "",
      })),
      signedBy: signedByUser.name,
      signedByUserName: signedByUser.userName,
      designation: signedByUser.userRole,
      userMobileNumber: signedByUser.userMobileNumber,
      complaintStatus: appConstants.allTypes.SERVICE_TICKET_STATUS.COMPLETED,
      complaint: serviceTicketById.ticketCategory,
      signedDate: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ssZ"),
    };
    console.log("updateSparePart----", updateSparePart);
    const reportObj = new FormData();
    reportObj.append("report", JSON.stringify(updateSparePart));
    reportObj.append("serviceTicketId", "" + serviceTicketById.id);
    dispatch(completeServiceTicket(reportObj)).then((res) => {
      dispatch(getServiceTicketById(serviceTicketById.id));
      // setConfirmAuditModal(false);
      toast.success("Service ticket completed! Report being generated.", {
        autoClose: 1000,
      });
      navigate(`/${Links.SERVICE_TICKET}/` + serviceTicketById.id);
    });
  };
  const columns = [
    { field: "sl_no", headerName: "S.No.", flex: 0.2, renderCell: renderSerialNumber },
    { field: "part_code_description", headerName: "Part Code/Description", flex: 1.5, renderCell: renderPartCodeDescription },
    { field: "current_stock", headerName: "App Stock", flex: 0.3 },
    { field: "actual_stock", headerName: "Actual Stock", flex: 0.3, renderCell: renderActualStockField },
  ];

  return (
    <div>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header
          titles={[
            `Service Ticket ${ticketId || ""} / Maintenance Code - ${machineInfo.maintenanceCode || "N.A"} / Priority - ${
              serviceTicketById.priority || "N.A"
            }`,
          ]}
        />
      </Stack>
      <div>
        <Dialog open={confirmAuditModal} onClose={() => setConfirmAuditModal(false)}>
          <DialogContent>
            <Typography mt={1}>
              Click <span style={{ fontWeight: "bold" }}> "Back" </span> if you need to verify the data entered.
            </Typography>
            <Typography mt={1}>
              Click <span style={{ fontWeight: "bold" }}> "Confirm" </span> to submit the data and complete the ticket{" "}
              {serviceTicketById.id}
            </Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmAuditModal(false)} variant="contained">
              Back
            </Button>
            <Button sx={{ height: "self" }} variant="contained" onClick={() => onClickSaveSparePartsData()}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Dialog open={showAlertDialog ? true : false} onClose={() => setShowAlertDialog(false)}>
        <DialogTitle fontWeight={"bold"}>Alert!</DialogTitle>
        <DialogContent>
          <Typography>
            -{" "}
            {`${
              showAlertDialog == "issueQuantity"
                ? "Issue quantity should be less than or equal to current stock."
                : "Return quantity and current returned combined should be less than or equal to current issued."
            }`}
          </Typography>
          <Typography mt={1}>
            {`${showAlertDialog == "issueQuantity" ? "- Current issued and issue quantity should be less than requested quantity." : ""}`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button sx={{ height: "self" }} variant="contained" onClick={() => setShowAlertDialog(false)}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showDialog} onClose={() => setShowDialog(false)}>
        <DialogContent>
          <Typography mt={1}>
            Click <span style={{ fontWeight: "bold" }}> "Confirm" </span> to approve the ticket {serviceTicketById.id}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)} variant="contained">
            Cancel
          </Button>
          <Button sx={{ height: "self" }} variant="contained" onClick={() => onPressConfirmApprove()}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={showDialogAction} onClose={() => setShowDialogAction(false)}>
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <IconButton
            onClick={() => {
              setShowDialogAction(false);
              setShowDialogVendor(false);
              setSelectedVendor("");
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
        <DialogContent>
          {showDialogVendor ? null : (
            <Typography mb={2}>
              {
                // serviceTicketById.status === appConstants?.allTypes?.SERVICE_TICKET_STATUS?.SPARES_REQUIRED ||
                // serviceTicketById.status === appConstants?.allTypes?.SERVICE_TICKET_STATUS?.SPARES_RECOMMENDED ||
                serviceTicketById.status === appConstants?.allTypes?.SERVICE_TICKET_STATUS?.VENDOR_SUPPORT_REQUIRED && (
                  <>
                    - Choosing <span style={{ fontWeight: "bold" }}>'Yes'</span> will confirm the technician's recommendation and will set
                    the ticket status to{" "}
                    <span style={{ fontWeight: "bold" }}>
                      {/* {serviceTicketById.status === appConstants?.allTypes?.SERVICE_TICKET_STATUS?.SPARES_REQUIRED
                      ? appConstants?.allTypes?.SERVICE_TICKET_STATUS?.SPARES_REQUIRED_PENDING
                      : serviceTicketById.status === appConstants?.allTypes?.SERVICE_TICKET_STATUS?.SPARES_RECOMMENDED
                      ? appConstants?.allTypes?.SERVICE_TICKET_STATUS?.SPARES_RECOMMENDED_PENDING
                      :  */}
                      {serviceTicketById.status === appConstants.allTypes.SERVICE_TICKET_STATUS.VENDOR_SUPPORT_REQUIRED
                        ? appConstants?.allTypes?.SERVICE_TICKET_STATUS?.VENDOR_SUPPORT_PENDING
                        : null}
                    </span>
                    .<br></br>
                    <br></br>- Choosing <span style={{ fontWeight: "bold" }}>'No'</span> will set the ticket status to
                    <span style={{ fontWeight: "bold" }}>'Assigned'</span>.
                  </>
                )
              }
            </Typography>
          )}
        </DialogContent>

        <DialogActions>
          {showDialogVendor ? (
            <Stack sx={{ marginLeft: "10px", marginRight: "10px" }}>
              <Typography sx={{ paddingBottom: "20px" }}>
                Selecting the vendor will mark the ticket's status as "Assigned". If no vendor is selected, the ticket status will be
                'Vendor Support Pending'.
              </Typography>
              <Autocomplete
                id="vendor-select"
                options={appConstants.customers
                  .filter((user) => user.customerType === appConstants.allTypes.TYPE_OF_CUSTOMER.VENDOR)
                  .map((item) => item.customerName)}
                value={selectedVendor}
                onChange={(event, newValue) => setSelectedVendor(newValue)}
                sx={{ minWidth: 300, maxWidth: 600, paddingBottom: "45px" }}
                renderInput={(params) => <TextField {...params} label="Vendor" />}
              />
              <DialogActions>
                <Button variant="contained" onClick={() => setShowDialogVendor(false)}>
                  Cancel
                </Button>
                <Button variant="contained" onClick={() => onPressConfirmApproveAction("vendor")}>
                  Confirm
                </Button>
              </DialogActions>
            </Stack>
          ) : (
            <>
              <Button
                variant="contained"
                onClick={() => {
                  setShowDialogAction(false);
                  onPressConfirmApproveAction("assigned");
                }}
              >
                No
              </Button>
              <Button
                variant="contained"
                onClick={() => {
                  if (serviceTicketById.status === appConstants?.allTypes?.SERVICE_TICKET_STATUS?.VENDOR_SUPPORT_REQUIRED) {
                    setShowDialogVendor(true);
                  } else {
                    onPressConfirmApproveAction("spares");
                  }
                }}
              >
                Yes
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>
      <Stack sx={{ mr: 5, mb: 1, pl: 2 }}>
        <Stack sx={{ mb: 1 }}>
          <Card variant="outlined" sx={{ p: 3 }}>
            {editTicketEnabled ? (
              <CreateUpdateTicket
                initialFormData={ticketId ? { ...serviceTicketById, machineInfo } : null}
                setEditTicketEnabled={setEditTicketEnabled}
              />
            ) : (
              ticketDetails()
            )}
          </Card>
        </Stack>
        {ticketId && serviceTicketById.ticketCategory === appConstants.allTypes.SERVICE_CATEGORY.INTERNAL_AUDIT && (
          <Stack flexDirection="row" sx={{ flex: 1, gap: 1, flexWrap: "wrap" }}>
            <Stack sx={{ flex: 1, minWidth: 300 }}>
              <Card variant="outlined" sx={{ p: 1, mb: 1 }}>
                {user.roles[0] === appConstants?.allTypes?.USER_ROLES.ROLE_STORES &&
                  serviceTicketById.status === appConstants?.allTypes?.SERVICE_TICKET_STATUS?.ASSIGNED && (
                    <div style={{ display: "flex", justifyContent: "flex-end", marginBottom: "16px" }}>
                      <Button variant="contained" onClick={() => setConfirmAuditModal(true)}>
                        Submit
                      </Button>
                    </div>
                  )}
                <div style={{ height: "100%", minHeight: 350, width: "100%" }}>
                  <DataGridTable rowId={"id"} data={sparePartsData} columnAttributes={columns} pageSize={5} />
                </div>
              </Card>
            </Stack>
          </Stack>
        )}
        {ticketId &&
          (serviceTicketById.status === appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGNED ||
            serviceTicketById.status === appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_REQUIRED ||
            serviceTicketById.status === appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_RECOMMENDED) &&
          serviceTicketById.ticketCategory !== appConstants.allTypes.SERVICE_CATEGORY.INTERNAL_AUDIT &&
          user.roles[0] === appConstants?.allTypes?.USER_ROLES.ROLE_STORES && (
            <Stack flexDirection="row" sx={{ flex: 1, gap: 1, flexWrap: "wrap" }}>
              <Stack sx={{ flex: 1, minWidth: 300 }}>
                <Card variant="outlined" sx={{ p: 1, mb: 1 }}>
                  <Stack alignItems={"end"} pb={1}>
                    <Stack direction={"row"} alignItems={"center"}>
                      <Typography mr={2}>Info: Issue should be less than or equal to current stock.</Typography>
                      <Button
                        variant="contained"
                        onClick={() => onClickSubmitIssueSpare()}
                        disabled={serviceTicketSpareParts.isLoading || !serviceTicketSpareParts.data.length}
                      >
                        Submit
                      </Button>
                    </Stack>
                  </Stack>
                  <div style={{ height: 300, width: "100%" }}>
                    <DataGridTable
                      loading={serviceTicketSpareParts.isLoading}
                      rowId={"id"}
                      data={sparePartsData}
                      columnAttributes={sparePartsColumns}
                      pageSize={5}
                    />
                  </div>
                </Card>
              </Stack>
            </Stack>
          )}

        {ticketId && (
          <Stack flexDirection="row" sx={{ flex: 1, gap: 1, flexWrap: "wrap" }}>
            <Stack sx={{ flex: 1, minWidth: 300 }}>
              <Card variant="outlined" sx={{ p: 3 }}>
                <TicketNotes ticketId={ticketId} />
              </Card>
            </Stack>
            <Stack sx={{ flex: 1, minWidth: 300 }}>
              <Card variant="outlined" sx={{ p: 3 }}>
                <Timelogs ticketId={ticketId} />
              </Card>
            </Stack>
            <Stack sx={{ flex: 1, minWidth: 300, maxWidth: 600 }}>
              <Card variant="outlined" sx={{ p: 3 }}>
                <Gallery ticketId={ticketId} />
              </Card>
            </Stack>
          </Stack>
        )}
      </Stack>
    </div>
  );
};

export default ServiceTicket;
