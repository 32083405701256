import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Service from "./settings.service";

export const configurable = createAsyncThunk("settings/configurable", async (category, thunkAPI) => {
  try {
    const response = await Service.configurable(category);
    return response.data;
  } catch (error) {
    return thunkAPI.rejectWithValue(error.response.data);
  }
});

const initialState = {
  loading: false,
  error: null,
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(configurable.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(configurable.fulfilled, (state, action) => {
        state.loading = false;
      })
      .addCase(configurable.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export default settingsSlice.reducer;
