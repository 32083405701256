import { useDroppable } from "@dnd-kit/core";
import { rectSortingStrategy, horizontalListSortingStrategy, SortableContext } from "@dnd-kit/sortable";
import SortableItem from "./SortableItem";
import React from "react";

const DragAndDrop = ({ id, items, itemComponent, nestedItem = false, isEditing }) => {
  const { setNodeRef } = useDroppable({ id });

  return (
    <SortableContext id={id} items={items} strategy={rectSortingStrategy}>
      <div ref={setNodeRef}>
        {items.map((item, index) => (
          <SortableItem
            key={item.id}
            id={item.id}
            data={item}
            index={index}
            itemComponent={itemComponent}
            isEditing={isEditing}
            nestedItem={nestedItem}
          />
        ))}
      </div>
    </SortableContext>
  );
};

export default DragAndDrop;
