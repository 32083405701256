import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  mode: "light",
  activeMenu: false,
};

export const globalSlice = createSlice({
  name: "global",
  initialState,
  reducers: {
    reset: (state) => {
      state.mode = "light";
    },
    setMode: (state) => {
      state.mode = state.mode === "light" ? "dark" : "light";
    },
    setActiveMenu: (state) => {
      state.activeMenu = !state.activeMenu;
    },
  },
});

export const { setMode, setActiveMenu, reset } = globalSlice.actions;

export default globalSlice.reducer;
