import axios from "axios";
import config from "app/config";

const multiInstance = axios.create({
  baseURL: `${config.URI}`,
});

multiInstance.interceptors.request.use(
  async (config) => {
    const token = JSON.parse(localStorage.getItem("userToken"));
    config.headers["Content-Type"] = "multipart/form-data";
    if (token) config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (err) => {
    return Promise.reject(err);
  }
);
multiInstance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;

    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          const rs = await refreshToken();
          const { access_token } = rs.data;
          localStorage.setItem("userToken", JSON.stringify(access_token));
          localStorage.setItem("user", JSON.stringify(rs.data));
          multiInstance.defaults.headers.common["x-access-token"] = access_token;

          //   localStorage.removeItem("userToken");
          //   localStorage.removeItem("user");
          return multiInstance(originalConfig);
        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }

          return Promise.reject(_error);
        }
      }

      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }

    return Promise.reject(err);
  }
);
const refreshToken = async () => {
  const user = JSON.parse(localStorage.getItem("user"));
  let formBody = [];
  formBody.push("grant_type=refresh_token");
  formBody.push("refresh_token=" + user.refresh_token);
  formBody = formBody.join("&");
  const response = await axios.post(`${config.URI}/oauth/access_token`, formBody, {
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
      Authorization: `Bearer ${user.access_token}`,
    },
  });
  return response;
};

export default multiInstance;
