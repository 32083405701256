import React, { useEffect, useState } from "react";
import Form from "component/form";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createMachineLocation, getMachines, updateMachineLocation, getMachineDepartments } from "features/service/service.slice";
import { Links } from "utils/pageLinkNames";
import { CircularProgress, Stack } from "@mui/material";
import Header from "component/Header";
import Cookies from "universal-cookie";
import { translateText } from "utils/utilFunctions";

const AddEditLocation = (props) => {
  const { id } = useParams();
  const [initialFormData, setInitialFormData] = useState({
    name: "",
    description: "",
  });
  const navigate = useNavigate();

  const { machineDepartments, isLoading } = useSelector((state) => state.service);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getMachineDepartments());
  }, [dispatch, initialFormData.name]);

  useEffect(() => {
    if (id && machineDepartments.length) {
      const info = machineDepartments.find((machine) => machine.id == id);
      const setInitialData = async () => {
        const cookies = new Cookies();
        const currentCookie = cookies.get("googtrans");
        const initialState = info;
        const responseText =
          currentCookie?.split("/")[2] != "en" && initialState.description
            ? await translateText(initialState?.description || "", currentCookie?.split("/")[2])
            : null;
        setInitialFormData({
          ...initialState,
          name: info?.name,
          description: responseText ? responseText[0]?.translatedText : initialState.description,
        });
      };
      setInitialData();
    }
  }, [id, machineDepartments]);

  const validations = {
    name: true,
  };

  const handleSubmit = async (formData) => {
    const userExists = machineDepartments.some((location) => location.name.toLowerCase() === formData.name.toLowerCase());
    if (userExists && (!id || machineDepartments.some((location) => location.id == id && location.name !== formData.name))) {
      return toast.error("Location already exists!");
    } else {
      if (formData.description) {
        const responseText = await translateText(formData.description);
        formData.description = responseText[0].translatedText;
      }
      if (id) {
        formData.locationId = Number(id);
        submitForm(updateMachineLocation, formData);
      } else submitForm(createMachineLocation, formData);
    }
  };

  const submitForm = (action, formData) => {
    dispatch(action(formData)).then(() => {
      dispatch(getMachineDepartments()).then(() => {
        toast.success("Location " + (formData.locationId ? "updated" : "added") + " successfully!", {
          autoClose: 1000,
        });
        navigate(`/${Links.LOCATIONS}/`);
      });
    });
  };

  if (isLoading) {
    return (
      <div
        style={{
          textAlign: "center",
          paddingTop: 200,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }
  return (
    <div>
      <Form
        title={id ? "Edit location" : "Add location"}
        initialFormData={initialFormData}
        validations={validations}
        onSubmit={handleSubmit}
        elements={[
          {
            type: "text",
            label: "Location",
            name: "name",
          },
          {
            type: "textArea",
            label: "Description",
            name: "description",
          },
        ]}
      />
      <ToastContainer />
    </div>
  );
};

export default AddEditLocation;
