import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { Search, ArrowRightOutlined as ArrowRight } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  OutlinedInput,
  IconButton,
  InputAdornment,
  Button,
  CircularProgress,
  Typography,
  Stack,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Paper,
  Tooltip,
  Autocomplete,
  TextField,
  useTheme,
  Tabs,
  Tab,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ZoomIn as ViewIcon, Download as DownloadIcon, Edit as EditIcon } from "@mui/icons-material";
import { toast } from "react-toastify";

import { getMachines, readAsyncStorageValues, getAllServiceTickets } from "features/service/service.slice";
import { Links } from "utils/pageLinkNames";
import Header from "component/Header";
import DataGridTable from "component/DataGridTable";
import { exportToCsv } from "utils/utilFunctions";

const AllServiceTickets = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { pathname } = useLocation();
  const { mode } = useSelector((state) => state.globalState);

  const [searchParams, setSearchParams] = useSearchParams();
  const [serviceTickets, setServiceTickets] = useState([]);
  const [showExportAlertDialog, setShowExportAlertDialog] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);

  const { user } = useSelector((state) => state.auth);

  const [activeTab, setActiveTab] = useState("");
  const [serviceTicketCount, setCount] = useState({
    completedCount: [],
    sparesRequired: [],
    sparesRecommended: [],
    vendorSupport: [],
    allActionRequired: [],
    sparesRequiredpendng: [],
    sparesRecommendedPending: [],
    vendorSupportPending: [],
    notCompleted: [],
    allpendingticket: [],
    assignTech: [],
    safetyTicket: [],
    high: [],
    medium: [],
    low: [],
    vendorTicket: [],
  });

  const [filterOptions, setFilterOptions] = useState({
    overDue: false,
    upComingIn: null,
    status: [],
    category: [],
  });
  const { machinesData, appConstants, isLoading, allServiceTickets } = useSelector((state) => state.service);
  useEffect(() => {
    dispatch(readAsyncStorageValues());
    dispatch(getMachines());
  }, [dispatch, searchParams]);
  useEffect(() => {
    dispatch(getAllServiceTickets());
  }, [dispatch]);
  useEffect(() => {
    if (
      allServiceTickets ||
      pathname.substring(1) === Links.PERIODIC_SERVICE_TICKETS ||
      pathname.substring(1) === Links.ONE_TIME_SERVICE_TICKETS
    )
      loadData();
  }, [dispatch, appConstants, searchParams, filterOptions, allServiceTickets, pathname]);

  useEffect(() => {
    if (pathname.substring(1) === Links.ACTION_REQUIRED_SERVICE_TICKETS) {
      setActiveTab("completed");
    } else if (pathname.substring(1) === Links.PENDING_SERVICE_TICKETS) {
      setActiveTab("sparesRequiredPending");
    } else if (pathname.substring(1) === Links.ASSIGN_TECHNICIAN_SERVICE_TICKETS) {
      setActiveTab("safetyTicket");
    }
  }, [pathname]);

  const loadData = () => {
    setLoading(true);
    const serviceTickets = [];
    const pagePathName = pathname.substring(1);
    const {
      ASSIGN_SERVICE_ENGINEER,
      VENDOR_SUPPORT_REQUIRED,
      SPARES_REQUIRED,
      SPARES_RECOMMENDED,
      VENDOR_SUPPORT_PENDING,
      SPARES_REQUIRED_PENDING,
      SPARES_RECOMMENDED_PENDING,
      COMPLETED,
      NOT_COMPLETED,
      ASSIGNED,
    } = appConstants.allTypes.SERVICE_TICKET_STATUS;
    const { CALIBRATION, EQUIPMENT_SUPPORT, INSPECTION, PREVENTIVE_MAINTENANCE, TASK, INTERNAL_AUDIT } =
      appConstants.allTypes.SERVICE_CATEGORY;

    allServiceTickets.data.forEach((ticket) => {
      // const machine = machinesData.find((machine) => machine.id === ticket?.machine?.id);
      const assigneUser = appConstants?.appUsers?.find((user) => {
        return ticket?.assigneeId?.[0] === user.userId;
      });
      console.log(user, assigneUser);
      if (
        filterOptions.maintananceCode &&
        filterOptions.maintananceCode.length &&
        !filterOptions.maintananceCode.includes(ticket?.maintenanceCode)
      )
        return;
      else if (filterOptions.category && filterOptions.category.length && !filterOptions.category.includes(ticket?.ticketCategory)) return;
      else if (filterOptions.status && filterOptions.status.length && !filterOptions.status.includes(ticket?.status)) return;
      else if (filterOptions.ticketIds && filterOptions.ticketIds.length && !filterOptions.ticketIds.includes(ticket?.ticketId.toString()))
        return;
      else if (
        filterOptions &&
        filterOptions.upComingIn &&
        ticket.dueDate &&
        !moment(ticket.dueDate).isBetween(moment(), moment().add(parseInt(filterOptions.upComingIn), "d"))
      )
        return;
      else if (filterOptions && filterOptions.upComingIn && !ticket.dueDate) return;
      else if (
        filterOptions &&
        filterOptions.overDue &&
        !moment(moment().format("DD MMM YYYY")).isAfter(moment(ticket.dueDate).format("DD MMM YYYY"))
      )
        return;
      // else if (filterOptions && filterOptions.priority && !filterOptions.priority.includes(ticket?.priority)) return;
      else if (filterOptions && filterOptions.technician && filterOptions.technician.name != assigneUser?.name) return;
      else if (
        pagePathName &&
        pagePathName === Links.ASSIGN_TECHNICIAN_SERVICE_TICKETS &&
        ![ASSIGN_SERVICE_ENGINEER, ASSIGNED].includes(ticket.status)
      )
        return;
      else if (
        pagePathName &&
        pagePathName === Links.ACTION_REQUIRED_SERVICE_TICKETS &&
        ![VENDOR_SUPPORT_REQUIRED, SPARES_REQUIRED, SPARES_RECOMMENDED, COMPLETED, NOT_COMPLETED].includes(ticket.status)
      )
        return;
      else if (pagePathName && pagePathName === Links.ASSIGNED && ![ASSIGNED].includes(ticket.status)) return;
      else if (pagePathName && pagePathName === Links.SPARES_REQUIRED && ![SPARES_REQUIRED].includes(ticket.status)) return;
      else if (pagePathName && pagePathName === Links.SPARES_RECOMMENDED && ![SPARES_RECOMMENDED].includes(ticket.status)) return;
      else if (
        pagePathName &&
        pagePathName === Links.PENDING_SERVICE_TICKETS &&
        ![VENDOR_SUPPORT_PENDING, SPARES_REQUIRED_PENDING, SPARES_RECOMMENDED_PENDING].includes(ticket.status)
      )
        return;
      else if (
        pagePathName &&
        pagePathName === Links.PERIODIC_SERVICE_TICKETS &&
        ![CALIBRATION, INSPECTION, INTERNAL_AUDIT, PREVENTIVE_MAINTENANCE].includes(ticket.ticketCategory)
      )
        return;
      else if (
        pagePathName &&
        pagePathName === Links.ONE_TIME_SERVICE_TICKETS &&
        ![EQUIPMENT_SUPPORT, TASK].includes(ticket.ticketCategory)
      )
        return;
      else if (
        pagePathName &&
        pagePathName === Links.ASSIGNED &&
        user.roles[0] === appConstants?.allTypes.USER_ROLES.ROLE_STORES &&
        [ASSIGNED].includes(ticket.status) &&
        user.username !== assigneUser?.userName
      )
        return;
      else
        return serviceTickets.push({
          ticketId: ticket?.ticketId,
          ticketCategory: ticket.ticketCategory,
          status: ticket.status,
          machineManufacturer: ticket?.manufacturer || "",
          machineModel: ticket?.modelName || "",
          machineSerialNumber: ticket?.serialNumber || "",
          machineMaintenanceCode: ticket?.maintenanceCode || "",
          description: ticket?.description || "",
          dueDate: ticket.dueDate ? moment(ticket.dueDate).format("DD MMM YY") : null,
          dateCreated: moment(ticket.openDate).format("DD MMM YY"),
          scheduledDate: moment(ticket.scheduledDate).format("DD MMM YY"),
          priority: ticket.priority ? ticket.priority : null,
          vendor: ticket.vendor ? ticket.vendor : null,
          technician: assigneUser?.name,
          safetyTicket: ticket?.safetyTicket ? ticket?.safetyTicket : null,
        });
    });
    setServiceTickets(serviceTickets?.sort((a, b) => b.ticketId - a.ticketId) || []);

    const cmp = serviceTickets.filter((ticket) => ticket.status === appConstants.allTypes.SERVICE_TICKET_STATUS.COMPLETED);
    const rqrd = serviceTickets.filter((ticket) => ticket.status === appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_REQUIRED);
    const rcmd = serviceTickets.filter((ticket) => ticket.status === appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_RECOMMENDED);
    const vendor = serviceTickets.filter((ticket) => ticket.status === appConstants.allTypes.SERVICE_TICKET_STATUS.VENDOR_SUPPORT_REQUIRED);
    const requiredPending = serviceTickets.filter(
      (ticket) => ticket.status === appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_REQUIRED_PENDING
    );
    const recommendedPending = serviceTickets.filter(
      (ticket) => ticket.status === appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_RECOMMENDED_PENDING
    );
    const notCompleted = serviceTickets.filter((ticket) => ticket.status === appConstants.allTypes.SERVICE_TICKET_STATUS.NOT_COMPLETED);

    const vendorPending = serviceTickets.filter(
      (ticket) => ticket.status === appConstants.allTypes.SERVICE_TICKET_STATUS.VENDOR_SUPPORT_PENDING
    );

    const allTickets = serviceTickets.filter(
      (ticket) =>
        ticket.status === appConstants.allTypes.SERVICE_TICKET_STATUS.COMPLETED ||
        ticket.status === appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_REQUIRED ||
        ticket.status === appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_RECOMMENDED ||
        ticket.status === appConstants.allTypes.SERVICE_TICKET_STATUS.VENDOR_SUPPORT_REQUIRED ||
        ticket.status === appConstants.allTypes.SERVICE_TICKET_STATUS.NOT_COMPLETED
    );
    const allpendingtickets = serviceTickets.filter(
      (ticket) =>
        ticket.status === appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_REQUIRED_PENDING ||
        ticket.status === appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_RECOMMENDED_PENDING ||
        ticket.status === appConstants.allTypes.SERVICE_TICKET_STATUS.VENDOR_SUPPORT_PENDING
    );

    const activeTabTickets = serviceTickets.filter((ticket) => ticket.status === appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGNED);
    let safetyTicket = serviceTickets.filter(
      (ticket) => ticket.status === appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGN_SERVICE_ENGINEER
    );
    safetyTicket = safetyTicket.filter((ticket) => ticket.safetyTicket !== null);
    setCount({
      completedCount: cmp,
      sparesRequired: rqrd,
      sparesRecommended: rcmd,
      vendorSupport: vendor,
      allActionRequired: allTickets,
      sparesRequiredpendng: requiredPending,
      sparesRecommendedPending: recommendedPending,
      vendorSupportPending: vendorPending,
      notCompleted: notCompleted,
      allpendingticket: allpendingtickets,
      safetyTicket,
      assignTech: serviceTickets.filter((ticket) => ticket.status == appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGN_SERVICE_ENGINEER),
      high: activeTabTickets.filter((ticket) => ticket.priority == appConstants.allTypes.SERVICE_TICKET_PRIORITY.HIGH),
      low: activeTabTickets.filter((ticket) => ticket.priority == appConstants.allTypes.SERVICE_TICKET_PRIORITY.LOW),
      medium: activeTabTickets.filter((ticket) => ticket.priority == appConstants.allTypes.SERVICE_TICKET_PRIORITY.MEDIUM),
      vendorTicket: activeTabTickets.filter((ticket) => ticket.vendor != null),
    });

    setLoading(false);
  };
  useEffect(() => {
    const ticketCategoryArrayParam = searchParams.getAll("ticketCategory[]");
    const maintenanceCodeArrayParam = searchParams.getAll("maintenanceCode[]");
    const ticketStatusArrayParam = searchParams.getAll("ticketStatus[]");
    const ticketIdArrayParam = searchParams.getAll("ticketId[]");
    setFilterOptions({
      ...filterOptions,
      category: ticketCategoryArrayParam,
      maintananceCode: maintenanceCodeArrayParam,
      status: ticketStatusArrayParam,
      ticketIds: ticketIdArrayParam,
    });
  }, []);

  const renderGridActions = (data) => {
    return (
      <div>
        <IconButton sx={{ mr: 1 }} onClick={() => navigate(`/${Links.SERVICE_TICKET}/` + data.row.ticketId)}>
          <Tooltip title="View">
            <ViewIcon sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
          </Tooltip>
        </IconButton>
        <IconButton
          sx={{ mr: 1 }}
          onClick={() => navigate(`/${Links.UPDATE_SERVICE_TICKET}/` + data.row.ticketId, { state: { enableEditing: true } })}
        >
          <Tooltip title="Edit">
            <EditIcon sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
          </Tooltip>
        </IconButton>
        {/* {appConstants && Object.keys(appConstants).length && data.row.status === appConstants.allTypes.SERVICE_TICKET_STATUS.COMPLETED ? (
          <Button variant="contained" onClick={() => onClickApprove(data.row)} sx={{ ml: 1 }}>
            Approve
          </Button>
        ) : null} */}
      </div>
    );
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const renderTableData = () => {
    let filteredServiceTickets = serviceTickets;
    switch (activeTab) {
      case "completed":
        filteredServiceTickets = serviceTicketCount.completedCount;
        break;
      case "sparesRequired":
        filteredServiceTickets = serviceTicketCount.sparesRequired;
        break;
      case "sparesRecommended":
        filteredServiceTickets = serviceTicketCount.sparesRecommended;
        break;
      case "vendorSupportRequired":
        filteredServiceTickets = serviceTicketCount.vendorSupport;
        break;
      case "sparesRequiredPending":
        filteredServiceTickets = serviceTicketCount.sparesRequiredpendng;
        break;
      case "sparesRecommendedPending":
        filteredServiceTickets = serviceTicketCount.sparesRecommendedPending;
        break;
      case "vendorSupportPending":
        filteredServiceTickets = serviceTicketCount.vendorSupportPending;
        break;
      case "allAction":
        filteredServiceTickets = serviceTicketCount.allActionRequired;
        break;
      case "notCompleted":
        filteredServiceTickets = serviceTicketCount.notCompleted;
        break;
      case "allPending":
        filteredServiceTickets = serviceTicketCount.allpendingticket;
        break;
      case "assignTech":
        filteredServiceTickets = serviceTicketCount.assignTech;
        break;
      case "safetyTicket":
        filteredServiceTickets = serviceTicketCount.safetyTicket;
        break;
      case "high":
        filteredServiceTickets = serviceTicketCount.high;
        break;
      case "low":
        filteredServiceTickets = serviceTicketCount.low;
        break;
      case "medium":
        filteredServiceTickets = serviceTicketCount.medium;
        break;
      case "vendorTicket":
        filteredServiceTickets = serviceTicketCount.vendorTicket;
        break;
      default:
        filteredServiceTickets = [];
        break;
    }

    return (
      <DataGridTable
        loading={loading}
        rowId={"ticketId"}
        data={filteredServiceTickets.filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchValue.toLowerCase()))}
        columnAttributes={columnAttributes}
      />
    );
  };

  const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));
  const columnAttributes = [
    {
      field: "ticketId",
      headerName: "ID",
      minWidth: 60,
      flex: 0.3,
    },
    {
      field: "dateCreated",
      headerName: "Open Date",
      minWidth: 90,
      flex: 0.4,
      sortComparator: dateComparator,
    },
    {
      field: "scheduledDate",
      headerName: "Scheduled Date",
      minWidth: 90,
      flex: 0.4,
      sortComparator: dateComparator,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      minWidth: 90,
      flex: 0.4,
      sortComparator: dateComparator,
    },
    {
      field: "machineMaintenanceCode",
      headerName: "Maintenance Code",
      minWidth: 110,
      editType: "dropdownedit",
      textAlign: "left",
      flex: 0.4,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 110,
      editType: "dropdownedit",
      textAlign: "left",
      flex: 0.4,
    },
    {
      field: "machineManufacturer",
      headerName: "Manufacturer",
      minWidth: 90,
      flex: 0.4,
    },
    {
      field: "machineModel",
      headerName: "Model",
      minWidth: 90,
      flex: 0.4,
    },
    {
      field: "technician",
      headerName: "Technician",
      minWidth: 90,
      flex: 0.4,
    },
    {
      field: "ticketCategory",
      headerName: "Category",
      minWidth: 90,
      flex: 0.5,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 90,
      flex: 0.6,
    },
    {
      field: "vendor",
      headerName: "Vendor",
      minWidth: 90,
      valueGetter: (params) => {
        const vendorId = params.row?.vendor?.id;
        const customer = appConstants.customers.find((user) => user.id === vendorId);
        return customer?.customerName || "Internal";
      },
      flex: 0.6,
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 140,
      flex: 0.8,
      sortable: false,
      filterable: false,
      renderCell: renderGridActions,
      disableClickEventBubbling: true,
    },
  ];
  const downloadCsv = (e, exportAll = false) => {
    e.preventDefault();
    const serviceTicketsList = [];
    if (exportAll) {
      allServiceTickets.data.forEach((ticket) => {
        const machine = machinesData.find((machine) => machine.id === ticket?.machine?.id);
        const assigneUser = appConstants?.appUsers?.find((user) => {
          return ticket?.assignees?.[0] === user.userId;
        });
        return allServiceTickets.data.push({
          ticketId: ticket?.ticketId,
          ticketCategory: ticket?.ticketCategory,
          status: ticket?.status,
          machineManufacturer: ticket?.manufacturer || "",
          machineModel: ticket?.model || "",
          machineSerialNumber: ticket?.serialNumber || "",
          machineMaintenanceCode: ticket?.maintenanceCode || "",
          description: ticket?.description || "",
          dueDate: moment(ticket.dueDate).format("DD MMM YYYY"),
          dateCreated: moment(ticket.openDate).format("DD MMM YYYY"),
          scheduledDate: moment(ticket.scheduledDate).format("DD MMM YYYY"),
          technician: assigneUser?.name,
        });
      });
    }
    const headers = [
      "Ticket Id,Open Date, Scheduled Date, Maintenance Code,Description, Manufacturer,Model,Serial Number,Technician, Category, Status",
    ];
    const keys = [
      "ticketId",
      "dateCreated",
      "scheduledDate",
      "machineMaintenanceCode",
      "description",
      "machineManufacturer",
      "machineModel",
      "machineSerialNumber",
      "technician",
      "ticketCategory",
      "status",
    ];
    exportToCsv(headers, keys, exportAll ? serviceTicketsList : serviceTickets, "ServiceTickets");
    setShowExportAlertDialog(false);
  };
  const filterTickets = (filterOption, filterItem) => {
    setFilterOptions({ ...filterOptions, [filterOption]: filterOptions[filterOption].filter((item) => item !== filterItem) });
  };
  const renderCustomFilter = (filterOption) => (
    <Paper>
      {filterOptions &&
      filterOptions[filterOption] &&
      filterOptions[filterOption].length &&
      filterOption !== "category" &&
      filterOption !== "status" &&
      filterOption !== "maintananceCode" &&
      filterOption !== "ticketIds" &&
      filterOption !== "priority" ? (
        <Stack direction={"row"} flexWrap={"wrap"} alignItems={"center"} px={0.5} py={filterOptions && filterOptions.overDue ? 0 : 0.3}>
          <Typography m={1} textTransform={"capitalize"}>
            {filterOption}
          </Typography>
          {filterOptions[filterOption].map((filterItem) => (
            <Stack direction={"row"} alignItems={"center"} pl={1} borderRadius={10} backgroundColor="#00808030">
              <Typography variant="caption">{filterItem}</Typography>
              <IconButton onClick={() => filterTickets(filterOption, filterItem)}>
                <Tooltip title="Clear item">
                  <CloseIcon sx={{ fontSize: 15 }} />
                </Tooltip>
              </IconButton>
            </Stack>
          ))}
        </Stack>
      ) : null}
    </Paper>
  );
  const showFilters = ![
    Links.ACTION_REQUIRED_SERVICE_TICKETS,
    Links.ASSIGN_TECHNICIAN_SERVICE_TICKETS,
    Links.PENDING_SERVICE_TICKETS,
  ].includes(pathname.substring(1));

  if (isLoading)
    return (
      <div
        style={{
          textAlign: "center",
          paddingTop: 200,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  return (
    <>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header
          titles={[
            (Links.ACTION_REQUIRED_SERVICE_TICKETS === pathname.substring(1)
              ? "Action Required"
              : Links.ASSIGN_TECHNICIAN_SERVICE_TICKETS === pathname.substring(1)
              ? "Active "
              : Links.PENDING_SERVICE_TICKETS === pathname.substring(1)
              ? "Pending "
              : Links.ASSIGNED === pathname.substring(1)
              ? "Assigned "
              : Links.SPARES_REQUIRED === pathname.substring(1)
              ? "Spares Required "
              : Links.SPARES_RECOMMENDED === pathname.substring(1)
              ? "Spares Recommended "
              : Links.PERIODIC_SERVICE_TICKETS === pathname.substring(1)
              ? "Periodic "
              : "One Time ") + "Service Tickets",
          ]}
        />
      </Stack>

      <Box sx={{ mx: 2 }}>
        <Stack direction={"row"} justifyContent={"space-between"} my={2}>
          <Stack direction={"row"} flexWrap={"wrap"} alignItems={"center"} gap={2}>
            {pathname !== `/${Links.ASSIGNED}` &&
              pathname !== `/${Links.SPARES_REQUIRED}` &&
              pathname !== `/${Links.SPARES_RECOMMENDED}` && (
                <Box>
                  <Button
                    startIcon={<AddIcon />}
                    sx={{ height: "self" }}
                    autoCapitalize="none"
                    variant="contained"
                    onClick={() => navigate(`/${Links.CREATE_SERVICE_TICKET}/`)}
                  >
                    Create
                  </Button>
                </Box>
              )}

            <OutlinedInput
              id="outlined-adornment-password"
              value={searchValue}
              onChange={(event) => handleSearchChange(event)}
              placeholder="Search..."
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" edge="end">
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
              sx={{ minWidth: 300, maxWidth: 600, height: 35 }}
            />

            {showFilters && (
              <Paper>
                <Stack
                  direction={"row"}
                  backgroundColor={
                    filterOptions && filterOptions.overDue
                      ? mode === "dark"
                        ? theme.palette.grey[700]
                        : theme.palette.primary[700]
                      : mode === "dark"
                      ? theme.palette.grey[10]
                      : theme.palette.primary[200]
                  }
                  alignItems={"center"}
                  px={0.5}
                  py={filterOptions && filterOptions.overDue ? 0 : 0.3}
                >
                  <Tooltip title="Filter over due tickets">
                    <IconButton onClick={() => setFilterOptions({ ...filterOptions, overDue: true })} size="small">
                      <Typography sx={{ color: "#fff" }}>Over due</Typography>
                    </IconButton>
                  </Tooltip>
                  {filterOptions && filterOptions.overDue ? (
                    <IconButton onClick={() => setFilterOptions({ ...filterOptions, overDue: false })}>
                      <Tooltip title="Clear filter">
                        <CloseIcon sx={{ fontSize: 18, color: "#fff" }} />
                      </Tooltip>
                    </IconButton>
                  ) : null}
                </Stack>
              </Paper>
            )}
            {showFilters && (
              <Autocomplete
                disablePortal
                size="small"
                id="combo-box-demo"
                onChange={(event, newValue, reason) => {
                  setFilterOptions({ ...filterOptions, upComingIn: reason === "clear" ? null : parseInt(newValue) });
                }}
                value={filterOptions && filterOptions.upComingIn ? filterOptions.upComingIn : ""}
                options={[7, 15, 30, 60, 90]}
                sx={{ minWidth: 150, maxWidth: 600 }}
                renderInput={(params) => <TextField {...params} label="Upcoming in" style={{}} />}
              />
            )}

            {showFilters && (
              <>
                {pathname !== `/${Links.ASSIGNED}` &&
                  pathname !== `/${Links.SPARES_REQUIRED}` &&
                  pathname !== `/${Links.SPARES_RECOMMENDED}` && (
                    <Autocomplete
                      disablePortal
                      multiple
                      filterSelectedOptions
                      size="small"
                      id="combo-box-demo"
                      onChange={(event, newValue, reason) => {
                        setFilterOptions({ ...filterOptions, status: reason === "clear" ? [] : newValue });
                      }}
                      value={filterOptions?.status || null}
                      options={
                        appConstants && appConstants?.allTypes
                          ? Object.values(appConstants?.allTypes.SERVICE_TICKET_STATUS)
                              .filter(
                                (item) =>
                                  item !== appConstants.allTypes.SERVICE_TICKET_STATUS.TIME_LOGGED &&
                                  item !== appConstants.allTypes.SERVICE_TICKET_STATUS.COMPLETE &&
                                  item !== appConstants.allTypes.SERVICE_TICKET_STATUS.SUPERVISOR_APPROVED
                              )
                              .sort()
                          : []
                      }
                      sx={{
                        minWidth: 220,
                        maxWidth: 500,
                        "& .MuiChip-label": {
                          color: "#000",
                        },
                        "& .MuiChip-deleteIcon": {
                          color: "#000",
                        },
                      }}
                      renderInput={(params) => <TextField {...params} label="Status" />}
                    />
                  )}
              </>
            )}

            <Autocomplete
              disablePortal
              multiple
              filterSelectedOptions
              size="small"
              id="combo-box-demo"
              onChange={(event, newValue, reason) => {
                setFilterOptions({
                  ...filterOptions,
                  category: reason === "clear" ? [] : newValue,
                });
              }}
              value={filterOptions && filterOptions?.category ? filterOptions.category : null}
              options={
                appConstants && appConstants?.allTypes
                  ? pathname.substring(1) === Links.PERIODIC_SERVICE_TICKETS
                    ? Object.values(appConstants.allTypes.SERVICE_CATEGORY)
                        .filter(
                          (item) =>
                            item !== appConstants.allTypes.SERVICE_CATEGORY.TASK &&
                            item !== appConstants.allTypes.SERVICE_CATEGORY.EQUIPMENT_SUPPORT
                        )
                        .sort()
                    : pathname.substring(1) === Links.ONE_TIME_SERVICE_TICKETS
                    ? Object.values(appConstants.allTypes.SERVICE_CATEGORY)
                        .filter(
                          (item) =>
                            item !== appConstants.allTypes.SERVICE_CATEGORY.CALIBRATION &&
                            item !== appConstants.allTypes.SERVICE_CATEGORY.INSPECTION &&
                            item !== appConstants.allTypes.SERVICE_CATEGORY.INTERNAL_AUDIT &&
                            item !== appConstants.allTypes.SERVICE_CATEGORY.PREVENTIVE_MAINTENANCE
                        )
                        .sort()
                    : Object.values(appConstants.allTypes.SERVICE_CATEGORY)
                  : []
              }
              sx={{ minWidth: 200, maxWidth: 500 }}
              renderInput={(params) => <TextField {...params} label="Ticket category" />}
            />
            <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              isOptionEqualToValue={(option, value) => option.nameEmpno == value.nameEmpno}
              getOptionLabel={(option) => option.nameEmpno}
              onChange={(event, newValue, reason) => {
                setFilterOptions({ ...filterOptions, technician: reason === "clear" ? null : newValue });
              }}
              value={filterOptions.technician || null}
              options={appConstants?.appUsers.filter((user) => user.userRole === appConstants.allTypes.USER_ROLES.ROLE_SERVICE_TECHNICIAN)}
              sx={{ minWidth: 300, maxWidth: 500 }}
              renderInput={(params) => <TextField {...params} label="Technician" />}
            />

            {Object.keys(filterOptions).map((filterOption) => renderCustomFilter(filterOption))}
          </Stack>
          <Stack>
            <Button
              startIcon={<DownloadIcon />}
              sx={{ minWidth: 170 }}
              autoCapitalize="none"
              variant="contained"
              onClick={(e) => {
                const ifFilterExists = Object.keys(filterOptions).filter((item) =>
                  filterOptions[item]
                    ? filterOptions[item] === true || filterOptions[item] > 0
                      ? filterOptions[item]
                      : filterOptions[item].length
                    : filterOptions[item]
                );
                ifFilterExists.length ? setShowExportAlertDialog(true) : downloadCsv(e);
              }}
            >
              Export to csv
            </Button>
          </Stack>
        </Stack>

        {pathname.substring(1) === Links.ACTION_REQUIRED_SERVICE_TICKETS ? (
          <>
            <Tabs value={activeTab} onChange={handleTabChange} centered>
              <Tab
                label={`${appConstants?.allTypes?.SERVICE_TICKET_STATUS?.COMPLETED} (${serviceTicketCount.completedCount.length})`}
                value="completed"
                sx={{
                  fontWeight: "bold",
                  marginRight: "20px",
                  "&:hover": {
                    backgroundColor: "initial",
                    color: "initial",
                  },
                }}
              />
              <Tab
                label={`${appConstants?.allTypes?.SERVICE_TICKET_STATUS?.SPARES_REQUIRED} (${serviceTicketCount.sparesRequired.length})`}
                value="sparesRequired"
                sx={{
                  fontWeight: "bold",
                  marginRight: "20px",
                  "&:hover": {
                    backgroundColor: "initial",
                    color: "initial",
                  },
                }}
              />
              <Tab
                label={`${appConstants?.allTypes?.SERVICE_TICKET_STATUS?.SPARES_RECOMMENDED} (${serviceTicketCount.sparesRecommended.length})`}
                value="sparesRecommended"
                sx={{
                  fontWeight: "bold",
                  marginRight: "20px",
                  "&:hover": {
                    backgroundColor: "initial",
                    color: "initial",
                  },
                }}
              />
              <Tab
                label={`${appConstants?.allTypes?.SERVICE_TICKET_STATUS?.VENDOR_SUPPORT_REQUIRED} (${serviceTicketCount.vendorSupport.length})`}
                value="vendorSupportRequired"
                sx={{
                  fontWeight: "bold",
                  marginRight: "20px",
                  "&:hover": {
                    backgroundColor: "initial",
                    color: "initial",
                  },
                }}
              />
              <Tab
                label={`${appConstants?.allTypes?.SERVICE_TICKET_STATUS?.NOT_COMPLETED} (${serviceTicketCount.notCompleted.length})`}
                value="notCompleted"
                sx={{
                  fontWeight: "bold",
                  marginRight: "20px",
                  "&:hover": {
                    backgroundColor: "initial",
                    color: "initial",
                  },
                }}
              />
              <Tab
                label={`All (${serviceTicketCount.allActionRequired.length})`}
                value="allAction"
                sx={{
                  fontWeight: "bold",
                  marginRight: "20px",
                  "&:hover": {
                    backgroundColor: "initial",
                    color: "initial",
                  },
                }}
              />
            </Tabs>
            {renderTableData()}
          </>
        ) : pathname.substring(1) === Links.PENDING_SERVICE_TICKETS ? (
          <>
            <Tabs value={activeTab} onChange={handleTabChange} centered>
              <Tab
                label={`${appConstants?.allTypes?.SERVICE_TICKET_STATUS?.SPARES_REQUIRED_PENDING} (${serviceTicketCount.sparesRequiredpendng.length})`}
                component={Button}
                value="sparesRequiredPending"
                sx={{
                  fontWeight: "bold",
                  marginRight: "20px",
                  "&:hover": {
                    backgroundColor: "initial",
                    color: "initial",
                  },
                }}
              />
              <Tab
                label={`${appConstants?.allTypes?.SERVICE_TICKET_STATUS?.SPARES_RECOMMENDED_PENDING} (${serviceTicketCount.sparesRecommendedPending.length})`}
                value="sparesRecommendedPending"
                sx={{
                  fontWeight: "bold",
                  marginRight: "20px",
                  "&:hover": {
                    backgroundColor: "initial",
                    color: "initial",
                  },
                }}
              />
              <Tab
                label={`${appConstants?.allTypes?.SERVICE_TICKET_STATUS?.VENDOR_SUPPORT_PENDING} (${serviceTicketCount.vendorSupportPending.length})`}
                value="vendorSupportPending"
                sx={{
                  fontWeight: "bold",
                  marginRight: "20px",
                  "&:hover": {
                    backgroundColor: "initial",
                    color: "initial",
                  },
                }}
              />
              <Tab
                label={`All (${serviceTicketCount.allpendingticket.length})`}
                value="allPending"
                sx={{
                  fontWeight: "bold",
                  marginRight: "20px",
                  "&:hover": {
                    backgroundColor: "initial",
                    color: "initial",
                  },
                }}
              />
            </Tabs>
            {renderTableData()}
          </>
        ) : pathname.substring(1) === Links.ASSIGN_TECHNICIAN_SERVICE_TICKETS ? (
          <>
            <Tabs value={activeTab} onChange={handleTabChange} centered>
              <Tab
                label={`${appConstants?.tabConstants?.safetyTicket || ""} (${serviceTicketCount.safetyTicket?.length || 0})`}
                component={Button}
                value="safetyTicket"
                sx={{
                  fontWeight: "bold",
                  marginRight: "20px",
                  "&:hover": {
                    backgroundColor: "initial",
                    color: "initial",
                  },
                }}
              />
              <Tab
                label={`${appConstants?.allTypes?.SERVICE_TICKET_STATUS?.ASSIGN_SERVICE_ENGINEER} (${serviceTicketCount.assignTech?.length})`}
                component={Button}
                value="assignTech"
                sx={{
                  fontWeight: "bold",
                  marginRight: "20px",
                  "&:hover": {
                    backgroundColor: "initial",
                    color: "initial",
                  },
                }}
              />
              <Tab
                label={`${appConstants?.allTypes?.SERVICE_TICKET_PRIORITY?.HIGH} (${serviceTicketCount.high.length})`}
                value="high"
                sx={{
                  fontWeight: "bold",
                  marginRight: "20px",
                  "&:hover": {
                    backgroundColor: "initial",
                    color: "initial",
                  },
                }}
              />
              <Tab
                label={`${appConstants?.allTypes?.SERVICE_TICKET_PRIORITY?.MEDIUM} (${serviceTicketCount.medium.length})`}
                value="medium"
                sx={{
                  fontWeight: "bold",
                  marginRight: "20px",
                  "&:hover": {
                    backgroundColor: "initial",
                    color: "initial",
                  },
                }}
              />
              <Tab
                label={`${appConstants?.allTypes?.SERVICE_TICKET_PRIORITY?.LOW} (${serviceTicketCount.low.length})`}
                value="low"
                sx={{
                  fontWeight: "bold",
                  marginRight: "20px",
                  "&:hover": {
                    backgroundColor: "initial",
                    color: "initial",
                  },
                }}
              />
              <Tab
                label={`${appConstants?.tabConstants?.vendorTickets || ""} (${serviceTicketCount.vendorTicket.length})`}
                value="vendorTicket"
                sx={{
                  fontWeight: "bold",
                  marginRight: "20px",
                  "&:hover": {
                    backgroundColor: "initial",
                    color: "initial",
                  },
                }}
              />
            </Tabs>
            {renderTableData()}
          </>
        ) : (
          <>
            <DataGridTable
              loading={loading}
              rowId={"ticketId"}
              data={serviceTickets.filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchValue.toLowerCase()))}
              columnAttributes={columnAttributes}
            />
          </>
        )}

        <Dialog open={showExportAlertDialog} onClose={() => setShowExportAlertDialog(false)}>
          <DialogContent>
            <Typography mt={1}>Click "Export filtered" to export only the filtered tickets.</Typography>
            <Typography mt={1}>Click "Export all" to export all tickets.</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowExportAlertDialog(false)}>Cancel</Button>
            <Button variant="contained" onClick={(e) => downloadCsv(e)}>
              Export filtered
            </Button>
            <Button variant="contained" onClick={(e) => downloadCsv(e, true)}>
              Export all
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};
export default AllServiceTickets;
