import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import {
  getMachines,
  getMachineDepartments,
  readServiceTicketsByMachineId,
  readServiceTicketMessagesByMachineId,
} from "features/service/service.slice";
import { Stack, Paper, Typography, Button, Card, Tooltip, useTheme, Box, IconButton, CircularProgress } from "@mui/material";
import { QrCode as QRCodeIcon, Download as DownloadIcon, FileUpload as FileUploadIcon } from "@mui/icons-material";
import moment from "moment";
import Header from "component/Header";
import DataGridTable from "component/DataGridTable";
import AddEditMachine from "pages/Assets/Machines/AddEditMachine";
import { Link } from "react-router-dom";
import { Links } from "utils/pageLinkNames.js";
import { ZoomIn as ViewIcon } from "@mui/icons-material";
import AuthorizedImage from "component/AuthorizedImage";
import ViewImageVideoModal from "component/ViewImageVideoModal";
import { exportToCsv } from "utils/utilFunctions";

const Machine = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [params, setParams] = useState(null);

  const { state } = useLocation();
  const { id } = useParams();
  const [initialFormData, setInitialFormData] = useState({
    serialNumber: "",
  });
  const [serviceTickets, setServiceTickets] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [enableEditing, setEnableEditing] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const { machinesData, appConstants, machineDepartments, serviceTicketMachine, serviceTicketMessagesByMachineId, isLoading } = useSelector(
    (state) => state.service
  );
  const { mode } = useSelector((state) => state.globalState);

  const [showDialogView, setShowDialogView] = useState(false);

  const [currentFilePathIndex, setCurrentFilePathIndex] = useState(0);
  const [serviceTicketMessagesList, setServiceTicketMessagesList] = useState([]);

  useEffect(() => {
    if (serviceTicketMachine) {
      const modified = serviceTicketMachine.map((ticket) => {
        return {
          ...ticket,
          dateCreated: moment(ticket.dateCreated).format("DD MMM YYYY"),
        };
      });
      setServiceTickets(modified?.sort((a, b) => b.ticketId - a.ticketId) || []);
    }
  }, [serviceTicketMachine]);

  useEffect(() => {
    dispatch(getMachineDepartments());
    dispatch(getMachines());
    if (!id || state?.enableEditing) setEnableEditing(true);
  }, [dispatch, initialFormData.serialNumber]);

  useEffect(() => {
    if (id && machinesData.length && machineDepartments.length) {
      const info = machinesData.find((machine) => machine.id == id);
      setInitialFormData({ ...info, location: info.department });
    }
  }, [appConstants, machinesData]);

  useEffect(() => {
    if (appConstants && appConstants?.allTypes) {
      dispatch(readServiceTicketsByMachineId({ ticketId: id, allAppUsers: appConstants.allAppUsers }));
      if (serviceTicketMessagesByMachineId) {
        const imageVideoPdfList = serviceTicketMessagesByMachineId.filter(
          (message) =>
            message.type === appConstants?.allTypes?.TYPE_OF_CONTENT.IMAGE ||
            message.type === appConstants?.allTypes?.TYPE_OF_CONTENT.VIDEO ||
            message.type === appConstants?.allTypes?.TYPE_OF_CONTENT.PDF
        );
        setServiceTicketMessagesList(imageVideoPdfList || []);
      }
    }
  }, [appConstants, serviceTicketMessagesByMachineId]);

  useEffect(() => {
    dispatch(readServiceTicketMessagesByMachineId(id));
  }, [dispatch]);

  const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));
  const gridTicketEdit = (args) => {
    return (
      <div>
        {user.roles[0] === appConstants?.allTypes?.USER_ROLES.ROLE_MAINTENANCE_SUPERVISOR ? (
          <Link to={`/${Links.SERVICE_TICKET}/` + args.row.ticketId}>
            <IconButton sx={{ mr: 1 }}>
              <Tooltip title="View">
                <ViewIcon sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
              </Tooltip>
            </IconButton>
          </Link>
        ) : null}
      </div>
    );
  };

  const renderAssignees = (args) => {
    return <div>{args.row.assignees}</div>;
  };
  const renderDescription = (data) => {
    return <div title={`${data?.row?.description || ""}`}>{`${data?.row?.description || ""}`}</div>;
  };
  const serviceTicketForMachineTableAttributes = [
    {
      field: "dateCreated",
      headerName: "Created",
      minWidth: 60,
      flex: 0.2,
      sortComparator: dateComparator,
    },
    {
      field: "ticketId",
      headerName: "Ticket Id",
      minWidth: 50,
      flex: 0.2,
    },
    {
      field: "ticketCategory",
      headerName: "Category",
      minWidth: 60,
      flex: 0.3,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 60,
      flex: 0.3,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 70,
      flex: 0.4,
      renderCell: renderDescription,
    },
    {
      field: "assignees",
      headerName: "Technician(s)",
      minWidth: 60,
      flex: 0.3,
      renderCell: renderAssignees,
    },
    {
      field: "edit",
      headerName: "",
      minWidth: 60,
      flex: 0.2,
      sortable: false,
      filterable: false,
      renderCell: gridTicketEdit,
    },
  ];

  const downloadCsv = (e) => {
    e.preventDefault();
    const headers = ["Date Created,Ticket Id,Category,Description,Status,Techinicians(s)"];
    const keys = ["dateCreated", "ticketId", "ticketCategory", "description", "status", "assignees"];
    const fileName = `${initialFormData.maintenanceCode || ""}_Service_Tickets`;
    exportToCsv(headers, keys, serviceTickets, fileName || "Machine_Service_Tickets");
  };

  const handleViewFile = () => {
    setCurrentFilePathIndex(0);
    setShowDialogView(true);
  };

  const handleCloseDialogView = () => {
    setCurrentFilePathIndex(null);
    setShowDialogView(false);
  };

  const handleQRCodeClick = async () => {
    const data = `
        Code: ${initialFormData.maintenanceCode}
        Mfg: ${initialFormData.manufacturer}
        Model: ${initialFormData.model}
        SN: ${initialFormData.serialNumber}
        Location: ${initialFormData.department}
        `;

    const qrCodeApiUrl = `https://api.qrserver.com/v1/create-qr-code/?size=400x400&data=${encodeURIComponent(data)}`;

    try {
      const response = await fetch(qrCodeApiUrl);
      if (!response.ok) {
        console.error("Failed to generate QR code.");
        return;
      }

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.href = blobUrl;
      const custName = appConstants?.customers
        ?.filter((customer) => customer.id == 5284)[0]
        ?.customerName.split(" ")
        .join("_");
      downloadLink.download = `${custName}_qr_code_machine_${initialFormData.maintenanceCode}.png`;

      downloadLink.click();

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  const renderLabel = (label, value) => (
    <Stack direction={"row"}>
      <Typography sx={{ fontWeight: "bold" }}>{label}</Typography>
      <Typography flexWrap={"wrap"} sx={{ ml: 0.5 }}>
        <span style={{ fontWeight: "bold", flexWrap: "wrap" }}>: </span> {value}
      </Typography>
    </Stack>
  );
  if (isLoading)
    return (
      <div
        style={{
          textAlign: "center",
          paddingTop: 50,
          paddingBottom: 50,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );

  return (
    <>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header
          titles={[
            "Assets",
            initialFormData.serialNumber
              ? `Maintenance Code: ${initialFormData.maintenanceCode || ""} | Manufacturer: ${
                  initialFormData.manufacturer || ""
                } | Model: ${initialFormData.model || ""} | Serial Number: ${initialFormData.serialNumber || ""}`
              : "",
          ]}
        />
      </Stack>
      {
        <ViewImageVideoModal
          showDialogView={showDialogView}
          handleCloseDialogView={handleCloseDialogView}
          data={serviceTicketMessagesList}
          selectedFileIndex={currentFilePathIndex}
        />
      }
      <Box sx={{ mx: 2 }}>
        {enableEditing ? (
          <Paper sx={{ borderRadius: 2, mb: 1.5, p: 2 }}>
            <AddEditMachine initialFormData={initialFormData} setEnableEditing={setEnableEditing} />
          </Paper>
        ) : (
          <Paper sx={{ borderRadius: 2, mb: 1.5, p: 2 }}>
            <Stack direction={"row"}>
              <Stack mr={3}>
                <Box sx={{}}>
                  {initialFormData.profileImage ? (
                    <AuthorizedImage path={initialFormData.profileImage || null} width={200} height={200} />
                  ) : (
                    <Card sx={{ borderRadius: 5 }}>
                      <Stack alignItems={"center"} p={3} gap={2}>
                        <Button disabled component="label" sx={mode === "dark" ? { color: "white" } : {}}>
                          <Typography>No Image</Typography>
                        </Button>
                      </Stack>
                    </Card>
                  )}
                </Box>
              </Stack>
              <Stack sx={{ flex: 1 }} ml={3} mr={3}>
                <Stack justifyContent={"space-between"} direction={"row"} flexWrap={"wrap"}>
                  <Stack rowGap={1}>
                    {renderLabel("Asset number ", initialFormData.assetNumber || "-")}
                    {renderLabel("Manufacturing year ", initialFormData.manufacturingYear)}
                    {renderLabel("Critical machine ", initialFormData.criticalMachine ? "Yes" : "No")}
                  </Stack>
                  <Stack rowGap={1}>
                    {renderLabel("Machine type ", initialFormData.machineType || "-")}
                    {renderLabel("Location ", initialFormData.location)}
                  </Stack>
                  <Stack rowGap={1}>
                    <Tooltip title="View Image" placement="right">
                      <Button onClick={handleViewFile} variant="contained">
                        View images
                      </Button>
                    </Tooltip>
                    <Tooltip title="Edit asset" placement="right">
                      <Button onClick={() => setEnableEditing(true)} variant="contained">
                        Edit
                      </Button>
                    </Tooltip>
                    <Tooltip title="Download QR" placement="right">
                      <Button endIcon={<DownloadIcon />} variant="contained" onClick={handleQRCodeClick}>
                        <QRCodeIcon />
                      </Button>
                    </Tooltip>
                    <Tooltip title="Export to csv" placement="right">
                      <Button
                        startIcon={<DownloadIcon />}
                        sx={{ minWidth: 170 }}
                        autoCapitalize="none"
                        variant="contained"
                        onClick={(e) => downloadCsv(e)}
                      >
                        Export to csv
                      </Button>
                    </Tooltip>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Paper>
        )}
        {id ? (
          <Paper sx={{ p: 2 }}>
            <Typography variant="h5" fontWeight={600} mb={1}>
              Tickets
            </Typography>
            <DataGridTable
              rowId={"ticketId"}
              data={serviceTickets.filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchValue.toLowerCase()))}
              columnAttributes={serviceTicketForMachineTableAttributes}
            />
          </Paper>
        ) : null}
      </Box>
    </>
  );
};
export default Machine;
