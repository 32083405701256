import React from "react";
import { Box, Stack } from "@mui/material";
import ChartTicketsByStatus from "component/ChartTicketsByStatus";
import OverdueTicketsByTicketCategory from "component/OverdueTicketsByTicketCategory";
import TechnicianReports from "component/TechnicianReports";

const ServiceDashboard = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <Stack mt={3} spacing={1}>
        <Stack direction="row" ml={3} mr={3} gap={3} flex={1}>
         
          <Box flex={1} >
            <TechnicianReports />
          </Box>
          <Box flex={1}>
            <OverdueTicketsByTicketCategory />
          </Box>
         
        </Stack>
      </Stack>
      <Stack mt={3} spacing={1}>
        <Stack direction="row" ml={3} mr={3} gap={2} flex={1}>
         
        <Box mt={2} sx={{ width: "100%"}}>
            <ChartTicketsByStatus />
          </Box>
         
        </Stack>
      </Stack>
      
    </Box>
  );
};

export default ServiceDashboard;
