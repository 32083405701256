import React, { useState, useRef } from "react";
import { Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import { Autocomplete, TextField, Stack, Card, useTheme } from "@mui/material";
import { motion } from "framer-motion";
import { InputAdornment, IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";

import api from "app/api";
import AuthorizedImage from "./AuthorizedImage";
import "../stylesheet/form.css";

const Form = (props) => {
  const theme = useTheme();
  const [formData, setFormData] = useState(props.initialFormData || {});
  const [errors, setErrors] = useState({});
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const { machinesData, isLoading } = useSelector((state) => state.service);
  const { mode } = useSelector((state) => state.globalState);

  const [selectedFile, setSelectedFile] = useState(null);
  const [filePath, setFilePath] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const fileInputRef = useRef(null);
  const [showDialogView, setShowDialogView] = useState(false);
  const [imageZoomLevel, setImageZoomLevel] = useState(1);

  const handleZoomIn = () => {
    setImageZoomLevel((prevZoomLevel) => prevZoomLevel + 0.1);
  };

  const handleZoomOut = () => {
    setImageZoomLevel((prevZoomLevel) => prevZoomLevel - 0.1);
  };

  const handleInputChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  function isYear(value) {
    const regex = /^[1-9]\d{3}$/; // regular expression for four-digit number starting with a non-zero digit
    return regex.test(value);
  }

  const isEmail = (value) => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    return emailRegex.test(value);
  };

  const isPhoneNumber = (value) => {
    const phoneNumberRegex = /^[+]?[(]?\d{1,4}[)]?[-\s./]?\d{1,3}[-\s./]?\d{1,3}[-\s./]?\d{1,4}$/;
    return phoneNumberRegex.test(value);
  };

  const validateForm = () => {
    const newErrors = {};
    for (const field in formData) {
      const value = formData[field];
      const validation = props.validations[field];

      if (validation && !value) {
        const label = field.split(/(?=[A-Z])/).join(" ");
        const capitalizedLabel = label.charAt(0).toUpperCase() + label.slice(1);
        newErrors[field] = `${capitalizedLabel} is required.`;
      } else if (field === "manufacturingYear" && value?.length && !isYear(value)) {
        newErrors[field] = `please type a valid year`;
      } else if (
        (field === "issued" || field === "currentStock" || field === "reorderLevel" || field === "reorderQuantity") &&
        value.length !== 0 &&
        isNaN(parseInt(value))
      ) {
        newErrors[field] = `Please type a valid number`;
      } else if ((field === "email" || field === "emailAddress") && value !== null && value.length > 0 && !isEmail(value)) {
        newErrors[field] = `please type a valid email`;
      } else if (field === "mobileNumber" && value?.trim().length > 0 && !isPhoneNumber(value)) {
        newErrors[field] = `please type a valid mobile number`;
      } else if (field === "password") {
        if (value !== confirmPassword) {
          newErrors[field] = "Passwords do not match.";
          if (value.length < 6) {
            newErrors[field] = "Password length should be at least 6 characters.";
          }
        }

      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      props.onSubmit(formData, e);
      setSelectedFile(null);
    }
  };

  const handleFileSelect = (event) => {
    setSelectedFile(event.target.files[0]);
    setShowDialog(true);
  };

  const handleViewFile = (file) => {
    setShowDialogView(true);
    setFilePath(file);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleFileUpload = async (e) => {
    e.preventDefault();
    props.onFileUpload(selectedFile);
    setShowDialog(false);
    // fileInputRef.current.value = null;
  };

  const handleCloseDialog = () => {
    setShowDialog(false);
    setSelectedFile(null);
    fileInputRef.current.value = null;
  };

  const handleCloseDialogView = () => {
    setShowDialogView(false);
    setImageZoomLevel(1);
    fileInputRef.current.value = null;
  };

  const handleDownload = async () => {
    try {
      const response = await api.get(`/serviceapi/writeImageToClient?location=${filePath}`, {
        responseType: "blob",
      });
      // create file link in browser's memory
      const href = URL.createObjectURL(response.data);

      // create "a" HTML element with href to file & click
      const link = document.createElement("a");
      link.href = href;
      const pdfFileName = filePath.split("/").pop();
      link.setAttribute("download", pdfFileName); //or any other extension
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Dialog open={showDialog} onClose={handleCloseDialog}>
        <DialogTitle>Selected File</DialogTitle>
        <DialogContent>
          {/* File preview */}
          {selectedFile ? (
            selectedFile.type.includes("image") ? (
              <img src={URL.createObjectURL(selectedFile)} alt="Selected File" style={{ maxWidth: "100%", maxHeight: "500px" }} />
            ) : (
              // : selectedFile.type.includes("pdf") ? (
              //   <embed src={URL.createObjectURL(selectedFile)} width="800" height="700" />
              // )
              <span>Selected File is Incorrect</span>
            )
          ) : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          {selectedFile ? (
            <Button variant="contained" onClick={handleFileUpload}>
              Upload
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>

      {/* <ToastContainer /> */}
      <Dialog
        open={showDialogView}
        onClose={handleCloseDialogView}
        PaperProps={{
          style: {
            maxWidth: "1100px",
            minWidth: "500px",
          },
        }}
      >
        <>
          <div style={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
            <DialogTitle style={{ flexGrow: 1 }}>Selected File</DialogTitle>
            <div style={{ display: "grid", gridAutoFlow: "column", columnGap: "10px", paddingRight: 10 }}>
              <span
                onClick={handleZoomIn}
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  borderRadius: "50%",
                  background: "lightgray",
                  width: "30px",
                  height: "30px",
                  lineHeight: "30px",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                +
              </span>
              <span
                onClick={handleZoomOut}
                style={{
                  cursor: "pointer",
                  display: "inline-block",
                  borderRadius: "50%",
                  background: "lightgray",
                  width: "30px",
                  height: "30px",
                  lineHeight: "30px",
                  textAlign: "center",
                  fontSize: "20px",
                }}
              >
                &#8722;
              </span>
            </div>
          </div>
        </>

        <DialogContent>
          {/* File preview */}
          {filePath ? <AuthorizedImage path={filePath} zoomLevel={imageZoomLevel} /> : null}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialogView}>Cancel</Button>
          <Button  sx={{ height: "self" }}onClick={handleDownload} color="primary">
            Download
          </Button>
        </DialogActions>
      </Dialog>
      <Box
        component={motion.div}
        animate={{
          transition: {
            staggerChildren: 0.55,
          },
        }}
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 50,
          padding: 5,
        }}
      >
        <form onSubmit={handleSubmit} className="forms" style={mode === 'dark'?{ backgroundColor:   '#212121'}:{}}>
          {Object.keys(formData).map((field) => {
            let fieldType;
            let fieldName;
            let options;

            const label = field.split(/(?=[A-Z])/).join(" ");
            const capitalizedLabel = label.charAt(0).toUpperCase() + label.slice(1);
            props.elements.forEach((item) => {
              if (item.name === field) {
                fieldType = item.type;
                options = item.options;
                fieldName = item.label;
              }
            });

            if (fieldType === "text") {
              return (
                <div key={field}>
                  <label htmlFor={field} className="labelForm">
                    {capitalizedLabel}:
                  </label>
                  <input type="text" id={field} name={field} value={formData[field]} onChange={handleInputChange} className="inputForm" />
                  {errors[field] && <span className="error">{errors[field]}</span>}
                </div>
              );
            } else if (fieldType === "textArea") {

              const handleCharacterCount = (event) => {
                const inputText = event.target.value?.length
                return inputText;
              };

              return (
                <div key={field} style={{ display: "flex", flexDirection: "column" }}>
                  <label htmlFor={field} className="labelForm">
                    {capitalizedLabel}:
                  </label>
                  <TextField
                    id={field}
                    name={field}
                    value={formData[field]}
                    maxLength={4000}
                    onChange={handleInputChange}
                    multiline
                    maxRows={10}
                    variant="outlined"
                    sx={{ width: "100%" }}
                    inputProps={{ maxLength: 4000, helperText: "ADEQ" }}
                  />
                  <span style={{ alignSelf: "flex-end",marginBottom:"20px" }}>
                    characters: {handleCharacterCount({ target: { value: formData[field] } })}/4000
                  </span>
                  {errors[field] && <span className="error">{errors[field]}</span>}
                </div>
              );
            } else if (fieldType === "checkBox") {
              return (
                <div key={field} style={{ marginTop: 15, marginBottom: 20 }}>
                  <div style={{ flexDirection: "row", display: "flex" }}>
                    <div>
                      <label htmlFor={field} style={{ fontWeight: "bold" }}>
                        <input
                          type="checkbox"
                          id={field}
                          name={field}
                          // value={formData[field]}
                          onChange={(e) => setFormData({ ...formData, [e.target.name]: !formData[field] })}
                          checked={formData[field]}
                        />
                        {capitalizedLabel}
                      </label>
                    </div>
                    <div>
                      <label style={{ marginLeft: 15 }}>
                        {formData[field] ? `(Uncheck the box to disable ${fieldName})` : `(Check the box to enable ${fieldName})`}
                      </label>
                    </div>
                  </div>
                  {errors[field] && <span className="error">{errors[field]}</span>}
                </div>
              );
            } else if (fieldType === "select") {
              return (
                <div key={field} style={{ marginBottom: 15 }}>
                  <label htmlFor={field} className="labelForm">
                    {capitalizedLabel}:
                  </label>
                  <Autocomplete
                    disablePortal
                    id={`combo-box-demo-${field}`}
                    options={options}
                    value={formData[field]}
                    onChange={(e, value) => setFormData({ ...formData, [field]: value.value })}
                    sx={{ display: "flex" }}
                    renderInput={(params) => <TextField {...params} label={`Select ${field}`} />}
                  />
                  {errors[field] && <span className="error">{errors[field]}</span>}
                </div>
              );
            } else if (fieldType === "display") {
              return (
                <div key={field} sx={{ display: "flex", flexDirection: "column" }}>
                  <label htmlFor={field} className="labelForm">
                    {capitalizedLabel}:
                  </label>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      border: "1px solid #ebebeb",
                      padding: "10px",
                      margin: "5px 0",
                      borderWidth: "2px",
                    }}
                  >
                    <span className="inputForm">{formData[field]}</span>
                  </div>
                </div>
              );
            } else if (fieldType === "image") {
              return (
                <div key={field}>
                  <label htmlFor={field} className="labelForm">
                    {capitalizedLabel}:
                  </label>
                  <div>
                    {selectedFile ? (
                      <img
                        src={URL.createObjectURL(selectedFile)}
                        alt="Selected File"
                        style={{ maxWidth: "100%", maxHeight: "500px" }}
                        onClick={() => handleViewFile(selectedFile)}
                      />
                    ) : formData[field] ? (
                      <AuthorizedImage path={formData[field]} onClick={() => handleViewFile(formData[field])} />
                    ) : null}
                  </div>
                  <div style={{ paddingBottom: 20 }}>
                    <input type="file" style={{ display: "none" }} onChange={handleFileSelect} id="fileInput" ref={fileInputRef} />
                    <button
                      onClick={(e) => {
                        e.preventDefault();
                        document.getElementById("fileInput").click();
                      }}
                    >
                      Attach
                    </button>
                  </div>
                </div>
              );
            } else if (fieldType === "password") {
              return (
                <div key={field}>
                  <label htmlFor={field} className="labelForm">
                    {capitalizedLabel}:
                  </label>
                  <div style={{ position: "relative" }}>
                    <input
                      type={showPassword ? "text" : "password"}
                      id={field}
                      name={field}
                      value={formData[field]}
                      onChange={handleInputChange}
                      className="inputForm"
                    />
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowPassword(!showPassword);
                        }}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        style={{ position: "absolute", top: 0, right: 10 }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  </div>
                  {errors[field] && <span className="error">{errors[field]}</span>}
                  {/* {errors[field] && <span className="error">{errors[field]}</span>} */}
                  <label htmlFor={field} className="labelForm">
                    Confirm {capitalizedLabel}:
                  </label>
                  <div style={{ position: "relative" }}>
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      id={"confirmPassword"}
                      name={"confirmPassword"}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      className="inputForm"
                    />
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => {
                          setShowConfirmPassword(!showConfirmPassword);
                        }}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                        style={{ position: "absolute", top: 0, right: 10 }}
                      >
                        {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  </div>
                </div>
              );
            }
          })}

          <Button type="submit" variant="contained" fullWidth>
            Submit
          </Button>
        </form>
      </Box>
    </>
  );
};

export default Form;
