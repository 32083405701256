import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import { getAllTemplates, getServiceTicketById, completeServiceTicket, readServiceTicketMessages } from "features/service/service.slice";
import {
  Card,
  Stack,
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField,
  IconButton,
  DialogTitle,
  useTheme,
  Checkbox,
  Tooltip,
  Paper,
  Box,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { Links } from "utils/pageLinkNames";
import Header from "component/Header";

const EquipmentSupportForm = ({ ticketFormData = {}, updateEqspData }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { appConstants, serviceTicketById, isLoading } = useSelector((state) => state.service);
  const { user } = useSelector((state) => state.auth);

  return (
    <div>
      <Stack rowGap={2}>
        <TextField
          multiline
          label="Complaint"
          onChange={(e) => updateEqspData({ ...ticketFormData, complaint: e.target.value })}
          sx={{ minWidth: 350, maxWidth: 600 }}
          maxRows={10}
        />
        <TextField
          multiline
          label="Resolution"
          onChange={(e) => updateEqspData({ ...ticketFormData, resolution: e.target.value })}
          sx={{ minWidth: 350, maxWidth: 600 }}
          maxRows={10}
        />
      </Stack>
    </div>
  );
};

export default EquipmentSupportForm;
