import React, { useEffect, useState } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { OutlinedInput, IconButton, InputAdornment, Box, Stack, useTheme, CircularProgress, Typography } from "@mui/material";
import { Search } from "@mui/icons-material";

import Header from "component/Header";
import { getTechnicianLoggedTime } from "features/service/service.slice";
import "../../../App.css";
import "../../../stylesheet/engReports.css";
import DataGridTable from "component/DataGridTable";
import { Links } from "utils/pageLinkNames.js";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

const MaintenanceReport = () => {
  const navigate = useNavigate();
  const theme = useTheme();

  const dispatch = useDispatch();
  const [dates, setDates] = useState(null);
  const { state } = useLocation();

  const { technicianLoggedTime, isLoading } = useSelector((state) => state.service);
  const { mode } = useSelector((state) => state.globalState);

  useEffect(() => {
    const logDate = dates
      ? moment(dates).format("YYYY-MM-DD")
      : state.date
      ? moment(state.date).format("YYYY-MM-DD")
      : moment(new Date()).subtract("1", "days").format("YYYY-MM-DD");
    setDates(logDate);
    dispatch(
      getTechnicianLoggedTime({
        logDate: logDate,
      })
    );
  }, [dispatch, state.date, dates]);

  const [searchValue, setSearchValue] = useState("");
  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const TotalTechTimeLoggedGrid = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 0.3,
    },
    {
      field: "totalHours",
      headerName: "Total Hours",
      minWidth: 150,
      flex: 0.2,
    },
  ];
  const techTimeLoggedGrid = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 150,
      flex: 0.3,
    },
    {
      field: "service_ticket_id",
      headerName: "Ticket Id",
      minWidth: 150,
      flex: 0.2,
    },
    {
      field: "hours",
      headerName: "Hours",
      minWidth: 150,
      flex: 0.2,
    },

    {
      field: "description",
      headerName: "Description",
      minWidth: 100,
      flex: 0.2,
    },
  ];

  const rowsWithIds = technicianLoggedTime?.IndividualTechTimeLog?.map((row, index) => ({
    ...row,
    id: index,
  }));

  return (
    <div>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header titles={["Maintenance Reports"]} />
      </Stack>
      <Box sx={{ mx: 2 }}>
        <Stack direction={"row"} gap={1} alignItems={"center"} my={2}>
          <OutlinedInput
            id="outlined-adornment-password"
            value={searchValue}
            onChange={(event) => handleSearchChange(event)}
            placeholder="Search..."
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" edge="end">
                  <Search />
                </IconButton>
              </InputAdornment>
            }
            sx={{ minWidth: 300, maxWidth: 600, height: 35 }}
          />

          {/* <Button
            startIcon={emailSending ? <CircularProgress size={12} /> : <SendIcon />}
            sx={{ height: "self", textTransform: "none" }}
            variant="contained"
            onClick={() => emailWorkHoursToSupervisors()}
            disabled={emailSending}
          >
            {emailSending ? "Sending " : ""}Maintenance Report
          </Button> */}
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <DatePicker
              maxDate={moment()}
              label="Select date"
              format={"dddd, DD MMM YYYY"}
              value={moment(dates) || null}
              onChange={(newValue) => setDates(newValue)}
              slotProps={{ textField: { size: "small" } }}
            />
          </LocalizationProvider>
        </Stack>

        {isLoading ? (
          <div
            style={{
              textAlign: "center",
              paddingTop: 200,
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <>
            <Typography sx={{ fontWeight: "bold",paddingTop:2,mb:2,fontSize:"medium" }}>Total time logged by technicians</Typography>
            <DataGridTable
              rowId={"name"}
              data={technicianLoggedTime?.TotalTechTimeLogged?.filter((item) =>
                Object.values(item).join(" ").toLowerCase().includes(searchValue.toLowerCase())
              )}
              columnAttributes={TotalTechTimeLoggedGrid}
            />
            <Typography sx={{ fontWeight: "bold",paddingTop:2,mb:2,fontSize:"medium" }}>Detailed time logged by technicians</Typography>
            <DataGridTable
              rowId={"id"}
              data={rowsWithIds?.filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchValue.toLowerCase()))}
              columnAttributes={techTimeLoggedGrid}
            />
          </>
        )}
      </Box>
    </div>
  );
};
export default MaintenanceReport;
