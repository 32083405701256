import React, { useEffect, useState } from "react";
import Form from "component/form";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createMachineModel, updateMachineModel, readAsyncStorageValues } from "features/service/service.slice";
import { Links } from "utils/pageLinkNames";
import {
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
  Stack,
  TextField,
  Tooltip,
  Autocomplete,
  IconButton,
} from "@mui/material";
import {
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Category as CategoryIcon,
  AttachFile as AttachFileIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material/";
import UploadProgressModal from "component/UploadProgressModal";

const CreateEditMachineModel = (props) => {
  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    manufacturer: "",
    model: "",
    attachments: [],
    // Add state for confirming deletion
    showDeleteConfirmation: false,
    deletingAttachmentIndex: -1,
  });
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();
  const { appConstants } = useSelector((state) => state.service);
  const [uploadProgress, setUploadProgress] = useState({ progress: 0, rate: 0, total: 0, buffer: 10 });
  const [showUploadDialog, setShowUploadDialog] = useState(false);

  useEffect(() => {
    if (props && props.initialFormData) setFormData({ ...formData, ...props.initialFormData });
  }, [props.initialFormData]);

  const progressCallback = (progressEvent) => {
    const percentRateFraction = progressEvent.rate / 1048576;
    const percentTotalFraction = progressEvent.total / 1048576;
    const percentLoadedFraction = progressEvent.loaded / 1048576;
    const percentFraction = percentLoadedFraction / percentTotalFraction;
    const roundedNum = Math.round(percentLoadedFraction * 10) / 10;
    const roundedNumRate = Math.round(percentRateFraction * 10) / 10;
    const roundedNumTotal = Math.round(percentTotalFraction * 10) / 10;
    const diff = Math.random() * 10;
    setUploadProgress({ progress: roundedNum, rate: roundedNumRate, total: roundedNumTotal, buffer: percentFraction * 100 + diff + 5 });
  };
  const handleSubmit = () => {
    setErrors({
      manufacturer: formData.manufacturer ? "" : "Please enter manufacturer!",
      model: formData.model ? "" : "Please enter model!",
    });
    const modelExists = appConstants.machineModels.some(
      (item) =>
        item.manufacturer.toLowerCase() === formData.manufacturer.toLowerCase() && item.model.toLowerCase() === formData.model.toLowerCase()
    );
    if (!formData.model || !formData.manufacturer) return toast.warn("Please enter required fields!");
    if (modelExists && !formData.id) {
      return toast.warn("Model exists with the same name and manufacturer!");
    }
    const updateObj = {
      modelName: formData.model,
      manufacturer: formData.manufacturer,
      info: formData?.info || {},
    };
    if (formData.id) {
      updateObj.modelId = formData.id;
      submitData(updateMachineModel, updateObj);
    } else submitData(createMachineModel, updateObj);
  };
  const submitData = (action, updateObj) => {
    setShowUploadDialog(true);
    const modelFormData = new FormData();
    const artifacts = [];
    if (formData.attachments.length) {
      formData.attachments.forEach((item, index) => {
        modelFormData.append("image" + index, item);
        artifacts.push({
          name: item.name,
          type: item.type.includes("image/")
            ? appConstants.allTypes.TYPE_OF_CONTENT.IMAGE
            : item.type.includes("application/pdf")
            ? appConstants.allTypes.TYPE_OF_CONTENT.PDF
            : appConstants.allTypes.TYPE_OF_CONTENT.VIDEO,
          image: "image" + index,
        });
      });
      updateObj = { ...updateObj, artifacts };
    }
    modelFormData.append("modelDetails", JSON.stringify(updateObj));
    dispatch(action({ modelFormData, progressCallback: progressCallback })).then((res) => {
      setUploadProgress({ progress: 0, rate: 0, total: 0, buffer: 10 });
      setShowUploadDialog(false);
      if (res && res.error) {
        toast.error("Something went wrong!");
      } else if (res && res.payload.data && res.payload.status === 200) {
        if (!updateObj.modelId) {
          navigate(`/${Links.MACHINE_MODEL}/` + res.payload.data.id, { replace: true });
        }
        dispatch(readAsyncStorageValues()).then(() => {
          toast.success(`Model ${updateObj.modelId ? "updated" : "added"} successfully!`, { autoClose: 1500 });
          props.setEnableEditing(false);
        });
      } else {
        toast.error("Something went wrong!");
      }
    });
  };

  const handleAttachmentChange = (event) => {
    const files = event.target.files;
    // Convert FileList to an array and store it in the state
    const newAttachments = Array.from(files);
    // setFormData({ ...formData, attachments: [...formData.attachments] });
    setFormData((prevState) => ({
      ...prevState,
      attachments: [...prevState.attachments, ...newAttachments],
    }));
  };

  const handleRemoveAttachment = (index) => {
    setFormData({
      ...formData,
      showDeleteConfirmation: true,
      deletingAttachmentIndex: index,
    });
  };

  const handleConfirmDelete = () => {
    const index = formData.deletingAttachmentIndex;
    setFormData((prevState) => {
      const updatedAttachments = [...prevState.attachments];
      updatedAttachments.splice(index, 1);
      return {
        ...prevState,
        attachments: updatedAttachments,
        showDeleteConfirmation: false,
        deletingAttachmentIndex: -1,
      };
    });
  };

  const handleCancelDelete = () => {
    setFormData({
      ...formData,
      showDeleteConfirmation: false,
      deletingAttachmentIndex: -1,
    });
  };

  return (
    <div>
      <div>
        <UploadProgressModal selectedFiles={formData?.attachments} showUploadDialog={showUploadDialog} uploadProgress={uploadProgress} />
      </div>
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Stack gap={2} flexWrap={"wrap"}>
          <TextField
            label="Model"
            value={formData.model}
            onChange={(event) => setFormData({ ...formData, model: event.target.value })}
            error={errors && errors.model && !formData.model}
            helperText={errors.model}
            sx={{ minWidth: 350 }}
          />
          <TextField
            label="Manufacturer"
            value={formData.manufacturer}
            onChange={(event) => setFormData({ ...formData, manufacturer: event.target.value })}
            error={errors && errors.manufacturer && !formData.manufacturer}
            helperText={errors.manufacturer}
            sx={{ minWidth: 350 }}
            // inputProps={{ maxLength: 4000, helperText: "ADEQ" }}
          />

          <Stack mt={2} flexDirection="row" flexWrap="wrap" alignItems="center">
            <div>
              {formData.id ? null : (
                <div>
                  <input
                    type="file"
                    accept="application/pdf,video/*,image/*"
                    id="attachment-input"
                    style={{ display: "none" }}
                    onChange={handleAttachmentChange}
                    multiple
                  />
                  <label htmlFor="attachment-input">
                    <IconButton
                      component="span"
                      // onClick={() => document.getElementById("fileInput").click()}
                    >
                      <AttachFileIcon />
                    </IconButton>
                    Attach Image
                  </label>
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    {formData.attachments?.map((attachment, index) => (
                      <div key={index} style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
                        <img
                          src={URL.createObjectURL(attachment)}
                          alt={`Attachment ${index}`}
                          style={{ maxWidth: "100px", maxHeight: "100px" }}
                        />
                        <IconButton color="error" onClick={() => handleRemoveAttachment(index)} aria-label="Delete" size="small">
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </div>
                    ))}
                  </div>
                  <Dialog open={formData.showDeleteConfirmation} onClose={handleCancelDelete}>
                    <DialogTitle>Confirm Delete</DialogTitle>
                    <DialogContent>
                      <Typography>Are you sure you want to delete this image?</Typography>
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={handleCancelDelete} variant="contained">
                        Cancel
                      </Button>
                      <Button onClick={handleConfirmDelete} variant="contained">
                        Delete
                      </Button>
                    </DialogActions>
                  </Dialog>
                </div>
              )}
            </div>
          </Stack>
        </Stack>
        <Stack>
          <Tooltip title="Save">
            <Button variant="contained" onClick={handleSubmit}>
              Save
            </Button>
          </Tooltip>
        </Stack>
      </Stack>
    </div>
  );
};

export default CreateEditMachineModel;
