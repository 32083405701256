import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Autocomplete, TextField, Stack, IconButton, OutlinedInput, InputAdornment, Box, useTheme, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Search } from "@mui/icons-material";
import Header from "component/Header";
import { Links } from "utils/pageLinkNames";
import { getAllTemplates, updateTemplate } from "features/service/service.slice";
import DataGridTable from "component/DataGridTable";
import { ZoomIn as ViewIcon, Edit as EditIcon } from "@mui/icons-material";
import FileCopyIcon from "@mui/icons-material/FileCopy";

const Templates = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState("");
  const { allTemplates, appConstants } = useSelector((state) => state.service);
  const { mode } = useSelector((state) => state.globalState);
  const [vendorsList, setVendorsList] = useState([]);
  const [templatesList, setTemplatesList] = useState([]);
  useEffect(() => {
    dispatch(getAllTemplates());
  }, [dispatch]);

  useEffect(() => {
    if (allTemplates.data.length && !allTemplates.isLoading) {
      const templatesWithVendorName = allTemplates.data.map((template) => {
        const vendorName = appConstants.customers.find((item) => item.id === parseInt(template.vendor))?.customerName;
        return { ...template, vendorName };
      });
      setTemplatesList(templatesWithVendorName);
    }
  }, [allTemplates.data]);

  useEffect(() => {
    if (appConstants && Object.keys(appConstants).length) {
      const vendors = appConstants.customers
        ?.filter((user) => user.customerType === appConstants.allTypes.TYPE_OF_CUSTOMER.VENDOR)
        .sort((a, b) => a.customerName.localeCompare(b.customerName))
        .map((vendor) => ({
          ...vendor,
          label: vendor.customerName,
          value: vendor.id,
        }));
      setVendorsList(vendors);
    }
  }, [appConstants]);

  const tableGrids = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 60,
      flex: 0.4,
    },
    {
      field: "vendor",
      headerName: "Vendor",
      minWidth: 60,
      flex: 0.4,
      valueGetter: (params) =>
        appConstants.customers.find((item) => item.id === parseInt(params.row[params.field]))?.customerName || "Internal",
    },
    {
      field: "modelId",
      headerName: "Machine Model",
      minWidth: 60,
      flex: 0.4,
      valueGetter: (params) => appConstants.machineModels.find((item) => item.id === parseInt(params.row[params.field]))?.model || " ",
    },
    {
      field: "active",
      headerName: "Active",
      minWidth: 60,
      flex: 0.4,
      valueGetter: (params) => (params.row[params.field] ? "Yes" : "No"),
    },
    {
      minWidth: 60,
      flex: 0.2,
      sortable: false,
      filterable: false,
      renderCell: (args) => (
        <div>
          <IconButton
            sx={{ mr: 1 }}
            onClick={() => {
              navigate(`/${Links.CREATE_TEMPLATE}`, { state: { copyTemplate: true, copyTemplateId: args.row.id } });
            }}
          >
            <Tooltip title="Copy">
              <FileCopyIcon sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
            </Tooltip>
          </IconButton>
          <IconButton sx={{ mr: 1 }} onClick={() => navigate(`/${Links.EDIT_TEMPLATE}/` + args.row.id)}>
            <Tooltip title="Edit">
              <EditIcon sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
            </Tooltip>
          </IconButton>
        </div>
      ),
    },
  ];

  const onTmpUdate = async () => {
    // Code to be changed ===============================
    const testsections = [];
    let test = structuredClone(allTemplates.data);
    for (let tmp of test) {
      let tmptemplate = { ...tmp };
      for (let sss in tmptemplate.sections) {
        let tmpSection = tmptemplate.sections[sss];
        const randomNumber = Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
        tmpSection.id = randomNumber + sss + "";
        const testtasks = [];
        for (let ttt in tmpSection.tasks) {
          const randomNumber2 = Math.floor(Math.random() * (99999 - 10000 + 1)) + 10000;
          let tmpSectionTask = tmpSection.tasks[ttt];
          tmpSectionTask.id = randomNumber2 + ttt + "";
          testtasks.push(tmpSectionTask);
        }
        tmpSection.tasks = testtasks;
        testsections.push(tmpSection);
      }
      tmptemplate.sections = testsections;
      tmptemplate.templateId = tmptemplate.id;

      const formDataToPost = new FormData();
      formDataToPost.append("template", JSON.stringify(tmptemplate));
      if (tmptemplate.id == 97) {
        console.log("tmp--", tmptemplate);
        dispatch(updateTemplate(formDataToPost)).then((templateResp) => {
          console.log("templateResp---", templateResp);
        });
      }
    }
    // formData.sections = testsections;
    // till here ===============================
  };

  return (
    <div>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header titles={["Templates"]} />
      </Stack>
      {/* <Button onClick={() => onTmpUdate()}>onTmpUdate</Button> */}
      <Box sx={{ mx: 2 }}>
        <Stack direction={"row"} alignItems={"center"} my={2} justifyContent={"space-between"}>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <Button
              startIcon={<AddIcon />}
              sx={{ height: "self" }}
              autoCapitalize="none"
              variant="contained"
              onClick={() => navigate(`/${Links.CREATE_TEMPLATE}`)}
            >
              ADD
            </Button>
            <OutlinedInput
              id="outlined-adornment-password"
              value={searchValue}
              onChange={(event) => setSearchValue(event.target.value)}
              placeholder="Search..."
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" edge="end">
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
              sx={{ minWidth: 300, maxWidth: 600, height: 35 }}
            />
          </Stack>
          <Autocomplete
            disablePortal
            options={vendorsList}
            onChange={(e, newValue, reason) => {
              if (reason === "clear") setTemplatesList(allTemplates.data);
              else setTemplatesList([...allTemplates.data.filter((template) => template.vendor === newValue.id)]);
            }}
            sx={{ width: 350 }}
            renderInput={(params) => <TextField {...params} label="Filter by vendor" />}
          />
        </Stack>
        <DataGridTable
          rowId={"id"}
          data={templatesList.filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchValue.toLowerCase()))}
          columnAttributes={tableGrids}
        />
      </Box>
    </div>
  );
};

export default Templates;
