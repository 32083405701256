import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Form from "component/form";
import { readAsyncStorageValues, getServiceTicketsByStatusList } from "features/service/service.slice";
import { createVendor, updateVendor, getAllVendors, updateVendorContact } from "features/appUsers/appUsers.slice";
import { toast } from "react-toastify";
import { Button, CircularProgress, Checkbox, ListItemAvatar, Avatar, Typography, Stack, Tooltip, TextField, Paper } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { Links } from "utils/pageLinkNames";
import moment from "moment";
import Header from "component/Header";
import Cookies from "universal-cookie";
import { translateText } from "utils/utilFunctions";

const AddEditVendor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [initialFormData, setInitialFormData] = useState({
    name: "",
    emailAddress: "",
    active: true,
  });
  const [errors, setErrors] = useState({});
  const validations = {
    name: true,
  };
  const [vendorContacts, setVendorContacts] = useState([]);
  const [vendorTickets, setVendorTickets] = useState([]);
  const [editVendor, setEditVendor] = useState(null);
  const [editContact, setEditContact] = useState(null);
  const { appConstants, serviceTicketsByStatusList, isLoading } = useSelector((state) => state.service);
  const { allVendorsList } = useSelector((state) => state.appUsers);

  useEffect(() => {
    if (appConstants && serviceTicketsByStatusList)
      setVendorTickets(serviceTicketsByStatusList.filter((ticket) => ticket.vendor?.id === parseInt(id)) || []);
  }, [serviceTicketsByStatusList]);

  useEffect(() => {
    if (appConstants && Object.keys(appConstants).length && allVendorsList && allVendorsList.data.length && id) {
      const vendor = allVendorsList.data.find((vendor) => vendor.id === parseInt(id));
      if (vendor) {
        const setInitialData = async () => {
          const cookies = new Cookies();
          const currentCookie = cookies.get("googtrans");
          const responseText =
            currentCookie?.split("/")[2] != "en" && vendor.description
              ? await translateText(vendor.description, currentCookie?.split("/")[2])
              : null;
          setInitialFormData({
            description: responseText ? responseText[0]?.translatedText : vendor.description,
            name: vendor.customerName,
            emailAddress: vendor.emailAddress,
            active: vendor.active,
            ...vendor,
          });
          setVendorContacts([...vendor.customerContacts].sort((a, b) => a.name.localeCompare(b.name)) || []);
        };
        setInitialData();
        dispatch(
          getServiceTicketsByStatusList({
            statusList: [
              appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGNED,
              appConstants.allTypes.SERVICE_TICKET_STATUS.COMPLETED,
              appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGN_SERVICE_ENGINEER,
              appConstants.allTypes.SERVICE_TICKET_STATUS.UNDER_OBSERVATION,
              appConstants.allTypes.SERVICE_TICKET_STATUS.SUPERVISOR_APPROVED,
              appConstants.allTypes.SERVICE_TICKET_STATUS.VENDOR_SUPPORT_REQUIRED,
              appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_RECOMMENDED,
              appConstants.allTypes.SERVICE_TICKET_STATUS.SPARES_REQUIRED,
            ],
          })
        );
      }
    } else if (!id) {
      setEditVendor(initialFormData);
    }
  }, [dispatch, allVendorsList, appConstants]);

  useEffect(() => {
    dispatch(readAsyncStorageValues());
    dispatch(getAllVendors());
  }, [dispatch]);

  const isEmail = (value) => {
    const emailRegex = /^\S+@\S+\.\S+$/;
    return emailRegex.test(value);
  };
  const isPhoneNumber = (value) => {
    const phoneNumberRegex = /^[+]?[(]?\d{1,4}[)]?[-\s./]?\d{1,3}[-\s./]?\d{1,3}[-\s./]?\d{1,4}$/;
    return phoneNumberRegex.test(value);
  };

  const handleSubmit = async () => {
    if (!initialFormData.name) return setErrors({ name: `Name is required.` });
    if (initialFormData.emailAddress && !isEmail(initialFormData.emailAddress))
      return setErrors({ emailAddress: `please type a valid email` });
    setErrors({});
    const vendorExists = allVendorsList.data.some((item) => item.customerName.toLowerCase() === initialFormData.name.toLowerCase());
    if (vendorExists && !id) {
      return toast.error("This data already exists!");
    } else {
      if (initialFormData.description) {
        const responseText = await translateText(initialFormData.description);
        initialFormData.description = responseText[0].translatedText;
      }
      if (id) {
        initialFormData.vendorId = parseInt(id);
        delete initialFormData.customerName;
        return submitForm(updateVendor, initialFormData);
      } else {
        initialFormData.customerType = appConstants.allTypes.TYPE_OF_CUSTOMER.VENDOR;
        return submitForm(createVendor, initialFormData);
      }
    }
  };
  const submitForm = (action, formData) => {
    dispatch(action(formData)).then((vendorResp) => {
      if (vendorResp && vendorResp.error) return toast.error("Something went wrong!");
      if (vendorResp && vendorResp.payload.data && vendorResp.payload.status === 200) {
        if (vendorResp.payload.data.status === "403" || vendorResp.payload.data.message === "Vendor already exists.")
          toast.warn(vendorResp.payload.data.message);
        else
          dispatch(getAllVendors()).then((res) => {
            setEditVendor(null);
            toast.success("Vendor " + (formData.id ? "updated" : "added") + " successfully!", {
              autoClose: 1000,
            });
            if (!id) navigate(`/${Links.EDIT_VENDOR}/` + vendorResp.payload.data.id, { replace: true });
          });
      } else return toast.error("Something went wrong!");
    });
  };

  const handleUpdateContact = () => {
    if (!editContact.name) return setErrors({ name: `Name is required.` });
    if (editContact.email && !isEmail(editContact.email)) return setErrors({ email: `please type a valid email` });
    if (editContact.mobileNumber && !isPhoneNumber(editContact.mobileNumber))
      return setErrors({ mobileNumber: `please type a valid mobile number` });

    setErrors({});
    const vendorContactExists = vendorContacts.some(
      (item) => item.name.toLowerCase() === editContact.name.toLowerCase() && item.id !== editContact.id
    );
    if (vendorContactExists)
      toast.error("Conatct with same name already exists!", {
        autoClose: 1000,
      });
    else {
      if (editContact?.id) {
        editContact.contactId = parseInt(editContact.id);
        delete editContact.id;
        submitContactForm(updateVendorContact, editContact);
      } else {
        editContact.vendorId = parseInt(id);
        submitContactForm(updateVendorContact, editContact);
      }
    }
  };
  const submitContactForm = (action, formData) => {
    dispatch(action(formData)).then((vendorResp) => {
      if (vendorResp && vendorResp.error) return toast.error("Something went wrong!");
      if (vendorResp && vendorResp.payload.data && vendorResp.payload.status === 200) {
        if (vendorResp.payload.data.status === "403" || vendorResp.payload.data.message === "Vendor contact already exists.")
          toast.warn(vendorResp.payload.data.message);
        else
          dispatch(getAllVendors()).then((res) => {
            toast.success("Vendor contact " + (formData.contactId ? "updated" : "added") + " successfully!", {
              autoClose: 1000,
            });
            setEditContact(null);
          });
      } else return toast.error("Something went wrong!");
    });
  };

  const editContactForm = () => (
    <Stack rowGap={2} sx={[customeStyle]}>
      <TextField
        value={editContact.name}
        label={"Name"}
        onChange={(event) => setEditContact({ ...editContact, name: event.target.value })}
        error={errors && errors.name}
        helperText={errors.name}
      />
      <TextField
        value={editContact.email}
        label={"Email"}
        onChange={(event) => setEditContact({ ...editContact, email: event.target.value })}
        error={errors && errors.email}
        helperText={errors.email}
      />
      <TextField
        value={editContact.mobileNumber}
        label={"Mobile number"}
        onChange={(event) => setEditContact({ ...editContact, mobileNumber: event.target.value })}
        error={errors && errors.mobileNumber}
        helperText={errors.mobileNumber}
      />
      <Stack flexDirection={"row"} alignItems={"center"}>
        <Checkbox
          checked={editContact.active}
          onChange={(event) => setEditContact({ ...editContact, active: event.target.checked })}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography>
          {editContact.active ? `Active (Uncheck the box to deactivate contact.)` : `Inactive (Check the box to activate contact.)`}
        </Typography>
      </Stack>
      <Stack flexDirection={"row"} justifyContent={"center"}>
        <Button
          onClick={() => {
            if (!editContact?.id) vendorContacts.shift();
            setEditContact(null);
          }}
        >
          Cancel
        </Button>
        <Button onClick={() => handleUpdateContact()}>Save</Button>
      </Stack>
    </Stack>
  );
  const editVendorForm = () => (
    <Stack rowGap={2}>
      <TextField
        value={initialFormData.name}
        label={"Name"}
        onChange={(event) => setInitialFormData({ ...initialFormData, name: event.target.value })}
        error={errors && errors.name}
        helperText={errors.name}
      />

      <TextField
        value={initialFormData.emailAddress}
        label={"Email"}
        onChange={(event) => setInitialFormData({ ...initialFormData, emailAddress: event.target.value })}
        error={errors && errors.emailAddress}
        helperText={errors.emailAddress}
      />
      <TextField
        value={initialFormData.description}
        maxRows={10}
        inputProps={{ maxLength: 4000 }}
        label="Description"
        multiline
        onChange={(event) => setInitialFormData({ ...initialFormData, description: event.target.value })}
        error={errors && errors.description}
        helperText={errors.description}
      />
      <Typography sx={{ textAlign: "end", mr: 1, mt: -1 }} variant="subtitle2">
        characters: {initialFormData.description?.length}/4000
      </Typography>
      <Stack flexDirection={"row"} alignItems={"center"}>
        <Checkbox
          checked={initialFormData.active}
          onChange={(event) => setInitialFormData({ ...initialFormData, active: event.target.checked })}
          inputProps={{ "aria-label": "controlled" }}
        />
        <Typography>
          {initialFormData.active ? `Active (Uncheck the box to deactivate vendor.)` : `Inactive (Check the box to activate vendor.)`}
        </Typography>
      </Stack>
    </Stack>
  );
  const customeStyle = {
    pl: 4,
    pr: 4,
    pt: 2,
    pb: 2,
    columnGap: 2,
    mb: 1,
    border: "1px solid #ddd",
    borderRadius: "10px",
    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 6px 6px rgba(0,0,0,0.23)",
  };
  return (
    <Stack>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header titles={["Vendor", id ? initialFormData.name : ""]} />
      </Stack>
      <Paper variant="outlined" sx={{ mr: 4, p: 3, ml: 2, mt: 2, mb: 1 }}>
        <Stack flexDirection={"row"} flexWrap={"wrap"} justifyContent={"space-between"}>
          {allVendorsList.isLoading ? (
            <div
              style={{
                textAlign: "center",
                paddingTop: 200,
              }}
            >
              <CircularProgress color="secondary" />
            </div>
          ) : (
            <Stack flexDirection={"row"} alignItems={"flex-start"}>
              {editVendor ? (
                <>{editVendorForm()}</>
              ) : (
                <Stack>
                  <Typography variant="h5" fontWeight={600} mb={1} mt={1}>
                    {initialFormData.name}
                  </Typography>
                  <Typography mt={1}>Email: {initialFormData.emailAddress}</Typography>
                  <Typography mt={1}>Active: {initialFormData.active ? "Yes" : "No"}</Typography>
                  <div style={{ wordWrap: "break-word", maxWidth: "400px" }}>
                    <Typography mt={1}>Description: {initialFormData.description}</Typography>
                  </div>{" "}
                </Stack>
              )}
              <Tooltip title={editVendor ? "Save vendor" : "Edit vendor"}>
                <Button variant="contained" onClick={() => (editVendor ? handleSubmit() : setEditVendor(initialFormData))} sx={{ ml: 1 }}>
                  {editVendor ? "Save" : "Edit"}
                </Button>
              </Tooltip>
            </Stack>
          )}
          <Stack>
            <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} mb={1} pr={4}>
              <Typography variant="h5" fontWeight={600}>
                Contacts
              </Typography>
              {editContact || !id ? null : (
                <Tooltip title="Add contact">
                  <Button
                    onClick={() => {
                      setVendorContacts([{ name: "", email: "", mobileNumber: "", active: true }, ...vendorContacts]);
                      setEditContact({ name: "", email: "", mobileNumber: "", active: true });
                    }}
                    variant="contained"
                    sx={{ ml: 2 }}
                  >
                    Add
                  </Button>
                </Tooltip>
              )}
            </Stack>
            {vendorContacts.length
              ? vendorContacts.map((contact) => (
                  <>
                    {editContact?.id === contact.id ? (
                      editContactForm(contact)
                    ) : (
                      <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} sx={[customeStyle]}>
                        <Stack flexDirection={"row"} alignItems={"center"} minWidth={200}>
                          <ListItemAvatar>
                            <Avatar alt={contact.name} src="/static/images/avatar/1.jpg" />
                          </ListItemAvatar>
                          <Stack>
                            <Typography sx={{ display: "inline", textTransform: "capitalize" }} variant="body1" fontWeight={400}>
                              {contact.name}
                            </Typography>
                            <Typography sx={{ display: "inline" }} variant="body2">
                              Email: {contact.email || "N.A"}
                            </Typography>
                            <Typography sx={{ display: "inline" }} variant="body2">
                              Mobile number: {contact.mobileNumber || "N.A"}
                            </Typography>
                            <Typography sx={{ display: "inline" }} variant="body2">
                              Active: {contact.active ? "Yes " : "No"}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Tooltip title="Edit contact">
                          <Button onClick={() => setEditContact(contact)} variant="contained">
                            Edit
                          </Button>
                        </Tooltip>
                      </Stack>
                    )}
                  </>
                ))
              : "No contacts."}
          </Stack>
          <Stack sx={[{ mt: 1 }]}>
            <Typography variant="h5" fontWeight={600} mb={1.5}>
              Service tickets
            </Typography>
            {isLoading ? (
              <div
                style={{
                  textAlign: "center",
                  paddingTop: 200,
                }}
              >
                <CircularProgress color="secondary" />
              </div>
            ) : (
              <>
                {vendorTickets.length
                  ? vendorTickets.map((ticket) => (
                      <Stack flexDirection={"row"} alignItems={"center"} justifyContent={"space-between"} sx={[customeStyle]}>
                        <Stack>
                          <Stack flexDirection={"row"} alignItems={"center"}>
                            <Typography sx={{ display: "inline", mr: 2 }} variant="body1">
                              {`${ticket.id}`}
                            </Typography>
                            <Typography sx={{ display: "inline" }} variant="body2">
                              Scheduled: {moment(ticket.scheduledDate).format("DD MMM YYYY") || "-"}
                            </Typography>
                          </Stack>
                          <Typography sx={{ display: "inline" }} variant="body2">
                            Status: {`${ticket.status}`}
                          </Typography>
                          <Typography sx={{ display: "inline" }} variant="body2">
                            Priority: {`${ticket.priority}`}
                          </Typography>
                          <Typography sx={{ display: "inline" }} variant="body2">
                            {ticket.ticketCategory || "-"}
                          </Typography>
                        </Stack>
                        <Tooltip title="View contact">
                          <Button onClick={() => navigate(`/${Links.SERVICE_TICKET}/` + ticket.id)}>View</Button>
                        </Tooltip>
                      </Stack>
                    ))
                  : "No tickets."}
              </>
            )}
          </Stack>
        </Stack>
      </Paper>
    </Stack>
  );
};

export default AddEditVendor;
