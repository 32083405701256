import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import authService from "./authService";

// Get user from localStorage
const user = JSON.parse(localStorage.getItem("user"));
const userToken = JSON.parse(localStorage.getItem("userToken"));

const initialState = {
  user: user ? user : null,
  isLoading: false,
  message: "",
  token: userToken || null,
  isAuthenticated: userToken ? true : false,
};

// Login user
export const login = createAsyncThunk("auth/login", async (user, thunkAPI) => {
  try {
    return await authService.login(user);
  } catch (error) {
    const message = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const logout = createAsyncThunk("auth/logout", async () => {
  await authService.logout();
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.message = "";
      state.isAuthenticated = null;
    },
    isUserLoggedIn: (state) => {
      state.isAuthenticated = JSON.parse(localStorage.getItem("userToken")) ? true : false;
      state.user = JSON.parse(localStorage.getItem("user"));
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isAuthenticated = true;
        state.user = action.payload.data;
        state.token = action.payload.data.access_token;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.message = action.payload;
        state.user = null;
        state.token = null;
        state.isAuthenticated = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.isAuthenticated = null;
        state.user = null;
        state.token = null;
      });
  },
});

export const { reset, isUserLoggedIn } = authSlice.actions;
export const selectCurrentToken = (state) => state.auth.token;

export default authSlice.reducer;
