import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updatingOrAddingServiceProblemCategory,
  updatingDueDatesByTicketType,
  updatingNoOfFollowOnTickets,
  readAsyncStorageValues,
} from "features/service/service.slice";
import { logout } from "features/auth/auth.slice";
import {
  Button,
  TextField,
  Box,
  List,
  ListItem,
  IconButton,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Paper,
  useTheme,
  Stack,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { Autocomplete } from "@mui/material";
import { toast } from "react-toastify";
import { object } from "yup/lib/locale";

const Settings = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { appConstants, isLoading } = useSelector((state) => state.service);
  const { mode } = useSelector((state) => state.globalState);
  const [selectedItem, setSelectedItem] = useState("");
  const [categoryName, setCategoryName] = useState({});
  const [problemCategories, setProblemCategories] = useState([]);
  const [dueDatesByTicketType, setDueDatesByTicketType] = useState([]);
  const [noOfFollowOnTickets, setNoOfFollowOnTickets] = useState([]);
  const [editIndex, setEditIndex] = useState(null);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (appConstants?.serviceProblemCategory) {
      setProblemCategories(appConstants.serviceProblemCategory);
    }
    if (appConstants?.dueDatesByTicketType) {
      const dueDates = {};
      for (let item of appConstants?.dueDatesByTicketType) {
        Object.assign(dueDates, item);
      }
      setDueDatesByTicketType(dueDates);
    }
    if (appConstants?.noOfFollowOnTickets) {
      setNoOfFollowOnTickets(appConstants.noOfFollowOnTickets);
    }
  }, [appConstants]);

  const handleitemChange = (event, value) => {
    setSelectedItem(value);
    setCategoryName("");
  };

  const handleEditButtonClick = (index, category) => {
    setEditIndex(index);
    setCategoryName({ [category]: problemCategories[category] });
    setOpen(true);
  };

  const handleSaveDueDates = () => {
    const payloadlist = [];
    Object.keys(dueDatesByTicketType).forEach((element) => {
      payloadlist.push({ [element]: parseInt(dueDatesByTicketType[element]) });
    });
    const payload = {
      due_dates_by_ticket_type: payloadlist,
      configurable: { due_dates_by_ticket_type: { edit: true } },
    };

    dispatch(updatingDueDatesByTicketType(payload)).then((updateResponse) => {
      if (updateResponse.error) {
        toast.error("Failed to update due dates");
      } else {
        toast.success("Due dates updated successfully");
      }
    });
  };

  const handleSaveFollowOnTickets = () => {
    const payloadlist = [];
    for (const item in noOfFollowOnTickets) {
      payloadlist.push({ ...noOfFollowOnTickets[item], count: parseInt(noOfFollowOnTickets[item].count) });
    }
    const payload = {
      no_of_follow_on_tickets: payloadlist,
      configurable: { no_of_follow_on_tickets: { edit: true } },
    };

    dispatch(updatingNoOfFollowOnTickets(payload)).then((updateResponse) => {
      if (updateResponse.error) {
        toast.error("Failed to update follow-on tickets");
      } else {
        toast.success("Follow-on tickets updated successfully");
      }
    });
  };

  const handleAddOrEditConfirm = () => {
    if (Object.values(categoryName)[0].trim() !== "") {
      const isValidated = Object.keys(problemCategories).filter(
        (item) =>
          item.toLowerCase() !== Object.keys(categoryName)[0].toLowerCase() &&
          problemCategories[item].trim() === Object.values(categoryName)[0].trim()
      );
      if (!isValidated.length) {
        if (Object.keys(categoryName)[0] === "newKeyValue") updateCategories(categoryName, "add");
        else updateCategories(categoryName, "edit", editIndex);
      } else toast.warning("Problem category with same name already exists!");
    }
  };

  const handleEditCancel = () => {
    setEditIndex(null);
    setCategoryName({});
    setOpen(false);
  };

  const updateCategories = (updatedCategories, actionType, index = null) => {
    const payload = {
      configurable: { service_problem_category: appConstants.configurableServiceProblemCategory },
    };
    if (actionType === "add") payload.service_problem_category = updatedCategories;
    else if (actionType === "edit" && index !== null) payload.service_problem_category = updatedCategories;

    dispatch(updatingOrAddingServiceProblemCategory(payload)).then((updateResponse) => {
      if (updateResponse.error) {
        toast.error("Failed to update problem categories");
      } else {
        setCategoryName({});
        setEditIndex(null);
        setOpen(false);
        dispatch(readAsyncStorageValues()).then((res) => {
          if (res && res.payload && res.payload.status === 200 && res.payload.data?.resetPassword) {
            toast.warning(
              "You are logged out because you requested a reset password. Please set the new password from mobile app to continue.",
              { autoClose: 10000 }
            );
            dispatch(logout());
          }
        });
        toast.success("Problem categories updated successfully");
      }
    });
  };

  const handleFollowOnTicketChange = (index, key, value) => {
    const regex = /^[0-9\b]+$/;
    if (value === "" || regex.test(value)) {
      const updatedFollowOnTickets = [...noOfFollowOnTickets];
      updatedFollowOnTickets[index] = { ...updatedFollowOnTickets[index], [key]: value };
      setNoOfFollowOnTickets(updatedFollowOnTickets);
    }
  };

  const handleDueDateChange = (key, value) => {
    const regex = /^[0-9\b]+$/;
    if (value === "" || regex.test(value)) {
      setDueDatesByTicketType({
        ...dueDatesByTicketType,
        [key]: value,
      });
    }
  };

  return (
    <div>
      <Box sx={{ mx: 2, mt: 2 }}>
        <Paper
          sx={{
            borderRadius: 2,
            mb: 1.5,
            p: 3,
          }}
        >
          <Stack direction={"row"} mb={4}>
            <Autocomplete
              id="combo-box-demo"
              value={selectedItem}
              onChange={handleitemChange}
              options={["Problem category", "Due Dates by Ticket Type", "No of follow on tickets"]}
              renderInput={(params) => <TextField {...params} label="Select an option" />}
              style={{ width: 260 }}
              size="small"
            />
            {selectedItem === "No of follow on tickets" && (
              <List sx={{ ml: 3, mt: -2 }}>
                {noOfFollowOnTickets.map((followOnTicket, index) => (
                  <ListItem key={index} style={{}}>
                    {Object.entries(followOnTicket).map(([key, value]) => (
                      <Stack key={key} style={{ alignItems: "center" }} direction={"row"}>
                        <span style={{ textTransform: "capitalize" }}>{key}:</span>
                        <TextField
                          value={value}
                          onChange={(e) => handleFollowOnTicketChange(index, key, e.target.value)}
                          variant="outlined"
                          size="small"
                          style={{ marginLeft: 10 }}
                        />
                      </Stack>
                    ))}
                  </ListItem>
                ))}
              </List>
            )}
            {selectedItem === "Due Dates by Ticket Type" && (
              <Stack sx={{ ml: 3, mt: -2 }}>
                <List>
                  {Object.values(appConstants?.allTypes?.SERVICE_CATEGORY).map((category, index) => (
                    <ListItem key={index} style={{ display: "flex", alignItems: "center" }}>
                      {Object.keys(dueDatesByTicketType).includes(category) ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span style={{ marginRight: 8, minWidth: 150 }}>{category}:</span>
                          <TextField
                            value={dueDatesByTicketType[category]}
                            onChange={(e) => handleDueDateChange(category, e.target.value)}
                            variant="outlined"
                            size="small"
                          />
                        </div>
                      ) : null}
                    </ListItem>
                  ))}
                </List>
              </Stack>
            )}
            {selectedItem === "Problem category" && (
              <Box sx={{ ml: 3, display: "flex", alignItems: "flex-start" }}>
                {appConstants?.configurableServiceProblemCategory?.add && (
                  <Stack direction={"row"} columnGap={2}>
                    <TextField
                      label="New Category"
                      value={categoryName?.newKeyValue || ""}
                      onChange={(e) => setCategoryName({ newKeyValue: e.target.value })}
                      variant="outlined"
                      size="small"
                    />
                    <Button onClick={() => handleAddOrEditConfirm()} variant="contained" disabled={isLoading}>
                      Add
                    </Button>
                  </Stack>
                )}
              </Box>
            )}

            <Stack>
              {selectedItem === "Due Dates by Ticket Type" || selectedItem === "No of follow on tickets" ? (
                <Button
                  onClick={() => (selectedItem === "No of follow on tickets" ? handleSaveFollowOnTickets() : handleSaveDueDates())}
                  variant="contained"
                  color="primary"
                  style={{ marginLeft: 10 }}
                >
                  Save
                </Button>
              ) : null}
            </Stack>
          </Stack>
          <Stack sx={{ ml: 1, mt: 2, flexWrap: "wrap" }}>
            {selectedItem === "Problem category" && (
              <Stack sx={{ flexDirection: "row", flexWrap: "wrap" }} columnGap={1} rowGap={1}>
                {Object.keys(problemCategories)
                  .sort((a, b) => a.localeCompare(b))
                  .map((category, index) => (
                    <Stack direction={"row"} alignItems={"center"} columnGap={1} minWidth={300}>
                      <Typography>{problemCategories[category]}</Typography>
                      {appConstants?.configurableServiceProblemCategory?.edit && (
                        <IconButton onClick={() => handleEditButtonClick(index, category)} size="small">
                          <EditIcon sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
                        </IconButton>
                      )}
                    </Stack>
                  ))}
              </Stack>
            )}
          </Stack>
          <Dialog open={open} onClose={handleEditCancel}>
            <DialogTitle>Edit Category</DialogTitle>
            <DialogContent>
              <DialogContentText>Please confirm your edit for the category.</DialogContentText>
              <TextField
                value={categoryName[Object.keys(categoryName)[0]]}
                onChange={(e) => setCategoryName({ [Object.keys(categoryName)[0]]: e.target.value })}
                variant="outlined"
                fullWidth
                margin="normal"
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={handleEditCancel} color="primary">
                Cancel
              </Button>
              <Button onClick={() => handleAddOrEditConfirm()} color="primary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </Paper>
      </Box>
    </div>
  );
};

export default Settings;
