import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import { Search, ArrowRightOutlined as ArrowRight } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import {
  OutlinedInput,
  IconButton,
  InputAdornment,
  Button,
  CircularProgress,
  Typography,
  Stack,
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Paper,
  Tooltip,
  Autocomplete,
  TextField,
  useTheme,
  Tabs,
  Tab,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { ZoomIn as ViewIcon, Download as DownloadIcon, Edit as EditIcon } from "@mui/icons-material";
import { toast } from "react-toastify";

import { getMachines, readAsyncStorageValues, getAllServiceTickets } from "features/service/service.slice";
import { Links } from "utils/pageLinkNames";
import Header from "component/Header";
import DataGridTable from "component/DataGridTable";
import { exportToCsv } from "utils/utilFunctions";

const AllServiceTickets = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const theme = useTheme();
  const { pathname } = useLocation();
  const { mode } = useSelector((state) => state.globalState);

  const [searchParams, setSearchParams] = useSearchParams();
  const [serviceTickets, setServiceTickets] = useState([]);
  const [showExportAlertDialog, setShowExportAlertDialog] = useState(false);

  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(true);

  const { user } = useSelector((state) => state.auth);

  const [filterOptions, setFilterOptions] = useState({
    overDue: false,
    upComingIn: null,
    status: [],
    category: [],
  });
  const { machinesData, appConstants, isLoading, allServiceTickets } = useSelector((state) => state.service);
  useEffect(() => {
    dispatch(readAsyncStorageValues());
    dispatch(getMachines());
  }, [dispatch, searchParams]);
  useEffect(() => {
    if (appConstants) dispatch(getAllServiceTickets());
  }, [dispatch, appConstants]);

  useEffect(() => {
    if (allServiceTickets.data) loadData();
  }, [dispatch, searchParams, filterOptions, allServiceTickets, pathname]);

  const loadData = () => {
    const serviceTickets = [];
    allServiceTickets.data.forEach((ticket) => {
      const assigneUser = appConstants?.appUsers?.find((user) => {
        return ticket?.assigneeId?.[0] === user.userId;
      });
      if (filterOptions.category && filterOptions.category.length && !filterOptions.category.includes(ticket?.ticketCategory)) return;
      else if (filterOptions.status && filterOptions.status.length && !filterOptions.status.includes(ticket?.status)) return;
      else if (
        filterOptions &&
        filterOptions.upComingIn &&
        ticket.dueDate &&
        !moment(ticket.dueDate).isBetween(moment(), moment().add(parseInt(filterOptions.upComingIn), "d"))
      )
        return;
      else if (filterOptions && filterOptions.upComingIn && !ticket.dueDate) return;
      else if (
        filterOptions &&
        filterOptions.overDue &&
        !moment(moment().format("DD MMM YYYY")).isAfter(moment(ticket.dueDate).format("DD MMM YYYY"))
      )
        return;
      else
        return serviceTickets.push({
          id: ticket?.ticketId,
          ticketId: ticket?.ticketId,
          ticketCategory: ticket.ticketCategory,
          status: ticket.status,
          machineManufacturer: ticket?.manufacturer || "",
          machineModel: ticket?.modelName || "",
          machineSerialNumber: ticket?.serialNumber || "",
          machineMaintenanceCode: ticket?.maintenanceCode || "",
          description: ticket?.description || "",
          dueDate: ticket.dueDate ? moment(ticket.dueDate).format("DD MMM YY") : null,
          dateCreated: moment(ticket.openDate).format("DD MMM YY"),
          scheduledDate: moment(ticket.scheduledDate).format("DD MMM YY"),
          priority: ticket.priority ? ticket.priority : null,
          vendor: ticket.vendor ? ticket.vendor : null,
          technician: assigneUser?.name,
          safetyTicket: ticket?.safetyTicket ? ticket?.safetyTicket : null,
        });
    });
    setServiceTickets(serviceTickets?.sort((a, b) => b.ticketId - a.ticketId) || []);
    setLoading(false);
  };

  const renderGridActions = (data) => {
    return (
      <div>
        <IconButton sx={{ mr: 1 }} onClick={() => navigate(`/${Links.SERVICE_TICKET}/` + data.row.ticketId)}>
          <Tooltip title="View">
            <ViewIcon sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
          </Tooltip>
        </IconButton>
        {user?.roles?.includes(appConstants?.allTypes?.USER_ROLES?.ROLE_MAINTENANCE_SUPERVISOR) && (
          <IconButton
            sx={{ mr: 1 }}
            onClick={() => navigate(`/${Links.UPDATE_SERVICE_TICKET}/` + data.row.ticketId, { state: { enableEditing: true } })}
          >
            <Tooltip title="Edit">
              <EditIcon sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
            </Tooltip>
          </IconButton>
        )}
      </div>
    );
  };

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));
  const columnAttributes = [
    {
      field: "ticketId",
      headerName: "ID",
      minWidth: 60,
      flex: 0.3,
    },
    {
      field: "dateCreated",
      headerName: "Open Date",
      minWidth: 90,
      flex: 0.4,
      sortComparator: dateComparator,
    },
    {
      field: "scheduledDate",
      headerName: "Scheduled Date",
      minWidth: 90,
      flex: 0.4,
      sortComparator: dateComparator,
    },
    {
      field: "dueDate",
      headerName: "Due Date",
      minWidth: 90,
      flex: 0.4,
      sortComparator: dateComparator,
    },
    {
      field: "machineMaintenanceCode",
      headerName: "Maintenance Code",
      minWidth: 110,
      editType: "dropdownedit",
      textAlign: "left",
      flex: 0.4,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 110,
      editType: "dropdownedit",
      textAlign: "left",
      flex: 0.4,
    },
    {
      field: "machineManufacturer",
      headerName: "Manufacturer",
      minWidth: 90,
      flex: 0.4,
    },
    {
      field: "machineModel",
      headerName: "Model",
      minWidth: 90,
      flex: 0.4,
    },
    {
      field: "ticketCategory",
      headerName: "Category",
      minWidth: 90,
      flex: 0.5,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 90,
      flex: 0.6,
    },
    {
      field: "vendor",
      headerName: "Vendor",
      minWidth: 90,
      valueGetter: (params) => {
        const vendorId = params.row?.vendor?.id;
        const customer = appConstants.customers.find((user) => user.id === vendorId);
        return customer?.customerName || "Internal";
      },
      flex: 0.6,
    },
    {
      field: "Actions",
      headerName: "Actions",
      width: 140,
      flex: 0.8,
      sortable: false,
      filterable: false,
      renderCell: renderGridActions,
      disableClickEventBubbling: true,
    },
  ];
  const downloadCsv = (e, exportAll = false) => {
    e.preventDefault();
    const serviceTicketsList = [];
    if (exportAll) {
      allServiceTickets.data.forEach((ticket) => {
        const machine = machinesData.find((machine) => machine.id === ticket?.machine?.id);
        const assigneUser = appConstants?.appUsers?.find((user) => {
          return ticket?.assignees?.[0] === user.userId;
        });
        return serviceTicketsList.push({
          ticketId: ticket?.ticketId,
          ticketCategory: ticket?.ticketCategory,
          status: ticket?.status,
          machineManufacturer: machine?.manufacturer || "",
          machineModel: machine?.model || "",
          machineSerialNumber: machine?.serialNumber || "",
          machineMaintenanceCode: machine?.maintenanceCode || "",
          description: ticket?.description || "",
          dueDate: moment(ticket.dueDate).format("DD MMM YYYY"),
          dateCreated: moment(ticket.openDate).format("DD MMM YYYY"),
          scheduledDate: moment(ticket.scheduledDate).format("DD MMM YYYY"),
          technician: assigneUser?.name,
        });
      });
    }
    const headers = [
      "Ticket Id,Open Date, Scheduled Date, Maintenance Code,Description, Manufacturer,Model,Serial Number,Technician, Category, Status",
    ];
    const keys = [
      "ticketId",
      "dateCreated",
      "scheduledDate",
      "machineMaintenanceCode",
      "description",
      "machineManufacturer",
      "machineModel",
      "machineSerialNumber",
      "technician",
      "ticketCategory",
      "status",
    ];
    exportToCsv(headers, keys, exportAll ? serviceTicketsList : serviceTickets, "ServiceTickets");
    setShowExportAlertDialog(false);
  };
  const filterTickets = (filterOption, filterItem) => {
    setFilterOptions({ ...filterOptions, [filterOption]: filterOptions[filterOption].filter((item) => item !== filterItem) });
  };
  const renderCustomFilter = (filterOption) => (
    <Paper>
      {filterOptions &&
      filterOptions[filterOption] &&
      filterOptions[filterOption].length &&
      filterOption !== "category" &&
      filterOption !== "status" ? (
        <Stack direction={"row"} flexWrap={"wrap"} alignItems={"center"} px={0.5} py={filterOptions && filterOptions.overDue ? 0 : 0.3}>
          <Typography m={1} textTransform={"capitalize"}>
            {filterOption}
          </Typography>
          {filterOptions[filterOption].map((filterItem) => (
            <Stack direction={"row"} alignItems={"center"} pl={1} borderRadius={10} backgroundColor="#00808030">
              <Typography variant="caption">{filterItem}</Typography>
              <IconButton onClick={() => filterTickets(filterOption, filterItem)}>
                <Tooltip title="Clear item">
                  <CloseIcon sx={{ fontSize: 15 }} />
                </Tooltip>
              </IconButton>
            </Stack>
          ))}
        </Stack>
      ) : null}
    </Paper>
  );

  if (isLoading)
    return (
      <div
        style={{
          textAlign: "center",
          paddingTop: 200,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  return (
    <>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header titles={["Service Tickets"]} />
      </Stack>

      <Box sx={{ mx: 2 }}>
        <Stack direction={"row"} justifyContent={"space-between"} my={2}>
          <Stack direction={"row"} flexWrap={"wrap"} alignItems={"center"} gap={2}>
            <Box>
              <Button
                startIcon={<AddIcon />}
                sx={{ height: "self" }}
                autoCapitalize="none"
                variant="contained"
                onClick={() => navigate(`/${Links.CREATE_SERVICE_TICKET}/`)}
              >
                Create
              </Button>
            </Box>

            <OutlinedInput
              id="outlined-adornment-password"
              value={searchValue}
              onChange={(event) => handleSearchChange(event)}
              placeholder="Search..."
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" edge="end">
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
              sx={{ minWidth: 300, maxWidth: 600, height: 35 }}
            />

            <Paper>
              <Stack
                direction={"row"}
                backgroundColor={
                  filterOptions && filterOptions.overDue
                    ? mode === "dark"
                      ? theme.palette.grey[700]
                      : theme.palette.primary[700]
                    : mode === "dark"
                    ? theme.palette.grey[10]
                    : theme.palette.primary[200]
                }
                alignItems={"center"}
                px={0.5}
                py={filterOptions && filterOptions.overDue ? 0 : 0.3}
              >
                <Tooltip title="Filter over due tickets">
                  <IconButton onClick={() => setFilterOptions({ ...filterOptions, overDue: true })} size="small">
                    <Typography sx={{ color: "#fff" }}>Over due</Typography>
                  </IconButton>
                </Tooltip>
                {filterOptions && filterOptions.overDue ? (
                  <IconButton onClick={() => setFilterOptions({ ...filterOptions, overDue: false })}>
                    <Tooltip title="Clear filter">
                      <CloseIcon sx={{ fontSize: 18, color: "#fff" }} />
                    </Tooltip>
                  </IconButton>
                ) : null}
              </Stack>
            </Paper>
            <Autocomplete
              disablePortal
              size="small"
              id="combo-box-demo"
              onChange={(event, newValue, reason) => {
                setFilterOptions({ ...filterOptions, upComingIn: reason === "clear" ? null : parseInt(newValue) });
              }}
              value={filterOptions && filterOptions.upComingIn ? filterOptions.upComingIn : ""}
              options={[7, 15, 30, 60, 90]}
              sx={{ minWidth: 150, maxWidth: 600 }}
              renderInput={(params) => <TextField {...params} label="Upcoming in" style={{}} />}
            />

            <Autocomplete
              disablePortal
              multiple
              filterSelectedOptions
              size="small"
              id="combo-box-demo"
              onChange={(event, newValue, reason) => {
                setFilterOptions({ ...filterOptions, status: reason === "clear" ? [] : newValue });
              }}
              value={filterOptions?.status || null}
              options={
                appConstants && appConstants?.allTypes
                  ? Object.values(appConstants?.allTypes.SERVICE_TICKET_STATUS)
                      .filter(
                        (item) =>
                          item !== appConstants.allTypes.SERVICE_TICKET_STATUS.TIME_LOGGED &&
                          item !== appConstants.allTypes.SERVICE_TICKET_STATUS.COMPLETE &&
                          item !== appConstants.allTypes.SERVICE_TICKET_STATUS.SUPERVISOR_APPROVED
                      )
                      .sort()
                  : []
              }
              sx={{
                minWidth: 220,
                maxWidth: 500,
                "& .MuiChip-label": {
                  color: "#000",
                },
                "& .MuiChip-deleteIcon": {
                  color: "#000",
                },
              }}
              renderInput={(params) => <TextField {...params} label="Status" />}
            />

            <Autocomplete
              disablePortal
              multiple
              filterSelectedOptions
              size="small"
              id="combo-box-demo"
              onChange={(event, newValue, reason) => {
                setFilterOptions({
                  ...filterOptions,
                  category: reason === "clear" ? [] : newValue,
                });
              }}
              value={filterOptions && filterOptions?.category ? filterOptions.category : null}
              options={
                appConstants && appConstants?.allTypes
                  ? pathname.substring(1) === Links.PERIODIC_SERVICE_TICKETS
                    ? Object.values(appConstants.allTypes.SERVICE_CATEGORY)
                        .filter(
                          (item) =>
                            item !== appConstants.allTypes.SERVICE_CATEGORY.TASK &&
                            item !== appConstants.allTypes.SERVICE_CATEGORY.EQUIPMENT_SUPPORT
                        )
                        .sort()
                    : pathname.substring(1) === Links.ONE_TIME_SERVICE_TICKETS
                    ? Object.values(appConstants.allTypes.SERVICE_CATEGORY)
                        .filter(
                          (item) =>
                            item !== appConstants.allTypes.SERVICE_CATEGORY.CALIBRATION &&
                            item !== appConstants.allTypes.SERVICE_CATEGORY.INSPECTION &&
                            item !== appConstants.allTypes.SERVICE_CATEGORY.INTERNAL_AUDIT &&
                            item !== appConstants.allTypes.SERVICE_CATEGORY.PREVENTIVE_MAINTENANCE
                        )
                        .sort()
                    : Object.values(appConstants.allTypes.SERVICE_CATEGORY)
                  : []
              }
              sx={{ minWidth: 200, maxWidth: 500 }}
              renderInput={(params) => <TextField {...params} label="Ticket category" />}
            />
            {Object.keys(filterOptions).map((filterOption) => renderCustomFilter(filterOption))}
          </Stack>
          <Stack>
            <Button
              startIcon={<DownloadIcon />}
              sx={{ minWidth: 170 }}
              autoCapitalize="none"
              variant="contained"
              onClick={(e) => {
                const ifFilterExists = Object.keys(filterOptions).filter((item) =>
                  filterOptions[item]
                    ? filterOptions[item] === true || filterOptions[item] > 0
                      ? filterOptions[item]
                      : filterOptions[item].length
                    : filterOptions[item]
                );
                ifFilterExists.length ? setShowExportAlertDialog(true) : downloadCsv(e);
              }}
            >
              Export to csv
            </Button>
          </Stack>
        </Stack>
        <>
          <DataGridTable
            loading={loading}
            rowId={"ticketId"}
            data={serviceTickets.filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchValue.toLowerCase()))}
            columnAttributes={columnAttributes}
          />
        </>

        <Dialog open={showExportAlertDialog} onClose={() => setShowExportAlertDialog(false)}>
          <DialogContent>
            <Typography mt={1}>Click "Export filtered" to export only the filtered tickets.</Typography>
            <Typography mt={1}>Click "Export all" to export all tickets.</Typography>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setShowExportAlertDialog(false)}>Cancel</Button>
            <Button variant="contained" onClick={(e) => downloadCsv(e)}>
              Export filtered
            </Button>
            <Button variant="contained" onClick={(e) => downloadCsv(e, true)}>
              Export all
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};
export default AllServiceTickets;
