import api from "app/api";
import multiInstance from "app/multiPartApi";
import moment from "moment";

const updatingNoOfFollowOnTickets = async (payload) => {
  const { data } = await api.post("/serviceapi/updatingNoOfFollowOnTickets", payload);
  return data;
};

const updatingDueDatesByTicketType = async (payload) => {
  const { data } = await api.post("/serviceapi/updatingDueDatesByTicketType", payload);
  return data;
};

const updatingOrAddingServiceProblemCategory = async (payload) => {
  const { data } = await api.post("/serviceapi/updatingOrAddingServiceProblemCategory", payload);
  return data;
};

const getSparesPartsFromServiceTicket = async (payload) => {
  const { data } = await api.get("/serviceapi/getSparesPartsFromServiceTicket?serviceTicketId=" + payload);
  return data;
};

const issueSparesPartsForServiceTicket = async (payload) => {
  const response = await api.post("/serviceapi/issueSpareParts", payload);
  return response;
};

const getServiceTicketsByStatusListAndDateCreatedInDaysV1 = async (payload) => {
  const { data } = await api.post("/serviceapi/getServiceTicketsByStatusListAndDateCreatedInDaysV1?days=" + payload.dateRange, {
    statusList: payload.statusList,
  });
  return data;
};

const getServiceTicketsByStatusList = async (payload) => {
  const { data } = await api.post("/serviceapi/getServiceTicketsByStatusList", {
    statusList: payload.statusList,
  });
  return data;
};

const getServiceTicketsStatusByTechId = async (payload) => {
  const apiUrl =
    `/serviceapi/getServiceTicketsStatusByTechId?startDate='${payload.startDate}'&endDate='${payload.endDate}'` +
    (payload?.userId ? `&userId=${payload?.userId || ""}` : "");
  const { data } = await api.post(apiUrl);
  return data;
};

const getServiceTicketsByUserId = async (payload) => {
  const { data } = await api.get(
    "/serviceapi/getServiceTicketsByUserId?userId=" + payload.userId + (payload?.timeStamp ? `&timeStamp=${payload?.timeStamp || ""}` : "")
  );
  if (data && data.length) {
    const modified =
      data
        .map((ticket) => {
          return {
            ...ticket,
            ticketId: ticket.ticketId,
            id: ticket.ticketId,
            dateCreated: moment(ticket.dateCreated).format("DD MMM YYYY"),
          };
        })
        .sort((a, b) => b.ticketId - a.ticketId) || [];
    return modified;
  }
  return data;
};

const getTimespentByServicetTicketId = async (payload) => {
  const response = await api.post("/serviceapi/getTimespentByServicetTicketId?days=" + payload);
  return response;
};

const getAllServiceTickets = async (payload) => {
  const response = await api.post("/serviceapi/getAllServiceTickets");
  return response;
};

const addMachine = async (payload) => {
  const response = await api.post("/serviceapi/addMachine", payload);
  return response;
};

const readSpareParts = async () => {
  const response = await api.get("/serviceapi/readSpareParts");
  return response;
};

const notifyVendor = async (payload) => {
  const response = await api.post("/serviceapi/notifyVendor", payload);
  return response;
};

const addSpareParts = async (payload) => {
  const response = await multiInstance.post("/serviceapi/createSparePart", payload);
  return response;
};

const updateSpareParts = async (payload) => {
  const response = await multiInstance.post("/serviceapi/updateSparePart", payload);
  return response;
};

const deleteSpareParts = async (payload) => {
  const response = await api.post("/serviceapi/deleteSparePart", payload);
  return response;
};

const updateMachine = async (payload) => {
  const response = await api.post("/serviceapi/updateMachine", payload);
  return response;
};

const addImageOrVideoToServiceTicket = async (payload, progressCallback) => {
  const response = await multiInstance.post("serviceapi/addImageOrVideoToServiceTicket", payload, {
    onUploadProgress: progressCallback,
  });
  return response;
};

const readServiceTicketMessages = async (payload) => {
  const response = await api.get("/serviceapi/readServiceTicketMessagesByServiceTicketId?id=" + payload);
  if (response && response?.data) {
    const modifiedData = response?.data.sort(
      (a, b) => new Date(b.postedDate || b.dateCreated).getTime() - new Date(a.postedDate || a.dateCreated).getTime()
    );
    return { ...response, data: modifiedData };
  }
  return response;
};

const deleteImageOrVideoFromServiceTicket = async (payload) => {
  const response = await api.post("serviceapi/deleteImageOrVideoFromServiceTicket", payload);
  return response;
};

const updateNotesForTicket = async (payload) => {
  const response = await api.post("serviceapi/addTextMessageToServiceTicket", payload);
  return response;
};

const readServiceTimeLog = async (payload) => {
  const response = await api.get("/serviceapi/readServiceTimeLog?id=" + payload);
  return response;
};

const addTimeLogToServiceTicket = async (payload) => {
  const response = await api.post("serviceapi/addTimeLogToServiceTicket", payload);
  return response;
};

const getMachineDepartments = async () => {
  const response = await api.get("/serviceapi/machineDepartments");
  return response;
};

const deleteMachine = async (payload) => {
  const response = await api.post("/serviceapi/deleteMachine", payload);
  return response;
};

const getServiceTicketsByDepartmentAndDateCreatedInDays = async (payload) => {
  const response = await api.post("/serviceapi/getServiceTicketsByDepartmentAndDateCreatedInDays?days=" + payload);
  return response;
};

const readServiceTicketsByMachineId = async (payload) => {
  const response = await api.post("/serviceapi/readServiceTicketsByMachineId?id=" + payload.ticketId);
  if (response && response?.data) {
    const modified = response?.data.map((item) => {
      const assignees =
        item.assignees?.map((assignee) => payload.allAppUsers.find((user) => user.userId === parseInt(assignee))?.name || "") || [];
      return {
        ...item,
        description: item.description.trim(),
        assignees: assignees.join(", ") || "",
      };
    });
    return { ...response, data: modified };
  }
  return response;
};

const getMachinesNotWorking = async () => {
  const response = await api.get("/serviceapi/getMachinesNotWorkingDashboard");
  return response;
};

const getActiveMachinesByStatus = async () => {
  const response = await api.get("/serviceapi/getActiveMachinesByStatus");
  return response;
};

const getOverdueServiceTickets = async () => {
  const response = await api.get("/serviceapi/getOverdueServiceTickets");
  return response.data;
};

const getOutOfServiceMachinesByCategory = async () => {
  const response = await api.get("/serviceapi/getOutOfServiceMachinesByCategory");
  return response.data;
};

const engineerReportsById = async (payload) => {
  const response = await api.get("/serviceapi/getServiceEngineerTickets?svcEngrId=" + payload);
  return response;
};
const readAsyncStorageValues = async () => {
  const { data } = await api.get("/serviceapi/readAsyncStorageValues");
  if (data && data?.appUsers) {
    const appUsers =
      data?.appUsers
        ?.map((item) => ({ ...item, nameEmpno: `${item?.name || ""} ${item?.userName || ""}` }))
        ?.sort((a, b) => a.name.localeCompare(b.name)) || data?.appUsers?.sort((a, b) => a.name.localeCompare(b.name));
    return { ...data, appUsers: appUsers.filter((user) => user.enabled), allAppUsers: appUsers };
  }
  return data;
};

const getMachines = async () => {
  const { data } = await api.get("/serviceapi/readMachinesByCustomerId?id=5284");
  return data;
};

const createMachineLocation = async (payload) => {
  const { data } = await api.post("/serviceapi/createMachineLocation", payload);
  return data;
};

const updateMachineLocation = async (payload) => {
  const { data } = await api.post("/serviceapi/updateMachineLocation", payload);
  return data;
};

const getMonthlyMachineBreakdownTime = async () => {
  const response = await api.get("/serviceapi/getMonthlyMachineBreakdownTime");
  return response;
};
const getWeeklyMachineBreakdownTime = async () => {
  const response = await api.get("/serviceapi/getWeeklyMachineBreakdownTime");
  return response;
};

const getMonthlyBreakdownHoursPercent = async () => {
  const response = await api.get("/serviceapi/getMonthlyBreakdownHoursPercent");
  const modified = response && response?.data ? response?.data.reverse() : [];
  return { ...response, data: modified };
};

const rescheduleServiceTickets = async (payload) => {
  const response = await api.post("/serviceapi/rescheduleServiceTickets?days=" + payload);
  return response;
};

const getWeeklyBreakdownHoursPercent = async () => {
  const response = await api.get("/serviceapi/getWeeklyBreakdownHoursPercent");
  const modified = response?.data.map((item, index) => ({
    ...item,
    baseline: 3,
    week:
      index === 0
        ? `${moment(new Date()).startOf("isoWeek").format("ddd DD MMM")} - ${moment(new Date()).endOf("isoWeek").format("ddd DD MMM")}`
        : item.week,
  }));
  return { ...response, data: modified.reverse() };
};

const readHoursDataByDays = async (payload) => {
  const response = await api.get("/serviceapi/getTimespentByDepartment?days=" + payload);
  return response;
};
const getServiceTicketById = async (payload) => {
  const { data } = await api.get("/serviceapi/readServiceTicket?id=" + payload);
  const additionalNotesObject = data?.additionalNotes ? JSON.parse(data.additionalNotes) : null;
  if (additionalNotesObject) {
    return { ...data, additionalNotes: additionalNotesObject };
  }
  return data;
};

const completeServiceTicket = async (payload) => {
  const response = await multiInstance.post("serviceapi/completeServiceTicketReport", payload);
  return response;
};

const createServiceTicket = async (payload) => {
  const response = await api.post("/serviceapi/createServiceTicket", payload);
  return response;
};
const updateServiceTicket = async (payload) => {
  const response = await api.post("/serviceapi/updateServiceTicket", payload);
  return response;
};

const approveServiceTicket = async (payload) => {
  const response = await api.get("/serviceapi/serviceTicketApprovedBySupervisor?serviceTktId=" + payload);
  return response;
};

const getCompletedReportPdf = async (payload) => {
  const response = await api.get("/serviceapi/readReportPathByServiceTicketId?serviceTicketId=" + payload);
  return response;
};

const createTemplate = async (payload) => {
  const response = await api.post("/serviceapi/createTemplate", payload);
  return response;
};

const updateTemplate = async (payload) => {
  const response = await api.post("/serviceapi/updateTemplate", payload);
  return response;
};

const getAllTemplates = async () => {
  const response = await api.get("/serviceapi/getAllTemplates");
  if (response && response?.data) {
    const modified = response?.data.sort((a, b) => a.name.localeCompare(b.name));
    return { ...response, data: modified };
  }
  return response;
};

const getTechnicianLoggedTime = async (payload) => {
  const response = await api.get(`/serviceapi/getTechnicianLoggedTime?logDate=${payload.logDate}`);
  return response.data;
};

const createMachineModel = async (payload, progressCallback) => {
  const response = await api.post("/serviceapi/createMachineModel", payload, {
    onUploadProgress: progressCallback,
  });
  return response;
};

const updateMachineModel = async (payload, progressCallback) => {
  const response = await api.post("/serviceapi/updateMachineModel", payload, {
    onUploadProgress: progressCallback,
  });
  return response;
};

const readServiceTicketMessagesByMachineId = async (payload) => {
  const response = await api.get("/serviceapi/readServiceTicketMessagesByMachineId?id=" + payload);
  if (response && response?.data) {
    const modifiedData = response?.data.sort(
      (a, b) => new Date(b.postedDate || b.dateCreated).getTime() - new Date(a.postedDate || a.dateCreated).getTime()
    );
    return { ...response, data: modifiedData };
  }
  return response;
};

const deleteMachineLocation = async (payload) => {
  const response = await api.get("serviceapi/deleteMachineLocation?id=" + payload);
  return response;
};

const completeInternalAudit = async (payload) => {
  const response = await api.post("serviceapi/completeInternalAudit", payload);
  return response;
};

const Service = {
  getAllTemplates,
  createTemplate,
  updateTemplate,
  engineerReportsById,
  readAsyncStorageValues,
  getServiceTicketsByUserId,
  getServiceTicketsByStatusList,
  getServiceTicketsStatusByTechId,
  getServiceTicketsByStatusListAndDateCreatedInDaysV1,
  getServiceTicketsByDepartmentAndDateCreatedInDays,
  getTimespentByServicetTicketId,
  getMachines,
  readHoursDataByDays,
  addMachine,
  updateMachine,
  readServiceTicketsByMachineId,
  deleteMachine,
  getMachineDepartments,
  readSpareParts,
  addSpareParts,
  deleteSpareParts,
  updateSpareParts,
  getMachinesNotWorking,
  getServiceTicketById,
  completeServiceTicket,
  createServiceTicket,
  updateServiceTicket,
  approveServiceTicket,
  addImageOrVideoToServiceTicket,
  getCompletedReportPdf,
  readServiceTicketMessages,
  deleteImageOrVideoFromServiceTicket,
  updateNotesForTicket,
  readServiceTimeLog,
  addTimeLogToServiceTicket,
  getActiveMachinesByStatus,
  getOverdueServiceTickets,
  getOutOfServiceMachinesByCategory,
  getMonthlyMachineBreakdownTime,
  getWeeklyMachineBreakdownTime,
  getMonthlyBreakdownHoursPercent,
  getWeeklyBreakdownHoursPercent,
  createMachineLocation,
  updateMachineLocation,
  getTechnicianLoggedTime,
  createMachineModel,
  updateMachineModel,
  readServiceTicketMessagesByMachineId,
  rescheduleServiceTickets,
  notifyVendor,
  deleteMachineLocation,
  getAllServiceTickets,
  updatingOrAddingServiceProblemCategory,
  updatingDueDatesByTicketType,
  updatingNoOfFollowOnTickets,
  completeInternalAudit,
  getSparesPartsFromServiceTicket,
  issueSparesPartsForServiceTicket,
};

export default Service;
