import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
import { updateMachineModel, readAsyncStorageValues } from "features/service/service.slice";
import {
  DialogTitle,
  Stack,
  Paper,
  Typography,
  DialogActions,
  Button,
  Dialog,
  Tooltip,
  DialogContent,
  useTheme,
  Box,
  IconButton,
} from "@mui/material";
import { toast } from "react-toastify";
import Header from "component/Header";
import CreateEditMachineModel from "pages/Assets/MachineModels/CreateEditMachineModel";
import AuthorizedImage from "component/AuthorizedImage";
import AuthorizedPDF from "component/AuthorizedPDF";
import videoImage from "images/Video.png";
import ViewImageVideoModal from "component/ViewImageVideoModal";
import {
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ChevronLeft as LeftArrowIcon,
  ChevronRight as RightArrowIcon,
  Delete as DeleteIcon,
  Upload,
} from "@mui/icons-material";
import ConfirmDeleteModal from "component/ConfirmDeleteModal";
import UploadProgressModal from "component/UploadProgressModal";

const MachineModel = () => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { state } = useLocation();
  const { id } = useParams();
  const [initialFormData, setInitialFormData] = useState({
    model: "",
    info: { artifacts: [] },
  });
  const [enableEditing, setEnableEditing] = useState(false);
  const { appConstants } = useSelector((state) => state.service);
  const [showDialog, setShowDialog] = useState(false);
  const [showDialogView, setShowDialogView] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [currentFilePathIndex, setCurrentFilePathIndex] = useState(null);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showUploadDialog, setShowUploadDialog] = useState(false);
  const [uploadProgress, setUploadProgress] = useState({ progress: 0, rate: 0, total: 0, buffer: 10 });

  useEffect(() => {
    if (id && appConstants) {
      const modelInfo = appConstants?.machineModels?.find((modelItem) => modelItem.id === parseInt(id));
      const info = modelInfo?.info ? JSON.parse(modelInfo?.info) : {};
      setInitialFormData({
        ...modelInfo,
        info: { ...info, artifacts: info.artifacts?.map((item) => ({ ...item, message: item.url })) || [] },
      });
    }
  }, [appConstants]);

  useEffect(() => {
    dispatch(readAsyncStorageValues());
    if (!id || state?.enableEditing) setEnableEditing(true);
  }, [dispatch, initialFormData.model]);

  const handleFileSelect = (event) => {
    setSelectedFiles([...event.target.files]);
    setShowDialog(true);
  };

  const handleCloseDialog = () => {
    setSelectedFiles([]);
    setShowDialog(false);
    setCurrentIndex(0);
    // fileInputRef.current.value = null;
  };
  const goToPrevSlide = () => setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : 0);
  const goToNextSlide = () => setCurrentIndex(currentIndex === selectedFiles.length - 1 ? 0 : currentIndex + 1);

  const progressCallback = (progressEvent) => {
    const percentRateFraction = progressEvent.rate / 1048576;
    const percentTotalFraction = progressEvent.total / 1048576;
    const percentLoadedFraction = progressEvent.loaded / 1048576;
    const percentFraction = percentLoadedFraction / percentTotalFraction;
    const roundedNum = Math.round(percentLoadedFraction * 10) / 10;
    const roundedNumRate = Math.round(percentRateFraction * 10) / 10;
    const roundedNumTotal = Math.round(percentTotalFraction * 10) / 10;
    const diff = Math.random() * 10;
    setUploadProgress({ progress: roundedNum, rate: roundedNumRate, total: roundedNumTotal, buffer: percentFraction * 100 + diff + 5 });
  };

  const submitData = (data = initialFormData, action = null) => {
    const modelFormData = new FormData();
    const artifacts = [];
    const updateObj = {
      manufacturer: data.manufacturer,
      modelName: data.model,
      modelId: id,
      info: data?.info || {},
    };
    if (selectedFiles.length) {
      selectedFiles.forEach((item, index) => {
        modelFormData.append("image" + index, item);
        artifacts.push({
          name: item.name,
          type: item.type.includes("image/")
            ? appConstants.allTypes.TYPE_OF_CONTENT.IMAGE
            : item.type.includes("application/pdf")
            ? appConstants.allTypes.TYPE_OF_CONTENT.PDF
            : appConstants.allTypes.TYPE_OF_CONTENT.VIDEO,
          image: "image" + index,
        });
      });
      updateObj.artifacts = artifacts;
    }
    modelFormData.append("modelDetails", JSON.stringify(updateObj));
    setShowDialog(false);
    if (action !== "DELETE") setShowUploadDialog(true);
    dispatch(updateMachineModel({ modelFormData, progressCallback: progressCallback })).then((res) => {
      setSelectedFiles([]);
      if (res && res.error) toast.error("Something went wrong!");
      if (res && res.payload.data && res.payload.status === 200) {
        dispatch(readAsyncStorageValues()).then((resp) => {
          toast.success(`Artifact(s) ${id ? "updated" : "added"} successfully!`, {
            autoClose: 1000,
          });
          setUploadProgress({ progress: 0, rate: 0, total: 0, buffer: 10 });
          setShowUploadDialog(false);
          // props.setEnableEditing(false);
        });
      } else toast.error("Something went wrong!");
    });
  };
  const handleViewFile = (articfactIndex) => {
    setCurrentFilePathIndex(articfactIndex);
    setShowDialogView(true);
  };

  const handleCloseDialogView = () => {
    setCurrentFilePathIndex(null);
    setShowDialogView(false);
  };

  const onClickDeleteArtifact = (artifact) => {
    const { artifacts } = initialFormData?.info;
    if (artifacts && initialFormData?.info) {
      setSelectedFile(artifact);
      setShowConfirmDeleteModal(true);
    }
  };
  const onConfirmDeleteArtifact = () => {
    const { artifacts } = initialFormData?.info;
    const newFormData = {
      ...initialFormData,
      info: { ...initialFormData?.info, artifacts: artifacts.filter((item) => item !== selectedFile) || [] },
    };
    setSelectedFile(null);
    setInitialFormData(newFormData);
    closeConfirmDeleteModal();
    submitData(newFormData, "DELETE");
  };
  const closeConfirmDeleteModal = () => setShowConfirmDeleteModal(false);
  return (
    <>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header titles={["Machine Model", id ? initialFormData.model : ""]} />
      </Stack>
      <div>
        <ConfirmDeleteModal
          showConfirmDeleteModal={showConfirmDeleteModal}
          closeConfirmDeleteModal={closeConfirmDeleteModal}
          modalTextContent={` Please press "Confirm Delete" to delete the selected item.`}
          onConfirmDelete={onConfirmDeleteArtifact}
        />
      </div>
      <div>
        <UploadProgressModal selectedFiles={selectedFiles} showUploadDialog={showUploadDialog} uploadProgress={uploadProgress} />
      </div>
      {
        <ViewImageVideoModal
          showDialogView={showDialogView}
          handleCloseDialogView={handleCloseDialogView}
          data={initialFormData?.info && initialFormData?.info?.artifacts ? initialFormData?.info?.artifacts : []}
          selectedFileIndex={currentFilePathIndex}
        />
      }
      <div>
        <Dialog open={showDialog} onClose={handleCloseDialog}>
          <DialogContent>
            {selectedFiles.length ? (
              selectedFiles[currentIndex].type.includes("image") ? (
                <img
                  src={URL.createObjectURL(selectedFiles[currentIndex])}
                  alt="Selected File"
                  style={{ maxWidth: "100%", maxHeight: "500px", minHeight: "200px" }}
                />
              ) : selectedFiles[currentIndex].type.includes("pdf") ? (
                <embed src={URL.createObjectURL(selectedFiles[currentIndex])} width="800" height="700" />
              ) : selectedFiles[currentIndex].type.includes("video") ? (
                <video controls style={{ width: "100%" }} height="auto">
                  <source src={URL.createObjectURL(selectedFiles[currentIndex])} type={selectedFiles[currentIndex].type} />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <span>Selected File is Incorrect</span>
              )
            ) : null}
          </DialogContent>
          <Stack direction={"row"} alignItems={"center"} justifyContent="center" sx={{ mt: 2, mb: 2, mx: 3, height: "100%" }}>
            <Button onClick={goToPrevSlide} sx={{ mr: 7 }}>
              <LeftArrowIcon />
              Prev
            </Button>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            {selectedFiles.length ? (
              <Button variant="contained" onClick={() => submitData()} sx={{ ml: 2, mr: 7 }}>
                Upload
              </Button>
            ) : null}
            <Button onClick={goToNextSlide}>
              Next <RightArrowIcon />
            </Button>
          </Stack>
        </Dialog>
      </div>
      <Box sx={{ mx: 2 }}>
        {enableEditing ? (
          <Paper sx={{ borderRadius: 2, mb: 1.5, p: 2 }}>
            <CreateEditMachineModel initialFormData={initialFormData} setEnableEditing={setEnableEditing} />
          </Paper>
        ) : (
          <Paper sx={{ borderRadius: 2, mb: 1.5, p: 2 }}>
            <Stack justifyContent={"space-between"} direction={"row"} flexWrap={"wrap"}>
              <Stack rowGap={1}>
                <Typography>Model : {initialFormData.model}</Typography>
                <Typography>Manufacturer : {initialFormData.manufacturer}</Typography>
              </Stack>
              <Stack rowGap={1}>
                <Tooltip title="Edit model">
                  <Button onClick={() => setEnableEditing(true)} variant="contained">
                    Edit
                  </Button>
                </Tooltip>
              </Stack>
            </Stack>
          </Paper>
        )}
      </Box>
      {id && (
        <Box sx={{ mx: 2 }}>
          <Paper sx={{ p: 2 }}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography variant="h5" fontWeight={600} mb={1}>
                Artifacts
              </Typography>
              <input
                type="file"
                accept="application/pdf,video/*,image/*"
                style={{ display: "none" }}
                onChange={handleFileSelect}
                id="fileInput"
                multiple
              />
              <Tooltip title="Add file">
                <Button
                  sx={{ height: "self" }}
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    setCurrentIndex(0);
                    document.getElementById("fileInput").click();
                  }}
                >
                  Add
                </Button>
              </Tooltip>
            </Stack>

            {initialFormData?.info && initialFormData?.info?.artifacts ? (
              <Stack direction="row" spacing={2} sx={{ overflow: "auto", pb: 1 }}>
                {initialFormData.info.artifacts.map((artifact, index) => (
                  <Paper sx={{ borderRadius: 2, p: 2, position: "relative", width: 200, height: 200, boxSizing: "border-box" }}>
                    <IconButton
                      color="error"
                      sx={{
                        position: "absolute",
                        top: -9,
                        right: -9,
                        minWidth: 0,
                        padding: 0,
                        height: 40,
                        width: 40,
                      }}
                      onClick={() => onClickDeleteArtifact(artifact)}
                      aria-label="Delete"
                    >
                      <DeleteIcon fontSize="medium" />
                    </IconButton>
                    <Stack rowGap={1} onClick={() => handleViewFile(index)}>
                      {artifact.type === appConstants.allTypes.TYPE_OF_CONTENT.IMAGE ? (
                        <AuthorizedImage path={artifact.url} width={160} height={165} />
                      ) : artifact.type === appConstants.allTypes.TYPE_OF_CONTENT.PDF ? (
                        <AuthorizedPDF path={artifact.url} scale={0.135} width={160} />
                      ) : (
                        <div style={{ width: 130, height: 130 }}>
                          <img src={videoImage} width={130} height={130} />
                        </div>
                      )}
                    </Stack>
                  </Paper>
                ))}
              </Stack>
            ) : null}
          </Paper>
        </Box>
      )}
    </>
  );
};

export default MachineModel;
