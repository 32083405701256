import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Tooltip,
  Button,
  useTheme,
  Stack,
  List,
  Typography,
  CircularProgress,
  TextField,
  Grid,
  Paper,
  Autocomplete,
  Box,
} from "@mui/material";
import { readAsyncStorageValues, readServiceTicketMessages, updateNotesForTicket } from "features/service/service.slice";
import moment from "moment";
import LanguageDropdown from "component/LanguageDropdown";
import { translateText } from "utils/utilFunctions";

const TicketNotes = ({ ticketId }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [showDialog, setShowDialog] = useState(false);
  const [newNote, setNewNote] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const { serviceTicketById, serviceTicketMessages, appConstants, isLoading } = useSelector((state) => state.service);
  const [selectedLanguage, setSelectedLanguage] = useState({ value: "en", lang: "English" });
  const [serviceTicketMessageList, setServiceTicketMessageList] = useState([]);

  useEffect(() => {
    dispatch(readAsyncStorageValues());
    dispatch(readServiceTicketMessages(ticketId));
  }, [dispatch]);

  useEffect(() => {
    if (serviceTicketMessages) {
      setServiceTicketMessageList(serviceTicketMessages.filter((note) => note.type === appConstants.allTypes.TYPE_OF_CONTENT.TEXT));
    }
  }, [serviceTicketMessages]);

  const customeStyle = {
    p: 2,
    border: "1px solid #ddd",
    borderRadius: "10px",
    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 4px 4px rgba(0,0,0,0.03)",
    mb: 1,
  };

  const handleNoteChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.length <= 50000) {
      setNewNote(inputValue);
      setErrorMsg("");
    } else {
      setErrorMsg(`Character limit reached (50000 characters max)`);
    }
  };

  const handleCancel = () => {
    setShowDialog(false);
    setNewNote("");
  };
  const checkAndTranslateText = async () => {
    const translatedDescription = await translateText(newNote || "");
    return translatedDescription[0]?.translatedText || "N.A";
  };
  const handleAddNote = async () => {
    if (newNote.length && newNote.trim()) {
      const message = await checkAndTranslateText();
      const postData = {
        serviceTicketId: ticketId,
        message: message,
      };
      dispatch(updateNotesForTicket(postData)).then((res) => dispatch(readServiceTicketMessages(ticketId)));
      setNewNote("");
      setShowDialog(false);
    } else setErrorMsg("*Please enter notes.");
  };
  // const onlangChange = async (newValue) => {
  //   if (newValue) {
  //     setSelectedLanguage(newValue);
  //     const list = await Promise.all(
  //       serviceTicketMessages.map(async (item) => {
  //         const translatedText = await translateText(item.message, newValue?.value || "en");
  //         return { ...item, message: translatedText[0].translatedText };
  //       })
  //     );
  //     setServiceTicketMessageList(list.filter((note) => note.type === appConstants.allTypes.TYPE_OF_CONTENT.TEXT));
  //   }
  // };

  if (isLoading)
    return (
      <div
        style={{
          textAlign: "center",
          paddingTop: 50,
          paddingBottom: 50,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  return (
    <div>
      <Stack mb={1} direction="row" alignItems="center" justifyContent="space-between">
        <Typography variant="h5" fontWeight={600} mb={1}>
          Notes
        </Typography>
        {/* <Box>
          <LanguageDropdown onlangChange={(newValue) => onlangChange(newValue)} selectedLanguage={selectedLanguage} />
        </Box> */}
        {serviceTicketById?.status != appConstants?.allTypes?.SERVICE_TICKET_STATUS.SUPERVISOR_APPROVED ? (
          <Tooltip title="Add note">
            <Button sx={{ height: "self" }} variant="contained" color="primary" onClick={() => setShowDialog(true)}>
              Add
            </Button>
          </Tooltip>
        ) : null}
      </Stack>
      <div>
        <Dialog open={showDialog} onClose={handleCancel}>
          <DialogTitle>Add note</DialogTitle>
          <DialogContent>
            <TextField
              value={newNote}
              label="Note"
              multiline
              onChange={handleNoteChange}
              error={errorMsg && !newNote.length}
              helperText={errorMsg || `${newNote.length}/50000`}
              sx={{ minWidth: 350, m: 2, maxWidth: 900, maxHeight: 700 }}
            />
          </DialogContent>

          <DialogActions>
            <Button onClick={handleCancel}>Cancel</Button>
            <Button sx={{ height: "self" }} variant="contained" onClick={handleAddNote}>
              Add
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Stack mb={1}>
        <List
          sx={{
            overflow: "auto",
            maxHeight: 500,
          }}
        >
          {Object.keys(appConstants).length && serviceTicketMessageList.length
            ? [...serviceTicketMessageList]
                .sort((a, b) => new Date(b.postedDate || b.dateCreated).getTime() - new Date(a.postedDate || a.dateCreated).getTime())
                .map((note) => (
                  <>
                    {note.type === appConstants.allTypes.TYPE_OF_CONTENT.TEXT ? (
                      <Paper>
                        <Stack minWidth={200} justifyContent={"space-between"} sx={[customeStyle]}>
                          <Stack flexDirection={"row"} justifyContent={"space-between"}>
                            <Typography sx={{ display: "inline", textTransform: "capitalize" }} variant="body2" fontWeight={400}>
                              {note?.postedDate || note?.dateCreated
                                ? moment(note?.postedDate ? note.postedDate : note.dateCreated).format("DD MMM HH:mm")
                                : "N.A"}{" "}
                            </Typography>
                            <Typography sx={{ display: "inline" }} variant="body2">
                              {note.postedUser.name || "-"}
                            </Typography>
                          </Stack>

                          <Typography sx={{ display: "inline" }}>{note.message}</Typography>
                        </Stack>
                      </Paper>
                    ) : null}
                  </>
                ))
            : "No notes."}
        </List>
      </Stack>
    </div>
  );
};

export default TicketNotes;
