import React, { useState, useEffect } from "react";
import { Document, Page } from "react-pdf";
import { Buffer } from "buffer";
import { CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
// If below line is removed failed to show pdf
// eslint-disable-next-line
import PDFViewer from "pdf-viewer-reactjs";

import api from "app/api";

const AuthorizedPDF = ({ path, scale, showPages, width = "600px" }) => {
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [loading, setLoading] = useState(false);
  const [url, setUrl] = useState(null);
  useEffect(() => {
    const fetch = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/serviceapi/writeImageToClient?location=${path}`, {
          responseType: "arraybuffer",
        });

        const base64Data = Buffer.from(response.data, "binary").toString("base64");
        const pdfDataUrl = `data:application/pdf;base64,${base64Data}`;

        setUrl(pdfDataUrl);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching pdf:", error);
      }
    };
    fetch();
    setPageNumber(1); // Reset the page number when the path changes
  }, [path]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const nextPage = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const previousPage = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  return loading ? (
    <Stack alignItems={"center"} sx={{ my: 5 }}>
      <CircularProgress size={30} color="secondary" />
    </Stack>
  ) : (
    <Stack alignItems={"center"}>
      {showPages && numPages && (
        <Stack flexDirection={"row"} alignContent={"center"} justifyContent={"center"} alignItems={"center"}>
          <IconButton onClick={previousPage} disabled={pageNumber === 1}>
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography>
            Page {pageNumber} of {numPages}
          </Typography>
          <IconButton onClick={nextPage} disabled={pageNumber === numPages}>
            <KeyboardArrowRightIcon />
          </IconButton>
        </Stack>
      )}
      <div style={{ width: width }}>
        <Document
          file={{
            url: url,
          }}
          onLoadSuccess={onDocumentLoadSuccess}
          height="100%"
          loading="Loading PDF..."
        >
          {numPages && (
            <Page
              renderTextLayer={false}
              renderAnnotationLayer={false}
              customTextRenderer={false}
              key={`page_${pageNumber}`}
              className="pdf-page"
              pageNumber={pageNumber}
              scale={scale || "auto"}
            />
          )}
        </Document>
      </div>
      {showPages && numPages && (
        <Stack flexDirection={"row"} alignContent={"center"} justifyContent={"center"} alignItems={"center"}>
          <IconButton onClick={previousPage} disabled={pageNumber === 1}>
            <KeyboardArrowLeftIcon />
          </IconButton>
          <Typography>
            Page {pageNumber} of {numPages}
          </Typography>
          <IconButton onClick={nextPage} disabled={pageNumber === numPages}>
            <KeyboardArrowRightIcon />
          </IconButton>
        </Stack>
      )}
    </Stack>
  );
};

export default AuthorizedPDF;
