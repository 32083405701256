import { Outlet } from "react-router-dom";
import React from "react";
import { Box } from "@mui/material";
import { useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";

import Navbar from "component/navBar";
import Sidebar from "component/sidebar";
import Footer from "component/Footer";

const Layout = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const drawerWidth = 240;
  return (
    <>
      <Box sx={{ display: "flex", pt: 8, minHeight: "100%" }}>
        {isAuthenticated ? <Navbar drawerWidth={drawerWidth} /> : null}
        {isAuthenticated ? <Sidebar activeMenu={true} drawerWidth={drawerWidth} /> : null}
        <Box component="main" sx={{ flexGrow: 1, width: { sm: `calc(100% - ${drawerWidth}px)` }, minHeight: "100vh" }}>
          <Outlet />
        </Box>
      </Box>
      <Footer />
      <ToastContainer />
    </>
  );
};

export default Layout;
