import React from "react";
import { Box, Card, Stack, Modal, Typography, useTheme, List, Autocomplete, Paper, Grid, Button } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const Header = ({ titles = [] }) => {
  const theme = useTheme();
  return (
    <Card sx={{ px: 1, py: 2, mr: 2 }}>
      <Stack sx={{ flexDirection: "row" }}>
        {titles.map((title, index) => (
          <Stack direction={"row"} key={index}>
            <Typography sx={{}} variant="h5" fontWeight={"700"}>
              {title}
            </Typography>
            {index !== titles.length - 1 ? <ArrowForwardIosIcon sx={{ mt: 0.3 }} /> : null}
          </Stack>
        ))}
      </Stack>
    </Card>
  );
};

export default Header;
