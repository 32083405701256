import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { addUser, updateUser, getAllUsers } from "features/appUsers/appUsers.slice";
import { VisibilityOff, Visibility } from "@mui/icons-material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Links } from "utils/pageLinkNames";
import { isEmail, isPhoneNumber } from "utils/utilFunctions";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";

import {
  Typography,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Tooltip,
  Autocomplete,
  Checkbox,
  InputAdornment,
  IconButton,
  useTheme,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { styled } from "@mui/system";

const CustomPhoneInput = styled(PhoneInput)(({ theme }) => ({
  "& .PhoneInputInput": {
    height: "51px",
    padding: "15px",
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "5px",
    width: "100%",
    fontSize: "15px",
    backgroundColor: theme.palette.mode === "dark" ? "#333333" : "#ffffff",
    color: theme.palette.mode === "dark" ? "white" : "black",
    "&:focus": {
      borderColor: theme.palette.mode === "dark" ? "black" : "#006a78",
      borderWidth: "2px !important",
      borderStyle: "solid !important",
      outline: "none",
    },
  },
  "& .PhoneInputCountryIcon": {
    height: "29px",
    width: "40px",
  },
}));

const AddEditUser = (props) => {
  const { id } = useParams();
  const theme = useTheme();
  const { mode } = useSelector((state) => state.globalState);

  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [errors, setErrors] = useState({});
  const [showAlertBox, setShowAlertBox] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    department: "",
    userRole: "",
    userName: "",
    password: "",
    confirmPassword: "",
  });

  const { appConstants } = useSelector((state) => state.service);
  const { allUsers, isLoading } = useSelector((state) => state.appUsers);
  const dispatch = useDispatch();

  const handleSubmit = () => {
    const errorValidations = {
      name: formData.name ? "" : "Please enter name!",
      userName: formData.userName ? "" : "Please enter user name!",
      userRole: formData.userRole ? "" : "Please enter user role!",
      department: formData.department ? "" : "Please enter department!",
      email: "",
      mobileNumber: formData.mobileNumber ? "" : "Please enter mobile number!",
    };

    if (!id && !formData.password) errorValidations.password = formData.password ? "" : "Please enter password!";
    if (!id && !formData.confirmPassword)
      errorValidations.confirmPassword = formData.confirmPassword ? "" : "Please enter confirmPassword!";
    if (!id && formData.password !== formData.confirmPassword) errorValidations.confirmPassword = "Password doesn't match!";
    if (id && formData.password)
      errorValidations.confirmPassword = formData.password !== formData.confirmPassword ? "Password doesn't match!" : "";
    if (Object.values(errorValidations).filter((item) => item).length) {
      setErrors(errorValidations);
      return toast.warn("Please enter required fields!");
    }
    if (formData.email && !isEmail(formData.email)) {
      errorValidations.email = "Please enter a valid email!";
      setErrors(errorValidations);
      return toast.warn("Please enter a valid email!");
    }
    if (formData.mobileNumber && !isPhoneNumber(formData.mobileNumber)) {
      errorValidations.mobileNumber = "Please enter a valid mobile number!";
      setErrors(errorValidations);
      return toast.warn("Please enter a valid mobile number!");
    }

    const userExists = allUsers.some((item) => item.userName.toLowerCase() === formData.userName.toLowerCase());
    if (
      userExists &&
      (!id || allUsers.some((item) => item.userId === id && item.userName.toLowerCase() !== formData.userName.toLowerCase()))
    ) {
      return toast.error("Username already exists!");
    } else {
      formData.department = formData.department.id || 50;
      delete formData.departmentId;
      delete formData.confirmPassword;
      if (formData.email === "" || formData.email == null) delete formData.email;
      if (id) {
        if (!formData.password) delete formData.password;
        formData.userId = parseInt(id);
        submitForm(updateUser, formData);
      } else submitForm(addUser, formData);
    }
  };

  const submitForm = (action, formData) => {
    if (formData.mobileNumber) {
      if (!formData.mobileNumber.startsWith("+")) {
        formData.mobileNumber = "+" + formData.mobileNumber;
      }
    }
    dispatch(action(formData)).then((res) => {
      if (res && res.error) return toast.error("Something went wrong!");
      if (res && res.payload.data && res.payload.status === 200) {
        navigate(`/${Links.EDIT_USER}/` + res.payload.data.id, { replace: true });
        dispatch(getAllUsers()).then((res) => {
          toast.success("User " + (formData.userId ? "updated" : "added") + " successfully!", { autoClose: 1500 });
          if (!formData.userId) {
            toast.info("The newly created user needs to reset password by logging into the mobile app.");
          }
          props.setEnableEditing(false);
        });
      } else return toast.error("Something went wrong!");
    });
  };

  useEffect(() => {
    if (props && props.initialFormData) {
      const initialFormData = { ...props.initialFormData };
      if (!id) initialFormData.enabled = true;
      setFormData({
        ...initialFormData,
      });
    }
  }, [appConstants, allUsers]);

  if (isLoading) {
    return (
      <div
        style={{
          textAlign: "center",
          paddingTop: 200,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }
  const renderUserTicketsAlert = () => {
    const userTickets = props?.serviceTickets
      ? props.serviceTickets.filter((tkt) => tkt.status === appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGNED)?.map((item) => item.id)
      : [];

    return userTickets.length ? (
      <div>
        <Typography mt={1}>
          All the tickets <span style={{ fontWeight: "bold" }}> "Assigned" </span> to {formData?.name || ""} will be changed to
          <span style={{ fontWeight: "bold" }}> "Assign Technician" </span> status.
        </Typography>
        <Typography mt={1} fontWeight={"bold"}>
          Ticket(s) :
        </Typography>
        <div style={{ marginLeft: 10 }}>
          <Typography mt={1}>{userTickets?.join(", ")}</Typography>
        </div>
      </div>
    ) : (
      <Typography mt={1}>
        Currently no tickets were <span style={{ fontWeight: "bold" }}> "Assigned" </span> to {formData?.name || ""}.
      </Typography>
    );
  };
  return (
    <div>
      <div>
        <Dialog open={showAlertBox} onClose={() => setShowAlertBox(false)}>
          <DialogTitle fontWeight={"bold"}>Alert!</DialogTitle>
          <DialogContent>
            <Typography>You are going to disable the user.</Typography>

            {renderUserTicketsAlert()}
          </DialogContent>
          <DialogActions sx={{ mb: 2 }}>
            <Button sx={{ height: "self" }} variant="contained" onClick={() => setShowAlertBox(false)}>
              Cancel
            </Button>
            <Button sx={{ height: "self" }} variant="contained" onClick={() => handleSubmit()}>
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Stack direction={"row"} gap={2}>
        <Stack direction={"row"} gap={2} flexWrap={"wrap"} display={"flex"}>
          <TextField
            label="Name"
            value={formData.name}
            autoComplete="new-name"
            onChange={(event) => setFormData({ ...formData, name: event.target.value })}
            error={errors && errors.name}
            helperText={errors.name}
            sx={{ minWidth: 400, maxWidth: 600 }}
          />
          <TextField
            label="User name"
            autoComplete="new-username"
            value={formData.userName}
            onChange={(event) => setFormData({ ...formData, userName: event.target.value })}
            error={errors && errors.userName}
            helperText={errors.userName}
            sx={{ minWidth: 400, maxWidth: 600 }}
          />
          <TextField
            label="Email"
            value={formData.email}
            autoComplete="new-email"
            onChange={(event) => setFormData({ ...formData, email: event.target.value })}
            error={errors && errors.email && !isEmail(formData.email)}
            helperText={errors.email}
            sx={{ minWidth: 400, maxWidth: 600 }}
          />
          <div>
            <CustomPhoneInput
              international={false}
              defaultCountry="US"
              value={formData.mobileNumber}
              onChange={(phone) => setFormData({ ...formData, mobileNumber: phone })}
            />
            {errors.mobileNumber && (
              <Typography color="error" sx={{ fontSize: "0.65rem", mt: 1, ml: 1.5 }}>
                {errors.mobileNumber}
              </Typography>
            )}
          </div>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            autoComplete="new-role"
            onChange={(event, newValue) =>
              setFormData({
                ...formData,
                userRole: newValue,
              })
            }
            value={formData.userRole || null}
            options={
              appConstants && Object.keys(appConstants).length
                ? Object.values(appConstants.allTypes.CALMAN_USER_ROLES).sort((a, b) => a.localeCompare(b))
                : []
            }
            sx={{ width: 400, maxWidth: 600 }}
            renderInput={(params) => <TextField {...params} label="Role" error={errors && errors.userRole} helperText={errors.userRole} />}
          />
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            onChange={(event, newValue) =>
              setFormData({
                ...formData,
                department: newValue,
              })
            }
            value={formData.department || null}
            autoComplete="new-department"
            options={
              appConstants && Object.keys(appConstants).length
                ? appConstants.departments
                    .map((item) => ({ ...item, value: item.name, label: item.name }))
                    .sort((a, b) => a.label.localeCompare(b.label))
                : []
            }
            sx={{ minWidth: 400, maxWidth: 600 }}
            renderInput={(params) => (
              <TextField {...params} label="Department" error={errors && errors.department} helperText={errors.department} />
            )}
          />
          <TextField
            label="Password"
            type={showPassword.password ? "text" : "password"}
            value={formData.password || null}
            onChange={(event) => setFormData({ ...formData, password: event.target.value })}
            error={errors && !id && errors.password}
            helperText={errors.password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword({ ...showPassword, password: !showPassword.password })}
                    edge="end"
                  >
                    {showPassword.password ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            sx={{ minWidth: 400, maxWidth: 600 }}
            autoComplete="new-password"
          />
          <TextField
            label="Confirm password"
            type={showPassword.confirmPassword ? "text" : "password"}
            value={formData.confirmPassword}
            onChange={(event) => setFormData({ ...formData, confirmPassword: event.target.value })}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={() => setShowPassword({ ...showPassword, confirmPassword: !showPassword.confirmPassword })}
                    edge="end"
                  >
                    {showPassword.password ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            error={(errors && !id && errors.confirmPassword) || errors.password !== errors.confirmPassword}
            helperText={errors.confirmPassword}
            sx={{ minWidth: 400, maxWidth: 600 }}
            autoComplete="new-password"
          />
          <Stack direction={"row"} alignItems={"center"}>
            <Checkbox
              checked={formData.enabled ? true : false}
              onChange={(event) => {
                setFormData({
                  ...formData,
                  enabled: event.target.checked,
                });
              }}
              inputProps={{ "aria-label": "controlled" }}
            />
            <Typography>
              {formData.enabled ? "Enabled(Uncheck the box to disable user)" : "Disabled(Check the box to enable user)"}
            </Typography>
          </Stack>
        </Stack>
        <Stack>
          <Tooltip title="Save">
            <Button
              variant="contained"
              onClick={() => {
                const currentUserStatus = allUsers.find((user) => user.userId === parseInt(id));
                return formData.enabled || !id
                  ? handleSubmit()
                  : currentUserStatus?.enabled && !formData.enabled
                  ? setShowAlertBox(true)
                  : handleSubmit();
              }}
            >
              Save
            </Button>
          </Tooltip>
        </Stack>
      </Stack>
      <ToastContainer />
    </div>
  );
};

export default AddEditUser;
