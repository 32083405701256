import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { contextMenuItems, serviceTicketForMachineTableAttributes } from "data/dataTovisualize";
import { readServiceTicketsByMachineId } from "features/service/service.slice";
import moment from "moment";
import { Stack } from "@mui/material";
import { OutlinedInput, InputAdornment, IconButton, Box } from "@mui/material";
import { Search } from "@mui/icons-material";
import DataGridTable from "component/DataGridTable";
import Header from "component/Header";

const ServiceTicketForMachine = (props) => {
  const dispatch = useDispatch();
  let { id } = useParams();

  const { serviceTicketMachine } = useSelector((state) => state.service);
  const [searchValue, setSearchValue] = useState("");
  const [serviceTickets, setServiceTickets] = useState([]);

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  useEffect(() => {
    if (serviceTicketMachine) {
      const modified = serviceTicketMachine.map((ticket) => {
        return {
          ...ticket,
          dateCreated: moment(ticket.dateCreated).format("DD MMM YYYY"),
        };
      });
      setServiceTickets(modified?.sort((a, b) => b.ticketId - a.ticketId) || []);
    }
  }, [serviceTicketMachine]);

  useEffect(() => {
    dispatch(readServiceTicketsByMachineId(id));
  }, [dispatch]);

  return (
    <div>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header
          titles={[
            "Assets",
            serviceTicketMachine.length
              ? "Machine - " + serviceTicketMachine[0]?.modelName + " " + `[${serviceTicketMachine[0]?.serialNumber}]`
              : "",
          ]}
        />
      </Stack>
      <Box sx={{ mx: 2 }}>
        <Stack direction={"row"} gap={1} alignItems={"center"} my={2}>
          <OutlinedInput
            id="outlined-adornment-password"
            value={searchValue}
            onChange={(event) => handleSearchChange(event)}
            placeholder="Search..."
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" edge="end">
                  <Search />
                </IconButton>
              </InputAdornment>
            }
            sx={{ minWidth: 300, maxWidth: 600, height: 35 }}
          />
        </Stack>
        <DataGridTable
          rowId={"ticketId"}
          data={serviceTickets.filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchValue.toLowerCase()))}
          columnAttributes={serviceTicketForMachineTableAttributes}
        />
      </Box>
    </div>
  );
};

export default ServiceTicketForMachine;
