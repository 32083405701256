import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Typography,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Switch,
  Tooltip,
  Autocomplete,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CardContent,
  useTheme,
  Card,
  IconButton,
} from "@mui/material";
import {
  AssignmentTurnedIn as AssignmentTurnedInIcon,
  Category as CategoryIcon,
  AttachFile as AttachFileIcon,
  Delete as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material/";

import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { Links } from "utils/pageLinkNames";
import {
  createServiceTicket,
  updateServiceTicket,
  getServiceTicketById,
  readServiceTicketMessages,
  addImageOrVideoToServiceTicket,
} from "features/service/service.slice";
import { translateText } from "utils/utilFunctions";
import Cookies from "universal-cookie";
import DrawingComponent from "component/DrawingComponent";

const CreateUpdateTicket = (props) => {
  const dispatch = useDispatch();
  const theme = useTheme();

  const { allTemplates, machinesData, appConstants, serviceTicketsByStatusList, createTicket, isLoading } = useSelector(
    (state) => state.service
  );
  const { user } = useSelector((state) => state.auth);

  const [machinesList, setMachinesList] = useState([]);
  const [translatedText, setTranslatedText] = useState("");
  const [technicianList, setTechnicianList] = useState([]);
  const [machine, setMachine] = useState(null);
  const [defaultMachine, setDefaultMachine] = useState(null);
  const [disableMachineField, setDisableMachineField] = useState(false);
  const [isInternal, setIsInternal] = useState(false);
  const [safetyTicket, setSafetyTicket] = useState(false);
  const [popupOpen, setPopupOpen] = useState(false);
  const [machineTicketInfo, setMachineTicketInfo] = useState([]);
  const [inputValue, setInput] = useState("");
  const [storesUserList, setStoresUserList] = useState([]);
  const [defaultTechnician, setDefaultTechnician] = useState(null);
  const [formData, setFormData] = useState({
    machineId: "",
    scheduledDate: new Date(),
    problemCategory: [],
    status: "",
    assignees: [],
    description: "",
    attachments: [],
    templateId: null,
    // Add state for confirming deletion
    showDeleteConfirmation: false,
    deletingAttachmentIndex: -1,
  });
  const [error, setError] = useState("");
  const [selectedImageToEdit, setSelectedImageToEdit] = useState(null);
  const [isDrawingComponentOpen, setDrawingComponentOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (appConstants && Object.keys(appConstants).length) {
      const modifiedMachineData = machinesData
        .map((item) => ({
          ...item,
          name: `${item.maintenanceCode || ""} - ${item.manufacturer}, ${item.model} [${item.serialNumber}]`,
        }))
        .sort((a, b) => a.name.localeCompare(b.name));
      if (props && props.initialFormData) {
        const cookies = new Cookies();
        const currentCookie = cookies.get("googtrans");
        const setInitialData = async () => {
          const initialState = props.initialFormData;
          if (initialState.machine) {
            const machineInfo = modifiedMachineData.find((machine) => machine.id === initialState?.machine?.id);
            setMachine(machineInfo);
          }
          if (initialState.ticketCategory === appConstants.allTypes.SERVICE_CATEGORY.TASK) {
            const taskMachine = modifiedMachineData.find((machine) => machine.maintenanceCode === "M-TASK");
            setDefaultMachine(taskMachine);
            setDisableMachineField(true);
          } else if (initialState.ticketCategory === appConstants.allTypes.SERVICE_CATEGORY.INTERNAL_AUDIT) {
            const internalAuditMachine = modifiedMachineData.find((machine) => machine.maintenanceCode === "M-AUDIT");
            setDefaultMachine(internalAuditMachine);
            setDisableMachineField(true);
          }
          if (initialState.safetyTicket) {
            setSafetyTicket(true);
          }
          const responseText =
            currentCookie?.split("/")[2] != "en"
              ? await translateText(initialState?.description || "", currentCookie?.split("/")[2])
              : null;
          const additionalNotesObject = initialState?.additionalNotes || null;
          setFormData({
            ...initialState,
            description: responseText ? responseText[0]?.translatedText : initialState.description,
            problemCategory:
              initialState.problemCategory && initialState.problemCategory.length
                ? initialState.problemCategory.slice(1, -1).split(", ")
                : [],
            assignees:
              initialState.assignees && initialState.assignees.length
                ? appConstants.appUsers.filter((user) => initialState.assignees.some((assignee) => assignee.id === user.userId))
                : [],
            templateId: additionalNotesObject?.templateId || null,
          });
          setIsInternal(initialState.vendor ? true : false);
        };

        setInitialData();
      }
      setMachinesList(modifiedMachineData);
      setTechnicianList(
        appConstants.appUsers.filter(
          (user) =>
            user.userRole === appConstants.allTypes.USER_ROLES.ROLE_SERVICE_TECHNICIAN ||
            user.userRole === appConstants.allTypes.USER_ROLES.ROLE_MAINTENANCE_SUPERVISOR
        )
      );
      setStoresUserList(appConstants.appUsers.filter((user) => user.userRole === appConstants.allTypes.USER_ROLES.ROLE_STORES));
    }
  }, [appConstants, props.initialFormData, machinesData]);

  const closePopup = () => setPopupOpen(false);

  const calculateDuedate = (newValue) => {
    const tktCategory = formData.ticketCategory;
    const dueDateValue = appConstants.dueDatesByTicketType.find((dueDateObj) => dueDateObj[tktCategory]);
    let defaultDueDate;
    if (dueDateValue) {
      const daysToAdd = dueDateValue[tktCategory];
      defaultDueDate = moment(newValue).add(daysToAdd, "days").toDate();
    }
    setFormData({ ...formData, dueDate: defaultDueDate, scheduledDate: newValue });
  };
  const onChangeTicketCategory = (ticketCategory) => {
    let updatedTicketObj = { ...formData };

    if (
      ticketCategory === appConstants.allTypes.SERVICE_CATEGORY.PREVENTIVE_MAINTENANCE ||
      ticketCategory === appConstants.allTypes.SERVICE_CATEGORY.CALIBRATION
    ) {
      updatedTicketObj.ticketCategory = ticketCategory;
    } else {
      updatedTicketObj.ticketCategory = ticketCategory;
      updatedTicketObj.vendor = null;
      setIsInternal(false);
    }
    updatedTicketObj.templateId = null;
    if (ticketCategory === appConstants.allTypes.SERVICE_CATEGORY.INSPECTION) updatedTicketObj.templateId = null;
    if (ticketCategory === appConstants.allTypes.SERVICE_CATEGORY.TASK) {
      const taskMachine = machinesList.find((machine) => machine.maintenanceCode === "M-TASK");
      setDefaultMachine(taskMachine);
      setDisableMachineField(true);
    } else if (ticketCategory === appConstants.allTypes.SERVICE_CATEGORY.INTERNAL_AUDIT) {
      const internalAuditMachine = machinesList.find((machine) => machine.maintenanceCode === "M-AUDIT");
      setDefaultMachine(internalAuditMachine);
      setDisableMachineField(true);
      const internalAuditUser = appConstants.appUsers.filter((user) => user.userRole === appConstants.allTypes.USER_ROLES.ROLE_STORES);
      if (internalAuditUser.length == 1) updatedTicketObj.assignees = internalAuditUser;
    } else {
      setDefaultMachine(null);
      setDisableMachineField(false);
    }
    if (ticketCategory !== appConstants.allTypes.SERVICE_CATEGORY.INTERNAL_AUDIT) {
      const techUsers = updatedTicketObj.assignees.filter((user) => user.userRole !== appConstants.allTypes.USER_ROLES.ROLE_STORES);
      updatedTicketObj.assignees = techUsers;
    }
    let defaultDueDate;
    const scheduled = updatedTicketObj.scheduledDate;
    const dueDateValue = appConstants.dueDatesByTicketType.find((dueDateObj) => dueDateObj[ticketCategory]);
    if (dueDateValue) {
      const daysToAdd = dueDateValue[ticketCategory];
      defaultDueDate = moment(scheduled).add(daysToAdd, "days").toDate();
    }
    updatedTicketObj = { ...updatedTicketObj, dueDate: defaultDueDate };

    setFormData(updatedTicketObj);
  };

  const validate = async () => {
    const selectedMachine =
      formData.ticketCategory === appConstants.allTypes.SERVICE_CATEGORY.TASK ||
      formData.ticketCategory === appConstants.allTypes.SERVICE_CATEGORY.INTERNAL_AUDIT
        ? defaultMachine
        : machine;
    const updateObj = {
      ticketCategory: formData.ticketCategory,
      scheduledDate: moment(formData.scheduledDate).format("YYYY-MM-DDTHH:mm:ssZ"),
      ...(formData.dueDate && { dueDate: moment(formData.dueDate).format("YYYY-MM-DDTHH:mm:ssZ") }),
      machineId: selectedMachine?.id,
      assignees: formData.assignees.length ? formData.assignees.map((a) => a.userId) : [],
      priority: formData.priority,
      problemCategory: formData.problemCategory || "Electrical",
      vendor: formData.vendor
        ? appConstants.customers.filter((user) => user.customerName === formData.vendor || user.id === formData.vendor?.id)[0].id
        : null,
      description: formData.description,
      safetyTicket: safetyTicket ? safetyTicket : null,
    };
    if (formData.templateId) updateObj.templateId = formData.templateId;
    if (formData.description) {
      const responseText = await translateText(formData.description);
      updateObj.description = responseText[0].translatedText;
    }
    if (formData.attachments) {
      for (const file of formData.attachments) {
        const regex = new RegExp("<!--" + file.name + "-->", "g");
        updateObj.description = updateObj.description.replaceAll(regex, "");
      }
    }
    if (formData.id) {
      updateObj.serviceTicketId = formData.id;
      updateObj.status = formData.status;
    } else {
      updateObj.status = formData.assignees.length
        ? appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGNED
        : appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGN_SERVICE_ENGINEER;
      updateObj.dateCreated = formData.dateCreated;
      updateObj.customerId = 5284;
      updateObj.serviceTicketPaymentType = appConstants.allTypes.SERVICE_TICKET_PAYMENT_TYPE.AMC;
      updateObj.attachments = formData.attachments;
      delete updateObj.showDeleteConfirmation;
      delete updateObj.deletingAttachmentIndex;
    }
    if (
      updateObj.priority &&
      updateObj.machineId &&
      updateObj.ticketCategory &&
      updateObj.status &&
      updateObj.description &&
      updateObj.scheduledDate
    )
      if (
        isInternal &&
        !updateObj.vendor &&
        [appConstants.allTypes.SERVICE_CATEGORY.CALIBRATION, appConstants.allTypes.SERVICE_CATEGORY.PREVENTIVE_MAINTENANCE].includes(
          updateObj.ticketCategory
        )
      )
        setError("* Required field.");
      else {
        if (formData.id) updateTicket(updateObj);
        else createNewTicket(updateObj);
      }
    else setError("* Required field.");
  };
  const progressCallback = (progressEvent) => {};
  const createNewTicket = (updateObj) => {
    const images = updateObj.attachments;
    delete updateObj.attachments;
    dispatch(createServiceTicket(updateObj)).then((res) => {
      if (res && res.error) toast.error("Something went wrong!");
      if (res && res.payload.data && res.payload.status === 200) {
        if (images.length > 0) {
          images.forEach((imageObj) => {
            const formData = new FormData();
            formData.append("image", imageObj);

            if (imageObj.type === "application/pdf") {
              formData.append("type", "pdf");
            } else {
              formData.append("type", "image/jpg");
            }

            formData.append("serviceTicketId", "" + res.payload.data.id);
            dispatch(addImageOrVideoToServiceTicket({ formData, progressCallback: progressCallback }));
            dispatch(readServiceTicketMessages(res.payload.data.id));
          });
        }
        toast.success("Service ticket created.", {
          autoClose: 1000,
        });
        navigate(`/${Links.SERVICE_TICKET}/` + res.payload.data.id, { replace: true });
        props.setEditTicketEnabled(false);
      } else toast.error("Something went wrong!");
    });
  };
  const updateTicket = (updateObj) => {
    dispatch(updateServiceTicket(updateObj)).then((res) => {
      if (res && res.error) toast.error("Something went wrong!");
      if (res && res.payload.data && res.payload.status === 200) {
        dispatch(getServiceTicketById(formData.id)).then((getRes) => {
          if (getRes && getRes.error) toast.error("Something went wrong!");
          if (getRes && getRes?.payload?.id) {
            toast.success("Service ticket updated successfully!", {
              autoClose: 1000,
            });
            dispatch(readServiceTicketMessages(formData.id));
            props.setEditTicketEnabled(false);
            navigate(`/${Links.SERVICE_TICKET}/` + formData.id);
          }
        });
      } else toast.error("Something went wrong!");
    });
  };
  const onChangeMachine = (event, machine) => {
    setFormData({ ...formData, machineId: machine.id });
    setMachine(machine);
    if (!formData.id) {
      let machinesWithSvcTicket = serviceTicketsByStatusList?.filter((ticket) => {
        return (
          ticket?.machine?.id == machine.id &&
          (ticket.status === appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGNED ||
            ticket.status === appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGN_SERVICE_ENGINEER) &&
          machine.maintenanceCode !== "M-TASK" &&
          machine.maintenanceCode !== "M-AUDIT"
        );
      });
      if (machinesWithSvcTicket?.length) {
        setMachineTicketInfo(machinesWithSvcTicket);
        setPopupOpen(true);
      }
    }
  };

  const handleAttachmentChange = (event) => {
    const files = event.target.files;
    // Convert FileList to an array and store it in the state
    const newAttachments = Array.from(files);
    if (newAttachments.length == 1) {
      setSelectedImageToEdit(newAttachments[0]);
      setDrawingComponentOpen(true);
    } else
      setFormData((prevState) => ({
        ...prevState,
        attachments: [...prevState.attachments, ...newAttachments],
      }));
  };

  const handleRemoveAttachment = (index) => {
    setFormData({
      ...formData,
      showDeleteConfirmation: true,
      deletingAttachmentIndex: index,
    });
  };

  const handleEditAttachment = (index) => {
    setSelectedImageToEdit(formData.attachments[index]);
    setDrawingComponentOpen(true);
    setFormData((prevState) => {
      const updatedAttachments = [...prevState.attachments];
      updatedAttachments.splice(index, 1);
      return {
        ...prevState,
        attachments: updatedAttachments,
      };
    });
  };

  const handleConfirmDelete = () => {
    const index = formData.deletingAttachmentIndex;
    setFormData((prevState) => {
      const updatedAttachments = [...prevState.attachments];
      updatedAttachments.splice(index, 1);
      return {
        ...prevState,
        attachments: updatedAttachments,
        showDeleteConfirmation: false,
        deletingAttachmentIndex: -1,
      };
    });
  };

  const handleCancelDelete = () => {
    setFormData({
      ...formData,
      showDeleteConfirmation: false,
      deletingAttachmentIndex: -1,
    });
  };
  const onPasteOrDrop = (e, clipboardData) => {
    e.stopPropagation();
    e.preventDefault();
    // Get pasted data via clipboard API
    // const clipboardData = e.clipboardData || window.clipboardData;
    const clipboardDataText = clipboardData.getData("Text");
    if (clipboardDataText) {
      setFormData((prevState) => ({
        ...prevState,
        description: prevState.description + clipboardDataText,
      }));
    }
    if (clipboardData.files[0]) {
      const newAttachments = Array.from(clipboardData.files);
      const fileNames = newAttachments.map((item) => `<!--${item.name}-->`).join(" \n");
      setFormData((prevState) => ({
        ...prevState,
        description: prevState.description + (prevState.description ? "\n" : "") + fileNames + "\n",
        attachments: [...prevState.attachments, ...newAttachments],
      }));
    }
  };
  const handleOndragOver = (event) => {
    event.preventDefault();
  };

  const onClickTranslate = async () => {
    if (translatedText) return setTranslatedText("");
    const responseText = await translateText(formData?.description || "");
    if (responseText) setTranslatedText(responseText[0].translatedText);
  };
  const onClickDoneEditing = async (editedImage) => {
    setDrawingComponentOpen(false);
    setSelectedImageToEdit(null);
    setFormData((prevState) => ({
      ...prevState,
      attachments: [...prevState.attachments, editedImage],
    }));
  };
  if (isLoading || createTicket.isLoading || !Object.keys(appConstants).length || !appConstants)
    return (
      <div
        style={{
          textAlign: "center",
          paddingTop: 200,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );

  return (
    <div>
      <Stack gap={2}>
        <Stack flexDirection="row" sx={{}}>
          <Stack flexDirection="row" sx={{}} flexWrap={"wrap"} gap={2}>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              onChange={(e, newValue) => onChangeTicketCategory(newValue)}
              value={formData.ticketCategory || null}
              options={Object.values(appConstants.allTypes.SERVICE_CATEGORY).sort((a, b) => a.localeCompare(b))}
              sx={{ minWidth: 300, maxWidth: 600 }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Ticket category"
                  error={error && !formData.ticketCategory}
                  helperText={formData.ticketCategory ? "" : error}
                />
              )}
            />
            <div class="notranslate">
              <Autocomplete
                disablePortal
                disabled={disableMachineField}
                id="controllable-states-demo"
                onChange={onChangeMachine}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) => setInput(newInputValue)}
                isOptionEqualToValue={(option, value) => option.name == value.name}
                getOptionLabel={(option) => option.name}
                value={
                  machine || defaultMachine
                    ? formData?.ticketCategory === appConstants.allTypes.SERVICE_CATEGORY.TASK ||
                      formData?.ticketCategory === appConstants.allTypes.SERVICE_CATEGORY.INTERNAL_AUDIT
                      ? defaultMachine
                      : machine
                    : null
                }
                options={machinesList.filter((item) => item.name)}
                sx={{ minWidth: 550, maxWidth: 800 }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Machine"
                    error={error && !machine?.id && !defaultMachine?.id}
                    helperText={machine?.id || defaultMachine?.id ? "" : error}
                  />
                )}
              />
            </div>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              onChange={(event, newValue, reason) => {
                if (reason === "clear") {
                  setFormData({ ...formData, priority: null });
                }
                setFormData({ ...formData, priority: newValue });
              }}
              value={formData.priority || null}
              options={Object.values(appConstants.allTypes.SERVICE_TICKET_PRIORITY)}
              sx={{ minWidth: 200, maxWidth: 600 }}
              renderInput={(params) => (
                <TextField {...params} label="Priority" error={error && !formData.priority} helperText={formData.priority ? "" : error} />
              )}
            />
            <Autocomplete
              disablePortal
              multiple
              filterSelectedOptions
              id="combo-box-demo"
              onChange={(event, newValue) =>
                setFormData({
                  ...formData,
                  problemCategory: newValue,
                })
              }
              value={formData.problemCategory || []}
              options={appConstants?.allTypes ? Object.values(appConstants.serviceProblemCategory).sort((a, b) => a.localeCompare(b)) : []}
              sx={{
                minWidth: 300,
                maxWidth: 600,
                "& .MuiChip-label": {
                  color: "#000",
                },
                "& .MuiChip-deleteIcon": {
                  color: "#000",
                },
              }}
              renderInput={(params) => <TextField {...params} label="Problem category" />}
            />
            <div class="notranslate">
              {user?.roles[0] == appConstants?.allTypes?.USER_ROLES?.ROLE_MAINTENANCE_SUPERVISOR && (
                <Autocomplete
                  disablePortal
                  multiple
                  filterSelectedOptions
                  id="combo-box-demo"
                  onChange={(event, newValue) => {
                    setFormData({
                      ...formData,
                      assignees: newValue,
                      status:
                        newValue.length &&
                        (formData.status === appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGNED ||
                          formData.status === appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGN_SERVICE_ENGINEER)
                          ? appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGNED
                          : formData.status === appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGNED
                          ? appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGN_SERVICE_ENGINEER
                          : formData.status,
                    });
                  }}
                  isOptionEqualToValue={(option, value) => option.nameEmpno == value.nameEmpno}
                  getOptionLabel={(option) => option.nameEmpno}
                  value={formData.assignees || []}
                  options={
                    appConstants.allTypes.SERVICE_CATEGORY.INTERNAL_AUDIT === formData.ticketCategory ? storesUserList : technicianList
                  }
                  sx={{
                    minWidth: 300,
                    maxWidth: 600,
                    "& .MuiChip-label": {
                      color: "#000",
                    },
                    "& .MuiChip-deleteIcon": {
                      color: "#000",
                    },
                  }}
                  renderInput={(params) => <TextField {...params} label="Technician" />}
                />
              )}
            </div>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                minDate={moment(new Date())}
                label="Scheduled date"
                format={"DD MMM YYYY"}
                value={moment(formData.scheduledDate || new Date())}
                onChange={calculateDuedate}
                variant="standard"
                sx={{
                  minWidth: 200,
                  maxWidth: 600,
                  "& .Mui-error": {
                    color: "black",
                  },
                  "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
                    borderColor: "#C8C8C8",
                    borderBlockColor: "#C8C8C8",
                    color: "black",
                  },
                  "& .MuiInputLabel-root": {
                    color: "#888888",
                  },
                }}
                // onChange={(newValue) => setUpdateServiceTicketByIdObj({ ...updateServiceTicketByIdObj, scheduledDate: newValue })}
              />
            </LocalizationProvider>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                minDate={moment(formData.scheduledDate)}
                label="Due date"
                format={"DD MMM YYYY"}
                value={formData.dueDate ? moment(formData.dueDate) : null}
                onChange={(newValue) => setFormData({ ...formData, dueDate: newValue })}
                sx={{ minWidth: 200, maxWidth: 600 }}
              />
            </LocalizationProvider>
            {formData.id ? (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                onChange={(event, newValue) =>
                  setFormData({
                    ...formData,
                    status: newValue,
                    assignees: newValue === appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGN_SERVICE_ENGINEER ? [] : formData.assignees,
                  })
                }
                value={formData.status || null}
                options={Object.values(appConstants.allTypes.SERVICE_TICKET_STATUS)
                  .filter(
                    (item) =>
                      item !== appConstants.allTypes.SERVICE_TICKET_STATUS.TIME_LOGGED &&
                      item !== appConstants.allTypes.SERVICE_TICKET_STATUS.COMPLETE
                  )
                  .sort()}
                sx={{ minWidth: 300, maxWidth: 600 }}
                renderInput={(params) => (
                  <TextField {...params} label="Status" error={error && !formData.status} helperText={formData.status ? "" : error} />
                )}
              />
            ) : null}
            {[
              appConstants.allTypes.SERVICE_CATEGORY.CALIBRATION,
              appConstants.allTypes.SERVICE_CATEGORY.INSPECTION,
              appConstants.allTypes.SERVICE_CATEGORY.PREVENTIVE_MAINTENANCE,
            ].includes(formData.ticketCategory) && (
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                onChange={(event, newValue) =>
                  setFormData({
                    ...formData,
                    templateId: newValue.id,
                  })
                }
                isOptionEqualToValue={(option, value) => option.name == value.name}
                getOptionLabel={(option) => option.name}
                value={allTemplates?.data.find((item) => item.id === formData.templateId) || null}
                options={allTemplates?.data.filter((item) => item.active) || []}
                sx={{ minWidth: 350, maxWidth: 600 }}
                renderInput={(params) => <TextField {...params} label="Template" />}
              />
            )}
            <div>
              <Stack flexDirection="row" alignItems="center">
                <Box
                  sx={{ width: 35, resizeMode: "contain", marginLeft: 2 }}
                  component="img"
                  src={require("app/assets/images/safety-observation.png")}
                  alt="logo"
                />
                <Switch
                  onChange={(event) => {
                    setSafetyTicket(event.target.checked);
                    if (event.target.checked) {
                      setFormData({
                        ...formData,
                        priority: appConstants.allTypes.SERVICE_TICKET_PRIORITY.HIGH,
                        ticketCategory: appConstants.allTypes.SERVICE_CATEGORY.EQUIPMENT_SUPPORT,
                      });
                    }
                  }}
                  checked={safetyTicket}
                  color="secondary"
                />
                <Typography>{appConstants?.tabConstants?.safetyTicket || ""}</Typography>
                <Switch
                  color="secondary"
                  onChange={(event) => {
                    if (!event.target.checked) setFormData({ ...formData, vendor: null });
                    setIsInternal(event.target.checked);
                  }}
                  checked={isInternal}
                />
                <Typography>{isInternal ? "External" : "Internal"}</Typography>
              </Stack>
            </div>
            <div>
              {isInternal ? (
                <Autocomplete
                  disablePortal
                  id="combo-box-demo"
                  onChange={(event, newValue) => {
                    setFormData({ ...formData, vendor: newValue });
                  }}
                  value={
                    formData.vendor
                      ? appConstants.customers.find((user) => user.id === formData.vendor?.id || user.customerName === formData.vendor)
                          ?.customerName
                      : null
                  }
                  options={appConstants.customers
                    .filter((user) => user.customerType === appConstants.allTypes.TYPE_OF_CUSTOMER.VENDOR)
                    .map((item) => item.customerName)
                    .sort((custA, custB) => custA.localeCompare(custB))}
                  sx={{ minWidth: 300, maxWidth: 600 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Vendor" error={error && !formData.vendor} helperText={formData.vendor ? "" : error} />
                  )}
                />
              ) : null}
            </div>
          </Stack>
          <Stack>
            <Tooltip title="Save">
              <Button sx={{ height: "self" }} variant="contained" onClick={validate}>
                Save
              </Button>
            </Tooltip>
          </Stack>
        </Stack>
        <Stack flexWrap="wrap">
          <Stack>
            <TextField
              sx={{ minWidth: 540, mr: 4, mt: 2 }}
              maxRows={10}
              inputProps={{ maxLength: 50000, placeholder: "Type / drag and drop / paste content here..." }}
              label="Description"
              multiline
              value={translatedText || formData.description || ""}
              onChange={(event) => setFormData({ ...formData, description: event.target.value })}
              onPasteCapture={(e) => onPasteOrDrop(e, e.clipboardData || window.clipboardData)}
              onDrop={(e) => onPasteOrDrop(e, e.dataTransfer)}
              onDragOver={handleOndragOver}
              error={error && !formData.description}
              helperText={formData.description ? "" : error}
              maxLength={50000}
            />
            <Stack direction={"row"} justifyContent={"end"} ml={40}>
              <Button
                sx={{ mt: 1 }}
                size="small"
                variant="contained"
                onClick={() => onClickTranslate()}
                disabled={formData.description ? false : true}
              >
                {translatedText ? "See Original" : "Translate"}
              </Button>
              <Typography sx={{ textAlign: "start", mr: 4, mt: 1, ml: 2 }} variant="subtitle2">
                characters: {formData.description ? formData.description.length : 0}/50000
              </Typography>
            </Stack>
          </Stack>
          <div>
            {formData.id ? null : (
              <div style={{ marginTop: -25 }}>
                <input
                  type="file"
                  accept="image/*"
                  id="attachment-input"
                  style={{ display: "none" }}
                  onChange={handleAttachmentChange}
                  multiple
                />
                <label htmlFor="attachment-input">
                  <IconButton component="span">
                    <AttachFileIcon />
                  </IconButton>
                  Attach Image
                </label>
                <div style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", marginBottom: 5 }}>
                  {formData.attachments?.map((attachment, index) => (
                    <div key={index} style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
                      <img
                        src={URL.createObjectURL(attachment)}
                        alt={`Attachment ${index}`}
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                      <Stack>
                        <IconButton color="error" onClick={() => handleEditAttachment(index)} aria-label="Delete" size="small">
                          <EditIcon fontSize="small" />
                        </IconButton>
                        <IconButton color="error" onClick={() => handleRemoveAttachment(index)} aria-label="Delete" size="small">
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </Stack>
                    </div>
                  ))}
                </div>

                <Dialog open={formData.showDeleteConfirmation} onClose={handleCancelDelete}>
                  <DialogTitle>Confirm Delete</DialogTitle>
                  <DialogContent>
                    <Typography>Are you sure you want to delete this image?</Typography>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCancelDelete} variant="contained">
                      Cancel
                    </Button>
                    <Button onClick={handleConfirmDelete} variant="contained">
                      Delete
                    </Button>
                  </DialogActions>
                </Dialog>
              </div>
            )}
          </div>
        </Stack>
      </Stack>
      {isDrawingComponentOpen && (
        <DrawingComponent
          isDrawingComponentOpen={isDrawingComponentOpen}
          closeDrawingComponent={setDrawingComponentOpen}
          onClickDoneEditing={onClickDoneEditing}
          selectedImageUri={selectedImageToEdit}
        />
      )}
      <Dialog open={popupOpen} onClose={closePopup} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
        <DialogContent sx={{ fontSize: 16 }}>
          {"This machine has the following tickets. Do you still need to create a new service ticket?"}
        </DialogContent>
        <DialogContent sx={{ paddingY: 1, flexDirection: "column", alignItems: "center" }}>
          {machineTicketInfo?.map(
            (ticket) =>
              (ticket.status === appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGNED ||
                ticket.status === appConstants.allTypes.SERVICE_TICKET_STATUS.ASSIGN_SERVICE_ENGINEER) && (
                <Card key={ticket.id} sx={{ marginBottom: 1, padding: 0 }}>
                  <CardContent
                    sx={{
                      padding: 1,
                      "&:last-child": {
                        paddingBottom: 1,
                      },
                    }}
                  >
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                      <span style={{ flex: 2.5, color: "green", fontSize: "16px" }}>
                        {`${ticket.id}   `}
                        <AssignmentTurnedInIcon sx={{ fontSize: 15 }} />
                        <span style={{ color: theme.palette.mode === "dark" ? "#fff" : "#000" }}>{` ${ticket.status}    `}</span>
                        <CategoryIcon sx={{ fontSize: 15 }} />
                        <span style={{ color: theme.palette.mode === "dark" ? "#fff" : "#000" }}>{` ${ticket.ticketCategory}`}</span>
                      </span>
                      <div
                        style={{
                          width: "70px",
                          paddingHorizontal: 2,
                          paddingVertical: 3,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          borderRadius: 5,
                          backgroundColor:
                            ticket.priority === appConstants.allTypes.SERVICE_TICKET_PRIORITY.HIGH
                              ? "red"
                              : ticket.priority === appConstants.allTypes.SERVICE_TICKET_PRIORITY.MEDIUM
                              ? "orange"
                              : ticket.priority === appConstants.allTypes.SERVICE_TICKET_PRIORITY.LOW
                              ? "green"
                              : "grey",
                          marginLeft: "10px",
                        }}
                      >
                        <span style={{ color: "#fff", fontSize: "12px" }}>{ticket.priority || "Unprioritized"}</span>
                      </div>
                    </div>
                  </CardContent>
                </Card>
              )
          )}
        </DialogContent>
        <DialogActions style={{ justifyContent: "center" }}>
          <Button
            variant="contained"
            sx={{ height: 30, marginBottom: 4 }}
            onClick={() => {
              setFormData({ ...formData, machineId: "" });
              closePopup();
            }}
          >
            No
          </Button>
          <Button onClick={closePopup} variant="contained" sx={{ height: 30, marginBottom: 4 }}>
            Yes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default CreateUpdateTicket;
