import { Box } from "@mui/material";
import React from "react";

const Footer = () => (
  <Box sx={{ marginTop: 6, textAlign: "center" }}>
    <p>Copyright &copy; {new Date().getFullYear()} Teraobjects All rights reserved </p>
  </Box>
);

export default Footer;
