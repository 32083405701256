import api from "app/api";

// Login user
const login = async (userData) => {
  try {
    const response = await api.post("api/login", userData);
    if (
      !response.data?.roles?.includes("ROLE_MAINTENANCE_SUPERVISOR") &&
      !response.data?.roles?.includes("ROLE_STORES") &&
      !response.data?.roles?.includes("ROLE_FINANCE") &&
      !response.data?.roles?.includes("ROLE_IT") &&
      !response.data?.roles?.includes("ROLE_SERVICE_TECHNICIAN")
    ) {
      throw Error(`Access denied!`);
    }
    if (response) {
      localStorage.setItem("user", JSON.stringify(response.data));
      localStorage.setItem("userToken", JSON.stringify(response.data.access_token));
    }
    return response;
  } catch (error) {
    if (error.message) {
      console.log("error", error.message);
    }
    if (error.response.data.message) {
      console.log("error", error.response.data.message);
    }
  }
};

// Logout user
const logout = () => {
  localStorage.removeItem("user");
  localStorage.removeItem("userToken");
};

const authService = {
  logout,
  login,
};

export default authService;
