import React from "react";
import {
  DialogTitle,
  Stack,
  Paper,
  Typography,
  DialogActions,
  Button,
  Dialog,
  Tooltip,
  DialogContent,
  useTheme,
  Box,
  LinearProgress,
} from "@mui/material";

const UploadProgressModal = ({
  selectedFiles = [],
  showUploadDialog = false,
  uploadProgress = { progress: 0, rate: 0, total: 0, buffer: 10 },
}) => {
  return (
    <div>
      <Dialog
        open={showUploadDialog}
        PaperProps={{
          style: {
            maxWidth: "700px",
            minWidth: "500px",
          },
        }}
      >
        <DialogTitle>
          <Stack>
            <Typography>{`File(s) :  `}</Typography>
            <Typography>{selectedFiles.map((item) => item.name)?.join("\n") || ""}</Typography>
          </Stack>
          <div>
            <Stack direction={"row"} my={2}>
              <Typography mr={3}>{`Progress : ${uploadProgress?.progress || 0}/${uploadProgress?.total || 0} MB`}</Typography>
              <Typography mr={3}>{`Speed : ${uploadProgress?.rate || 0} MB/sec`}</Typography>
            </Stack>
            <LinearProgress
              sx={{ mb: 1 }}
              variant="buffer"
              value={(uploadProgress?.progress / uploadProgress?.total) * 100}
              valueBuffer={uploadProgress?.buffer || 10}
            />
          </div>
        </DialogTitle>
      </Dialog>
    </div>
  );
};

export default UploadProgressModal;
