import { configureStore } from "@reduxjs/toolkit";
import authReducer from "features/auth/auth.slice";
import globalReducer from "features/global/global.slice";
import serviceReducer from "features/service/service.slice";
import appUsersReducer from "features/appUsers/appUsers.slice";
import settingsReducer from "features/settings/settings.slice";


export const store = configureStore({
  reducer: {
    auth: authReducer,
    globalState: globalReducer,
    service: serviceReducer,
    appUsers: appUsersReducer,
    settings: settingsReducer,
  },
});

export default store;
