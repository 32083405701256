import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Box, Stack, Button, Tabs, Tab, Typography, useTheme } from "@mui/material";
// import ChartMachinesUnderServiceByModal from "component/ChartMachinesUnderServiceByModal";
import ActiveMachinesByStatus from "component/ActiveMachinesByStatus";
import MachinesOverview from "component/MachinesOverview";
import ChartOutOfServiceMachinesByTicketCategory from "component/ChartOutOfServiceMachinesByTicketCategory";

const PlantOverview = () => {
  const [activeTab, setActiveTab] = useState(0);
  const theme = useTheme();
  const mode = useSelector((state) => state.globalState.mode);
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)", background: mode === "dark" ? "#B0B0B0" : null }}>
        <Tabs value={activeTab} onChange={(event, newValue) => setActiveTab(newValue)} variant="fullWidth" aria-label="Tabs">
          <Tab label="Critical Machines by Status" component={Button} disableElevation sx={{ fontWeight: "bold" }} />
          <Tab label="Critical Machines by Ticket Category" component={Button} disableElevation sx={{ fontWeight: "bold" }} />
        </Tabs>
      </Box>
      <Stack mt={2} direction="row" spacing={1}>
        <Stack ml={5} gap={1} flex={1}>
          <Box flex={1} hidden={activeTab !== 0}>
            <ActiveMachinesByStatus />
          </Box>
          <Box flex={1} hidden={activeTab !== 1}>
            <ChartOutOfServiceMachinesByTicketCategory />
          </Box>
        </Stack>
        {activeTab !== 1 ? (
          <Stack gap={1} flexDirection={"row"} pr={2} flex={1}>
            <Box flex={1}>
              <MachinesOverview status={"Under Observation"} dataKey="underObservation" />
            </Box>
            <Box flex={1}>
              <MachinesOverview status={"Breakdown"} dataKey="breakDown" />
            </Box>
          </Stack>
        ) : null}
      </Stack>
    </Box>
  );
};

export default PlantOverview;
