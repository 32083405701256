import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, Stack, List, Typography, CircularProgress, Icon, useTheme, Paper, Badge } from "@mui/material";
import { getActiveMachinesByStatus } from "features/service/service.slice";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import ConstructionIcon from "@mui/icons-material/Construction";
import BuildIcon from "@mui/icons-material/Build";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";

const MachinesOverview = ({ status, dataKey }) => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [machineCount, setMachineCount] = useState(0);

  const { activeMachinesByStatus, isLoading } = useSelector((state) => state.service);
  const { mode } = useSelector((state) => state.globalState);

  useEffect(() => {
    dispatch(getActiveMachinesByStatus());
  }, []);

  useEffect(() => {
    if (activeMachinesByStatus && activeMachinesByStatus[dataKey]) {
      setMachineCount(activeMachinesByStatus[dataKey].length);
    }
  }, [activeMachinesByStatus, dataKey]);

  if (isLoading)
    return (
      <div
        style={{
          textAlign: "center",
          paddingTop: 50,
          paddingBottom: 50,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  return (
    <Paper elevation={8} sx={{ padding: 2 }}>
      <Stack flexDirection="row" gap={1}>
        <Typography variant="h5">{status}</Typography>
        <Badge badgeContent={machineCount}>
          {status === "Under Observation" ? (
            <CenterFocusStrongIcon sx={{ color: theme.palette.UNDER_OBSERVATION }} />
          ) : (
            <BuildIcon sx={{ color: theme.palette.BREAKDOWN }} />
          )}
        </Badge>
      </Stack>

      <Stack mb={1}>
        {activeMachinesByStatus && activeMachinesByStatus[dataKey] && activeMachinesByStatus[dataKey].length > 0 ? (
          <List
            sx={{
              overflow: "auto",
              maxHeight: "82vh",
            }}
          >
            {activeMachinesByStatus[dataKey].map((machine) => (
              <Card
                sx={{
                  mt: 2,
                  p: 1,
                  background: "rgb(247,247,247)",
                  borderRadius: 2,
                  background: "rgba(9, 30, 66, 0.25) 0px 4px 8px -2px, rgba(9, 30, 66, 0.08) 0px 0px 0px 1px",
                }}
              >
                <Stack flexDirection="row">
                  <Icon component={QrCode2Icon} sx={{ fontSize: "medium", color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
                  <Typography ml={2} variant="body2">
                    {machine.maintenanceCode}
                  </Typography>
                </Stack>
                <Stack flexDirection="row">
                  <Icon
                    component={ConstructionIcon}
                    sx={{ fontSize: "medium", color: mode === "dark" ? "#fff" : theme.palette.primary[50] }}
                  />
                  <Typography ml={2} variant="body2">
                    {machine.manufacturer} {machine.machineModel} [{machine.serialNumber}]
                  </Typography>
                </Stack>
              </Card>
            ))}
          </List>
        ) : (
          <Typography variant="body1" sx={{ paddingTop: 2 }}>
            No machines!
          </Typography>
        )}
      </Stack>
    </Paper>
  );
};

export default MachinesOverview;
