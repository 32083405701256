import api from "app/api";
import multiInstance from "app/multiPartApi";

const addUser = async (payload) => {
  const response = await api.post("/serviceapi/createUser", payload);
  return response;
};

const updateUser = async (payload) => {
  const response = await api.post("/serviceapi/updateUser", payload);
  return response;
};

const getAllUsers = async () => {
  const { data } = await api.get("/serviceapi/getAllUsers");
  if (data) {
    const modified =
      data
        ?.map((item) => ({ ...item, nameEmpno: `${item?.name || ""} ${item?.userName || ""}` }))
        ?.sort((a, b) => a.name.localeCompare(b.name)) || data?.appUsers?.sort((a, b) => a.name.localeCompare(b.name));
    return modified;
  }
  return data;
};

const getAllVendors = async () => {
  const response = await api.get("/serviceapi/getAllVendors");
  return response;
};

const updateVendor = async (payload) => {
  const response = await api.post("/serviceapi/updateVendor", payload);
  return response;
};

const createVendor = async (payload) => {
  const response = await api.post("/serviceapi/createVendor", payload);
  return response;
};

const updateVendorContact = async (payload) => {
  const response = await api.post("/serviceapi/updateVendorContact", payload);
  return response;
};

const Service = {
  getAllUsers,
  addUser,
  updateUser,
  getAllVendors,
  updateVendor,
  createVendor,
  updateVendorContact,
};

export default Service;
