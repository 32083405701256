import React, { useEffect, useState } from "react";
import Form from "component/form";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { readSpareParts, getMachineDepartments, updateSpareParts, addSpareParts } from "features/service/service.slice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Links } from "utils/pageLinkNames";
import {
  CircularProgress,
  Stack,
  TextField,
  Tooltip,
  Button,
  Card,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  Typography,
  Autocomplete,
} from "@mui/material";
import AuthorizedImage from "component/AuthorizedImage";
import {
  QrCode as QRCodeIcon,
  FileUpload as FileUploadIcon,
  AttachFile as AttachFileIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment/moment";
import Cookies from "universal-cookie";
import { translateText } from "utils/utilFunctions";

const AddEditSpare = (props) => {
  const [expanded, setExpanded] = useState(false);

  const toggleExpansion = () => {
    setExpanded(!expanded);
  };
  const { id } = useParams();
  const navigate = useNavigate();
  const [imageToUpload, setImageToUpload] = useState("");
  const [errors, setErrors] = useState({});
  const { appConstants } = useSelector((state) => state.service);
  const [formData, setFormData] = useState({
    maker: "",
    partCode: "",
    unit: "",
    location: "",
    currentStock: "",
    reorderLevel: "",
    reorderQuantity: "",
    issued: "",
    returned: "",
    description: "",
    info: "",
    image: "",
    storageRoom: "",
    rack: "",
    level: "",
    bin: "",
    attachments: [],
    // Add state for confirming deletion
    showDeleteConfirmation: false,
    deletingAttachmentIndex: -1,
  });
  const { isLoading, machineDepartments, spareParts } = useSelector((state) => state.service);
  const { mode } = useSelector((state) => state.globalState);

  const dispatch = useDispatch();

  useEffect(() => {
    if (props && props.initialFormData) {
      const setInitialData = async () => {
        const cookies = new Cookies();
        const currentCookie = cookies.get("googtrans");
        const initialState = props.initialFormData;
        const responseText =
          currentCookie?.split("/")[2] != "en" && initialState.description
            ? await translateText(initialState?.description || "", currentCookie?.split("/")[2])
            : null;

        setFormData({
          ...initialState,
          description: responseText ? responseText[0]?.translatedText : initialState.description,
        });
      };
      setInitialData();
    }
  }, [spareParts, machineDepartments]);

  const validations = {
    maker: true,
    partCode: true,
    storageRoom: true,
    rack: true,
    level: true,
    bin: true,
  };

  const handleSubmit = async () => {
    const formErrors = {};

    if (!formData.maker) formErrors.maker = "Please enter maker!";
    if (!formData.partCode) formErrors.partCode = "Please enter part code!";
    if (!formData.storageRoom) formErrors.storageRoom = "Please enter storage room!";

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      return toast.warn("Please enter required fields!");
    }
    formData.location = {
      storageRoom: formData.storageRoom || "",
      rack: formData?.rack || "",
      level: formData?.level || "",
      bin: formData?.bin || "",
    };
    delete formData.storageRoom;
    delete formData.rack;
    delete formData.level;
    delete formData.bin;
    const spareExists = spareParts.some((item) => item.partCode === formData.partCode);
    const formDataToBe = new FormData();
    if (spareExists && !id) {
      toast.error("This data already exists!");
    } else {
      formData.currentStock = parseInt(formData.currentStock);
      formData.issued = parseInt(formData.issued);
      formData.returned = parseInt(formData.returned);
      formData.reorderLevel = parseInt(formData.reorderLevel);
      formData.reorderQuantity = parseInt(formData.reorderQuantity);
      if (formData.description) {
        const responseText = await translateText(formData.description);
        formData.description = responseText[0].translatedText;
      }
      if (formData.partInfo) {
        const responseText = await translateText(formData.partInfo);
        formData.info = {
          ...formData.info,
          partInfo: responseText[0].translatedText,
        };
      }
      if (formData.expiryOrCalibrationDate)
        formData.info = {
          ...formData.info,
          expiryOrCalibrationDate: formData.expiryOrCalibrationDate,
        };

      if (id) {
        formData.modelPartId = parseInt(id);
        if (imageToUpload) {
          formDataToBe.append("image", imageToUpload);
          delete formData.image;
        }
        formDataToBe.append("spareDetails", JSON.stringify(formData));
        submitForm(updateSpareParts, formDataToBe);
      } else {
        if (imageToUpload) {
          formDataToBe.append("image", imageToUpload);
          delete formData.image;
        }
        formDataToBe.append("spareDetails", JSON.stringify(formData));
        submitForm(addSpareParts, formDataToBe);
      }
    }
  };

  const submitForm = (action, formDataToBe) => {
    if (formData.attachments && formData.attachments.length) {
      const partImages = [];
      formData.attachments.forEach((item, index) => {
        formDataToBe.append("partImages", item);
      });
    }
    // delete formDataToBe.attachments;
    dispatch(action(formDataToBe)).then((res) => {
      if (res && res.error) return toast.error("Something went wrong!");
      if (res && res.payload.data && res.payload.status === 200) {
        if (!formDataToBe.modelPartId) navigate(`/${Links.EDIT_SPARE_PART}/` + res.payload.data.id, { replace: true });
        dispatch(readSpareParts()).then((res) => {
          toast.success("Spare part " + (id ? "updated" : "added") + " successfully!", { autoClose: 1500 });
          props.setEnableEditing(false);
        });
      } else return toast.error("Something went wrong!");
    });
  };

  const handleFileUpload = (event) => {
    setImageToUpload(event.target.files[0]);
    // toast.success("Image uploaded successfully!");
  };

  const handleAttachmentChange = (event) => {
    const files = event.target.files;

    // Convert FileList to an array and store it in the state
    const newAttachments = Array.from(files);
    // setFormData({ ...formData, attachments: [...formData.attachments] });
    setFormData((prevState) => ({
      ...prevState,
      attachments: [...(prevState.attachments || []), ...newAttachments],
    }));
  };

  const handleRemoveAttachment = (index) => {
    setFormData({
      ...formData,
      showDeleteConfirmation: true,
      deletingAttachmentIndex: index,
    });
  };

  const handleConfirmDelete = () => {
    const index = formData.deletingAttachmentIndex;
    setFormData((prevState) => {
      const updatedAttachments = [...prevState.attachments];
      updatedAttachments.splice(index, 1);
      return {
        ...prevState,
        attachments: updatedAttachments,
        showDeleteConfirmation: false,
        deletingAttachmentIndex: -1,
      };
    });
  };

  const handleCancelDelete = () => {
    setFormData({
      ...formData,
      showDeleteConfirmation: false,
      deletingAttachmentIndex: -1,
    });
  };

  if (isLoading) {
    return (
      <div
        style={{
          textAlign: "center",
          paddingTop: 200,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  }
  return (
    <div>
      <Stack gap={2}>
        <Stack direction={"row"} gap={2} flexWrap={"wrap"} justifyContent={"space-between"}>
          <Stack alignItems={"center"} sx={{ flex: 0.2, mr: 4 }}>
            <Card sx={{ borderRadius: 5 }}>
              <Stack alignItems={"center"} p={3} gap={2}>
                <Button startIcon={<FileUploadIcon />} component="label" sx={mode === "dark" ? { color: "white" } : {}}>
                  Upload
                  <input
                    style={{ display: "none" }}
                    hidden
                    accept="image/*"
                    multiple
                    type="file"
                    id="fileInput"
                    onChange={handleFileUpload}
                  />
                </Button>
                {imageToUpload ? (
                  <img
                    src={URL.createObjectURL(imageToUpload)}
                    alt="Selected File"
                    style={{ maxWidth: "100%", maxHeight: "500px" }}
                    // onClick={() => handleViewFile(imageToUpload)}
                  />
                ) : formData.image ? (
                  <AuthorizedImage path={formData.image} height={250} width={300} />
                ) : null}
              </Stack>
            </Card>
          </Stack>
          <Stack direction={"row"} flex={1} gap={5} flexWrap={"wrap"}>
            <Stack gap={2}>
              <TextField
                label="Maker"
                value={formData.maker}
                onChange={(event) => setFormData({ ...formData, maker: event.target.value })}
                error={errors && !formData.maker}
                helperText={errors.maker}
              />
              <TextField
                label="Part code"
                value={formData.partCode}
                onChange={(event) => setFormData({ ...formData, partCode: event.target.value })}
                error={errors && !formData.partCode}
                helperText={errors.partCode}
              />
              <TextField label="Unit" value={formData.unit} onChange={(event) => setFormData({ ...formData, unit: event.target.value })} />

              <TextField
                label="Description"
                multiline
                value={formData.description}
                onChange={(event) => setFormData({ ...formData, description: event.target.value })}
                sx={{ minWidth: 350 }}
              />
              <TextField
                label="Part info"
                multiline
                value={formData?.partInfo || ""}
                onChange={(event) => setFormData({ ...formData, partInfo: event.target.value })}
                sx={{ minWidth: 350 }}
              />
            </Stack>
            <Stack gap={2}>
              <TextField
                label="Reorder level"
                value={formData.reorderLevel}
                onChange={(event) => setFormData({ ...formData, reorderLevel: event.target.value })}
              />
              <TextField
                label="Reorder quantity"
                value={formData.reorderQuantity}
                onChange={(event) => setFormData({ ...formData, reorderQuantity: event.target.value })}
              />
              <TextField
                label="Current stock"
                value={formData.currentStock}
                onChange={(event) => setFormData({ ...formData, currentStock: event.target.value })}
              />
              <TextField
                label="Issued"
                value={formData.issued}
                onChange={(event) => setFormData({ ...formData, issued: event.target.value })}
              />
              <TextField
                label="Returned"
                value={formData.returned}
                onChange={(event) => setFormData({ ...formData, returned: event.target.value })}
              />
            </Stack>
            <Stack gap={2}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  label="Expiry or Calibration Date"
                  format={"DD MMM YYYY"}
                  value={formData.expiryOrCalibrationDate ? moment(formData.expiryOrCalibrationDate) : null}
                  onChange={(newValue) => setFormData({ ...formData, expiryOrCalibrationDate: newValue })}
                  sx={{ minWidth: 200, maxWidth: 600 }}
                />
              </LocalizationProvider>

              {/* <Button variant="outlined" onClick={toggleExpansion}>
             Location</Button> */}
              <Autocomplete
                disablePortal
                size="small"
                options={Object.values(appConstants?.partsLocation || {}).sort((a, b) => a.localeCompare(b)) || []}
                fullWidth
                value={formData.storageRoom || null}
                sx={{ minWidth: 300 }}
                onChange={(event, newValue) => setFormData({ ...formData, storageRoom: newValue })}
                renderInput={(params) => (
                  <TextField {...params} label="Storage Room" error={errors && errors.storageRoom} helperText={errors.storageRoom} />
                )}
              />
              {
                <TextField
                  label="Rack"
                  value={formData.rack}
                  onChange={(event) => setFormData({ ...formData, rack: event.target.value })}
                  error={errors && errors.rack}
                  helperText={errors.rack}
                />
              }
              {
                <TextField
                  label="Level"
                  value={formData.level}
                  onChange={(event) => setFormData({ ...formData, level: event.target.value })}
                  error={errors && errors.level}
                  helperText={errors.level}
                />
              }
              {
                <TextField
                  label="Bin"
                  value={formData.bin}
                  onChange={(event) => setFormData({ ...formData, bin: event.target.value })}
                  error={errors && errors.bin}
                  helperText={errors.bin}
                />
              }

              <Stack mt={2} flexDirection="row" flexWrap="wrap" alignItems="center">
                <div>
                  {formData.id ? null : (
                    <div>
                      <input
                        type="file"
                        accept="image/*"
                        id="attachment-input"
                        style={{ display: "none" }}
                        onChange={handleAttachmentChange}
                        multiple
                      />
                      <label htmlFor="attachment-input">
                        <IconButton component="span">
                          <AttachFileIcon />
                        </IconButton>
                        Attach Spare Part Images
                      </label>
                      <div style={{ display: "flex", flexDirection: "row" }}>
                        {formData.attachments?.map((attachment, index) => (
                          <div key={index} style={{ marginBottom: "8px", display: "flex", alignItems: "center" }}>
                            <img
                              src={URL.createObjectURL(attachment)}
                              alt={`Attachment ${index}`}
                              style={{ maxWidth: "100px", maxHeight: "100px" }}
                            />
                            <IconButton color="error" onClick={() => handleRemoveAttachment(index)} aria-label="Delete" size="small">
                              <DeleteIcon fontSize="small" />
                            </IconButton>
                          </div>
                        ))}
                      </div>
                      <Dialog open={formData.showDeleteConfirmation} onClose={handleCancelDelete}>
                        <DialogTitle>Confirm Delete</DialogTitle>
                        <DialogContent>
                          <Typography>Are you sure you want to delete this image?</Typography>
                        </DialogContent>
                        <DialogActions>
                          <Button onClick={handleCancelDelete} variant="contained">
                            Cancel
                          </Button>
                          <Button onClick={handleConfirmDelete} variant="contained">
                            Delete
                          </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  )}
                </div>
              </Stack>
            </Stack>
          </Stack>
          <Stack sx={{ flex: 0.1 }} alignItems={"center"}>
            <Stack>
              <Tooltip title="Save">
                <Button variant="contained" onClick={handleSubmit}>
                  Save
                </Button>
              </Tooltip>
            </Stack>
          </Stack>
        </Stack>
      </Stack>

      <ToastContainer />
    </div>
  );
};

export default AddEditSpare;
