import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button, Autocomplete, TextField, Stack, IconButton, OutlinedInput, InputAdornment, Box, useTheme, Tooltip } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { Search } from "@mui/icons-material";
import Header from "component/Header";
import { Links } from "utils/pageLinkNames";
import DataGridTable from "component/DataGridTable";
import { ZoomIn as ViewIcon, Edit as EditIcon } from "@mui/icons-material";

const MachineModels = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const [searchValue, setSearchValue] = useState("");
  const { appConstants } = useSelector((state) => state.service);
  const { mode } = useSelector((state) => state.globalState);

  const tableGrids = [
    {
      field: "model",
      headerName: "Name",
      minWidth: 60,
      flex: 0.4,
    },
    {
      field: "manufacturer",
      headerName: "Manufacturer",
      minWidth: 60,
      flex: 0.4,
    },
    // {
    //   field: "active",
    //   headerName: "Active",
    //   minWidth: 60,
    //   flex: 0.4,
    //   valueGetter: (params) => (params.row[params.field] ? "Yes" : "No"),
    // },
    {
      minWidth: 60,
      flex: 0.2,
      sortable: false,
      filterable: false,
      renderCell: (args) => (
        <div>
          <IconButton sx={{ mr: 1 }} onClick={() => navigate(`/${Links.MACHINE_MODEL}/` + args.row.id)}>
            <Tooltip title="View">
              <ViewIcon sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
            </Tooltip>
          </IconButton>
          <IconButton sx={{ mr: 1 }} onClick={() => navigate(`/${Links.MACHINE_MODEL}/` + args.row.id, { state: { enableEditing: true } })}>
            <Tooltip title="Edit">
              <EditIcon sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
            </Tooltip>
          </IconButton>
        </div>
      ),
    },
  ];
  return (
    <div>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header titles={["Machine Models"]} />
      </Stack>
      <Box sx={{ mx: 2 }}>
        <Stack direction={"row"} alignItems={"center"} my={2} justifyContent={"space-between"}>
          <Stack direction={"row"} gap={1} alignItems={"center"}>
            <Button
              startIcon={<AddIcon />}
              sx={{ height: "self" }}
              autoCapitalize="none"
              variant="contained"
              onClick={() => navigate(`/${Links.MACHINE_MODEL}`)}
            >
              ADD
            </Button>
            <OutlinedInput
              id="outlined-adornment-password"
              value={searchValue}
              onChange={(event) => setSearchValue(event.target.value)}
              placeholder="Search..."
              endAdornment={
                <InputAdornment position="end">
                  <IconButton aria-label="toggle password visibility" edge="end">
                    <Search />
                  </IconButton>
                </InputAdornment>
              }
              sx={{ minWidth: 300, maxWidth: 600, height: 35 }}
            />
          </Stack>
        </Stack>
        <DataGridTable
          rowId={"id"}
          data={
            appConstants?.machineModels.filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchValue.toLowerCase())) ||
            []
          }
          columnAttributes={tableGrids}
        />
      </Box>
    </div>
  );
};

export default MachineModels;
