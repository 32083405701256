import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useLocation } from "react-router-dom";

import {
  Card,
  Stack,
  Typography,
  CircularProgress,
  Button,
  Dialog,
  DialogContent,
  DialogActions,
  Autocomplete,
  TextField,
  IconButton,
  DialogTitle,
  useTheme,
  Checkbox,
  Tooltip,
  Paper,
  Box,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import { Add as AddIcon, Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { Links } from "utils/pageLinkNames";

import {
  getServiceTicketById,
  deleteImageOrVideoFromServiceTicket,
  addImageOrVideoToServiceTicket,
  readServiceTicketMessages,
} from "features/service/service.slice";

import AuthorizedImage from "component/AuthorizedImage";
import Header from "component/Header";
import DrawingComponent from "component/DrawingComponent";
import ViewImageVideoModal from "component/ViewImageVideoModal";

const TemplateForm = ({ templateInfo = null, updateTemplateInfo, templateId = null, errorMsg = {} }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const { ticketId } = useParams();

  const [modalTaskStatusVisible, setTaskStatusModalVisible] = useState(false);
  const [selectedSectionNTask, setSelectedSectionNTask] = useState({
    selectedSection: null,
    selectedTask: null,
  });
  const [isDrawingComponentOpen, setDrawingComponentOpen] = useState(false);
  const [selectedImageToEdit, setSelectedImageToEdit] = useState(null);
  const [showDialogView, setShowDialogView] = useState(false);
  const [taskImageList, setTaskImageList] = useState([]);
  const [uploadProgress, setUploadProgress] = useState({ progress: 0, rate: 0, total: 0, buffer: 10 });

  const { appConstants, serviceTicketById, serviceTicketMessages, allTemplates } = useSelector((state) => state.service);
  const { mode } = useSelector((state) => state.globalState);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (allTemplates.data.length) {
      //   const templatesExist = allTemplates.data.filter((template) => parseInt(template?.vendor) === parseInt(vendorId));
      //   if (!templatesExist && !templatesExist.length) {
      // const payload = {
      //   ticketId: serviceTicketById.id,
      // };
      // if (serviceTicketById.vendor) {
      //   payload.vendorId = serviceTicketById.vendor.id;
      //   navigation.replace(screenNames.EQUIPMENT_SUPPORT_FORM, payload);
      // }
      //   }
      //   console.log("---", serviceTicketById);
      if (templateId) {
        const svcTicketTemplate = allTemplates.data.find((template) => template?.id === templateId);
        if (svcTicketTemplate) {
          const templateSections = svcTicketTemplate.sections.map((sectionItem) => ({
            ...sectionItem,
            tasks: sectionItem.tasks.map((taskItem) => ({
              ...taskItem,
              taskStatus: appConstants?.configurableDefaultValues?.taskStatus || appConstants?.taskStatus?.ACCEPTABLE,
            })),
          }));
          updateTemplateInfo({ ...svcTicketTemplate, sections: templateSections });
        }
      }
    }
  }, [allTemplates.isLoading, serviceTicketById]);

  const onPressSaveTask = () => {
    if (selectedSectionNTask?.selectedTask?.images?.length) {
      const taskImages = serviceTicketMessages.filter((msgItem) => {
        const templateTaskMapInfo = msgItem?.templateInfo?.templateId ? msgItem?.templateInfo : JSON.parse(msgItem?.templateInfo);
        return (
          selectedSectionNTask?.selectedSection?.id === templateTaskMapInfo?.sectionId &&
          selectedSectionNTask?.selectedTask?.id === templateTaskMapInfo?.taskId
        );
      });
      if (!taskImages.length) return toast.warn(`Please add an image!`);
    }
    if (!selectedSectionNTask.selectedTask.taskStatus)
      return toast.warn(`Please select task status (${Object.values(appConstants.taskStatus).join("/")})!`);
    if (
      selectedSectionNTask.selectedTask.taskStatus === appConstants?.taskStatus.NOT_ACCEPTABLE &&
      !selectedSectionNTask.selectedTask.reason
    )
      return toast.warn("Please enter reason!");

    const nextState = templateInfo.sections.map((sectionitem) =>
      selectedSectionNTask.selectedSection.id == sectionitem.id
        ? {
            ...sectionitem,
            tasks: sectionitem.tasks.map((taskitem) => {
              return taskitem.id === selectedSectionNTask.selectedTask.id
                ? {
                    ...selectedSectionNTask.selectedTask,
                    reason:
                      selectedSectionNTask.selectedTask.taskStatus !== appConstants?.taskStatus.NOT_ACCEPTABLE
                        ? ""
                        : selectedSectionNTask.selectedTask.reason,
                  }
                : taskitem;
            }),
          }
        : sectionitem
    );
    updateTemplateInfo({ ...templateInfo, sections: nextState });
    setSelectedSectionNTask({ selectedSection: null, selectedTask: null });
    setTaskStatusModalVisible(false);
  };

  const onUpdateText = (e, field) => {
    setSelectedSectionNTask({
      ...selectedSectionNTask,
      selectedTask: {
        ...selectedSectionNTask.selectedTask,
        [field]: e.target.value,
      },
    });
  };
  const handleAttachmentChange = (event) => {
    const files = event.target.files;
    const filesArray = Array.from(files);
    setDrawingComponentOpen(true);
    setSelectedImageToEdit(filesArray[0]);
  };
  const handleCloseDialogView = () => {
    // setCurrentFilePathIndex(null);
    setTaskImageList([]);
    setShowDialogView(false);
    // fileInputRef.current.value = null;
  };

  const showTicketTaskImages = (taskItem, sectionItem) => {
    const ticketTaskImages = serviceTicketMessages.filter((msgItem) => {
      const taskMap = msgItem?.templateInfo?.templateId ? msgItem?.templateInfo : JSON.parse(msgItem?.templateInfo);
      return sectionItem?.id === taskMap?.sectionId && taskItem?.id === taskMap?.taskId;
    });
    return (
      <div>
        {ticketTaskImages.length ? (
          <div>
            {ticketTaskImages.map((imageItem) => (
              <Stack direction={"row"}>
                <div
                  onClick={() => {
                    setTaskImageList(ticketTaskImages);
                    setShowDialogView(true);
                  }}
                >
                  <AuthorizedImage path={imageItem.message} width={100} height={70} />
                </div>
                <Tooltip title={"Delete image"}>
                  <IconButton
                    aria-label="delete"
                    color="error"
                    size="small"
                    onClick={() => {
                      dispatch(
                        deleteImageOrVideoFromServiceTicket({
                          serviceTicketId: serviceTicketById.id,
                          messageIds: [imageItem.id],
                        })
                      ).then((res) => {
                        toast.success("Image deleted!");
                        dispatch(readServiceTicketMessages(serviceTicketById.id)).then((response) => {});
                      });
                      // templateData.sections[sectionItem.index].tasks.splice(index, 1);
                      // setTemplateData({ ...templateData });
                    }}
                  >
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </Stack>
            ))}
          </div>
        ) : (
          <div style={{ cursor: "pointer" }}>
            <input
              type="file"
              accept="image/*"
              id={"attachment-input" + sectionItem.id + taskItem.id}
              style={{ display: "none" }}
              onChange={(event) => {
                setSelectedSectionNTask({
                  selectedSection: sectionItem,
                  selectedTask: {
                    ...taskItem,
                  },
                });
                handleAttachmentChange(event);
              }}
              // multiple
            />
            <div>
              <label style={{ cursor: "pointer" }} htmlFor={"attachment-input" + sectionItem.id + taskItem.id}>
                <Tooltip title={"Add Image"}>
                  <Paper
                    sx={{
                      mt: -2,
                      p: 1,
                      borderColor: mode === "dark" ? "#fff" : "#000",
                    }}
                  >
                    <Stack direction={"row"} alignItems={"center"}>
                      <AddIcon fontSize="medium" sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[100] }} />
                      <div>Add image</div>
                    </Stack>
                  </Paper>
                </Tooltip>
              </label>
              <div>
                <Typography variant="caption" color={"red"}>
                  {errorMsg && Object.keys(errorMsg) ? errorMsg[sectionItem.id + "" + taskItem.id] : ""}
                </Typography>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  };

  const progressCallback = (progressEvent) => {
    const percentRateFraction = progressEvent.rate / 1048576;
    const percentTotalFraction = progressEvent.total / 1048576;
    const percentLoadedFraction = progressEvent.loaded / 1048576;
    const percentFraction = percentLoadedFraction / percentTotalFraction;
    const roundedNum = Math.round(percentLoadedFraction * 100) / 100;
    const roundedNumRate = Math.round(percentRateFraction * 100) / 100;
    const roundedNumTotal = Math.round(percentTotalFraction * 100) / 100;
    const diff = Math.random() * 10;
    setUploadProgress({ progress: roundedNum, rate: roundedNumRate, total: roundedNumTotal, buffer: percentFraction * 100 + diff + 5 });
  };

  const onClickDoneEditing = (editedImage) => {
    if (editedImage) {
      const formData = new FormData();
      formData.append("image", editedImage);
      editedImage.type == "application/pdf" ? formData.append("type", "pdf") : formData.append("type", "image/jpg");
      formData.append("serviceTicketId", "" + ticketId);
      const templateSvcticketMap = {
        templateId: templateInfo?.id || "",
        taskId: selectedSectionNTask?.selectedTask?.id || 1,
        sectionId: selectedSectionNTask?.selectedSection?.id || 1,
      };
      formData.append("templateSvcTicketMap", JSON.stringify(templateSvcticketMap));
      setSelectedSectionNTask(null);
      setDrawingComponentOpen(false);
      setSelectedImageToEdit(null);
      try {
        dispatch(addImageOrVideoToServiceTicket({ formData, progressCallback: progressCallback })).then((res) => {
          toast.success("File uploaded successfully!", {
            autoClose: 1000,
          });
          setUploadProgress({ progress: 0, rate: 0, total: 0, buffer: 10 });
          dispatch(readServiceTicketMessages(ticketId));
        });
      } catch (error) {
        toast.error("Please try after some time!");
      }
    }
  };
  const cancelSelection = () => {
    setDrawingComponentOpen(false);
    setSelectedImageToEdit(null);
    setSelectedSectionNTask(null);
  };

  return (
    <div>
      <div>
        {isDrawingComponentOpen && (
          <DrawingComponent
            isDrawingComponentOpen={isDrawingComponentOpen}
            closeDrawingComponent={setDrawingComponentOpen}
            onClickDoneEditing={onClickDoneEditing}
            selectedImageUri={selectedImageToEdit}
            cancelSelection={cancelSelection}
            buttonText={"Upload"}
          />
        )}
      </div>
      <div>
        {
          <ViewImageVideoModal
            showDialogView={showDialogView}
            handleCloseDialogView={handleCloseDialogView}
            data={taskImageList}
            selectedFileIndex={0}
          />
        }
      </div>

      <div>
        <Dialog open={modalTaskStatusVisible} onClose={() => setTaskStatusModalVisible(false)}>
          <DialogTitle fontWeight={"bold"}>{selectedSectionNTask?.selectedTask?.task || ""}</DialogTitle>
          <DialogContent>
            <Stack direction={"row"} sx={{ my: 1, justifyContent: "space-between" }}>
              {Object.values(appConstants.taskStatus).map((statusItem) => (
                <div>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Checkbox
                      checked={statusItem == selectedSectionNTask?.selectedTask?.taskStatus ? true : false}
                      onChange={(event) => {
                        setSelectedSectionNTask({
                          ...selectedSectionNTask,
                          selectedTask: {
                            ...selectedSectionNTask.selectedTask,
                            taskStatus: statusItem,
                          },
                        });
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                    <Typography>{statusItem}</Typography>
                  </Stack>
                </div>
              ))}
            </Stack>
            <Stack rowGap={2}>
              {selectedSectionNTask?.selectedTask?.taskStatus === appConstants.taskStatus.NOT_ACCEPTABLE && (
                <TextField
                  label="Reason"
                  value={selectedSectionNTask?.selectedTask?.reason || ""}
                  onChange={(e) => onUpdateText(e, "reason")}
                />
              )}
              <TextField
                label="Remarks"
                value={selectedSectionNTask?.selectedTask?.remarks || ""}
                onChange={(e) => onUpdateText(e, "remarks")}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setTaskStatusModalVisible(false)}>
              Cancel
            </Button>
            <Button variant="contained" onClick={() => onPressSaveTask()} color="primary">
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
      <Stack sx={{ mb: 1 }}>
        <div>
          <Stack>
            <Stack direction={"row"} sx={{ flexWrap: "wrap", width: "100%" }} gap={2}>
              {templateInfo &&
                templateInfo?.sections.map((sectionItem) => (
                  <Paper sx={{ p: 2, width: "49%" }}>
                    <div>
                      <Typography fontWeight={"bold"} mb={1.5}>
                        {sectionItem.name}
                      </Typography>
                      {sectionItem.tasks.map((taskItem) => (
                        <div>
                          <Paper sx={{ p: 1, mb: 1.5 }}>
                            <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"}>
                              <Typography fontWeight={"bold"}>{taskItem.task}</Typography>
                              <Stack direction={"row"} alignItems={"center"} columnGap={2}>
                                <Typography fontWeight={"bold"}>{taskItem?.taskStatus || appConstants.taskStatus.ACCEPTABLE}</Typography>
                                <Tooltip title="Update Status">
                                  <Button
                                    startIcon={<EditIcon />}
                                    sx={{ height: "self" }}
                                    autoCapitalize="none"
                                    size="small"
                                    variant="contained"
                                    onClick={() => {
                                      setSelectedSectionNTask({
                                        selectedSection: sectionItem,
                                        selectedTask: {
                                          ...taskItem,
                                        },
                                      });
                                      setTaskStatusModalVisible(true);
                                    }}
                                  >
                                    Update
                                  </Button>
                                </Tooltip>
                              </Stack>
                            </Stack>
                            <Stack my={1}>
                              {taskItem?.taskStatus === appConstants.taskStatus.NOT_ACCEPTABLE && (
                                <Typography>
                                  <span style={{ fontWeight: "bold" }}>Reason : </span>
                                  {taskItem?.reason || ""}
                                </Typography>
                              )}
                              <Typography>
                                <span style={{ fontWeight: "bold" }}>Remarks : </span>
                                {taskItem?.remarks || ""}
                              </Typography>
                            </Stack>
                            <Stack direction={"row"} justifyContent={"space-between"} mt={2}>
                              {taskItem.images ? (
                                taskItem.images.map((imageItem) => (
                                  <div
                                    onClick={() => {
                                      const imageArray = [{ message: imageItem, type: appConstants?.allTypes?.TYPE_OF_CONTENT.IMAGE }];
                                      setTaskImageList(imageArray);
                                      setShowDialogView(true);
                                    }}
                                  >
                                    <AuthorizedImage path={imageItem} width={100} height={70} />
                                  </div>
                                ))
                              ) : (
                                <div></div>
                              )}
                              {showTicketTaskImages(taskItem, sectionItem)}
                            </Stack>
                          </Paper>
                        </div>
                      ))}
                    </div>
                  </Paper>
                ))}
            </Stack>
          </Stack>
        </div>
      </Stack>
    </div>
  );
};

export default TemplateForm;
