import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  CircularProgress,
  OutlinedInput,
  IconButton,
  InputAdornment,
  Button,
  Stack,
  Box,
  useTheme,
  Tooltip,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";
import { Search, Clear } from "@mui/icons-material"; // Import Clear icon
import AddIcon from "@mui/icons-material/Add";
import { ZoomIn as ViewIcon, Edit as EditIcon } from "@mui/icons-material";

import { readAsyncStorageValues } from "features/service/service.slice";
import { getAllVendors } from "features/appUsers/appUsers.slice";
import "../../App.css";
import "../../stylesheet/charts.css";
import { useNavigate } from "react-router-dom";
import { Links } from "utils/pageLinkNames";
import Header from "component/Header";
import DataGridTable from "component/DataGridTable";
import SendIcon from "@mui/icons-material/Send";

const Vendors = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();

  const { allVendorsList } = useSelector((state) => state.appUsers);
  const { mode } = useSelector((state) => state.globalState);

  useEffect(() => {
    dispatch(getAllVendors());
  }, [dispatch]);

  const [searchValue, setSearchValue] = useState("");
  const [activeFilter, setActiveFilter] = useState(""); // State for filter

  const handleSearchChange = (event) => {
    setSearchValue(event.target.value);
  };

  const handleFilterChange = (event) => {
    setActiveFilter(event.target.value);
  };

  const handleClearFilter = () => {
    setActiveFilter("");
  };

  const gridVendorAction = (data) => {
    return (
      <IconButton sx={{ mr: 1 }} onClick={() => navigate(`/${Links.EDIT_VENDOR}/` + data.id)}>
        <Tooltip title="View">
          <ViewIcon sx={{ color: mode === "dark" ? "#fff" : theme.palette.primary[50] }} />
        </Tooltip>
      </IconButton>
    );
  };

  const vendorGrid = [
    {
      field: "customerName",
      headerName: "Name",
      minWidth: 50,
      flex: 0.3,
    },
    {
      field: "emailAddress",
      headerName: "Email",
      minWidth: 30,
      flex: 0.2,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 50,
      flex: 0.5,
    },
    {
      field: "active",
      headerName: "Active",
      minWidth: 40,
      flex: 0.1,
      valueGetter: (params) => (params.value ? "Yes" : "No"),
    },
    {
      headerName: "",
      minWidth: 30,
      flex: 0.2,
      renderCell: gridVendorAction,
      sortable: false,
      filterable: false,
    },
  ];

  return (
    <div>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header titles={["Vendors"]} />
      </Stack>
      <Box sx={{ mx: 2 }}>
        <Stack direction={"row"} gap={1} alignItems={"center"} my={2}>
          <Box>
            <Button
              startIcon={<AddIcon />}
              sx={{ height: "self" }}
              autoCapitalize="none"
              variant="contained"
              onClick={() => navigate(`/${Links.ADD_VENDOR}/`)}
            >
              Add
            </Button>
          </Box>

          <OutlinedInput
            id="outlined-adornment-password"
            value={searchValue}
            onChange={(event) => handleSearchChange(event)}
            placeholder="Search..."
            endAdornment={
              <InputAdornment position="end">
                <IconButton aria-label="toggle password visibility" edge="end">
                  <Search />
                </IconButton>
              </InputAdornment>
            }
            sx={{ minWidth: 300, maxWidth: 600, height: 35 }}
          />

          <FormControl variant="outlined" size="small" sx={{ minWidth: 100 }}>
            <InputLabel
              sx={{
                backgroundColor: mode === "dark" ? "none" : "#fff",
                paddingLeft: "7px",
                paddingRight: "7px",
                "&.Mui-focused": {
                  color: mode === "dark" ? "#fff" : "#008080",
                },
              }}
            >
              Active
            </InputLabel>
            <Select
              value={activeFilter}
              onChange={handleFilterChange}
              endAdornment={
                <InputAdornment position="center">
                  {activeFilter && (
                    <IconButton onClick={handleClearFilter} size="small" sx={{ p: 1.3 }}>
                      <Clear />
                    </IconButton>
                  )}
                </InputAdornment>
              }
            >
              <MenuItem value="Yes">Yes</MenuItem>
              <MenuItem value="No">No</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        {allVendorsList.isLoading ? (
          <div
            style={{
              textAlign: "center",
              paddingTop: 200,
            }}
          >
            <CircularProgress color="secondary" />
          </div>
        ) : (
          <DataGridTable
            rowId={"id"}
            data={allVendorsList.data
              .filter((item) => {
                if (activeFilter === "") {
                  return true; // Show all items if no filter is selected
                }
                return item.active === (activeFilter === "Yes");
              })
              .filter((item) => Object.values(item).join(" ").toLowerCase().includes(searchValue.toLowerCase()))}
            columnAttributes={vendorGrid}
          />
        )}
      </Box>
    </div>
  );
};

export default Vendors;
