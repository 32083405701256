import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";
import {
  Card,
  Stack,
  Paper,
  Typography,
  List,
  Button,
  Dialog,
  Tooltip,
  DialogContent,
  DialogActions,
  Box,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { QrCode as QRCodeIcon, Download as DownloadIcon, FileUpload as FileUploadIcon, Close as CloseIcon } from "@mui/icons-material";
import moment from "moment/moment";
import {
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ChevronLeft as LeftArrowIcon,
  ChevronRight as RightArrowIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

const ConfirmDeleteModal = ({
  showConfirmDeleteModal = false,
  onConfirmDelete,
  closeConfirmDeleteModal,
  modalTitle = "Alert",
  modalTextContent = "",
}) => {
  return (
    <div>
      <Dialog open={showConfirmDeleteModal} onClose={() => closeConfirmDeleteModal()}>
        <DialogTitle fontWeight={"bold"}>{modalTitle}</DialogTitle>
        <DialogContent>
          <Typography>{modalTextContent}</Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={() => closeConfirmDeleteModal()}>
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              onConfirmDelete();
            }}
            color="primary"
          >
            Confirm Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ConfirmDeleteModal;
