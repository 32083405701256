import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  Tooltip,
  Card,
  Stack,
  List,
  Typography,
  CircularProgress,
  Button,
  Paper,
  Autocomplete,
  TextField,
  Grid,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@mui/material";
import { readServiceTimeLog, readAsyncStorageValues, addTimeLogToServiceTicket } from "features/service/service.slice";
import moment from "moment";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { toast } from "react-toastify";
import { translateText } from "utils/utilFunctions";

const Timelogs = ({ ticketId }) => {
  const dispatch = useDispatch();
  const { serviceTimeLogs, serviceTicketById, appConstants, isLoading } = useSelector((state) => state.service);
  const { user } = useSelector((state) => state.auth);

  const [showTimelogConfirmDialog, setShowTimelogConfirmDialog] = useState(false);
  const [showTimelogDialog, setShowTimelogDialog] = useState(false);
  const [startAndEndTime, setStartAndEndTime] = useState({ startTime: null, endTime: null });
  const [manualStartAndEndTime, setManualStartAndEndTime] = useState({ startTime: null, endTime: null });
  const [timelogNotes, setTimelogNotes] = useState("");

  useEffect(() => {
    dispatch(readAsyncStorageValues());
    dispatch(readServiceTimeLog(ticketId));
  }, [dispatch]);
  useEffect(() => {
    if (serviceTimeLogs.length && ticketId) {
      setManualStartAndEndTime({ ...manualStartAndEndTime, startTime: serviceTimeLogs.find((entry) => !entry.endTime)?.startTime || null });
    }
  }, [serviceTimeLogs]);
  const minutes_to_hhmm = (numberOfMinutes) => {
    //calculate hours
    var hh =
      numberOfMinutes.years() * (365 * 24) + numberOfMinutes.months() * (30 * 24) + numberOfMinutes.days() * 24 + numberOfMinutes.hours();
    //get minutes
    var mm = numberOfMinutes.minutes();
    //return total time in hh:mm format
    return hh + "h " + mm + " m";
  };
  const onPressSubmit = async () => {
    if (!startAndEndTime.startTime) return toast.warn("Please enter start time!");
    if (!startAndEndTime.endTime) return toast.warn("Please enter end time!");
    if (moment(startAndEndTime.startTime).isAfter(moment(startAndEndTime.endTime)))
      return toast.warn("Start time should be earlier than end time!");
    if (!timelogNotes || !timelogNotes.trim()) return toast.warn("Please enter timelog notes!");

    const isTimelogOverlapped = serviceTimeLogs.filter(
      (entry) =>
        moment.utc(startAndEndTime.startTime).isBetween(moment(entry.startTime), moment(entry.endTime)) ||
        moment.utc(startAndEndTime.endTime).isBetween(moment(entry.startTime), moment(entry.endTime)) ||
        moment.utc(entry.startTime).isBetween(moment(startAndEndTime.startTime), moment(startAndEndTime.endTime)) ||
        moment.utc(entry.endTime).isBetween(moment(startAndEndTime.startTime), moment(startAndEndTime.endTime)) ||
        (moment.utc(entry.endTime).isSame(moment.utc(startAndEndTime.endTime)) &&
          moment.utc(entry.startTime).isSame(moment.utc(startAndEndTime.startTime)))
    );
    if (isTimelogOverlapped.length) return toast.warn("Timelogs cannot be overlapped.");
    if (startAndEndTime.startTime && startAndEndTime.endTime && timelogNotes && timelogNotes.trim().length) {
      if (moment(startAndEndTime.endTime).diff(moment(startAndEndTime.startTime), "seconds") > 60) {
        if (timelogNotes) {
          const translatedDescription = await translateText(timelogNotes);
          if (translatedDescription[0]?.detectedSourceLanguage !== "en")
            startAndEndTime.timelogNotes = translatedDescription[0]?.translatedText || "N.A";
          else startAndEndTime.timelogNotes = timelogNotes;
        }
        startAndEndTime.serviceTicketId = ticketId;
        startAndEndTime.type = appConstants.allTypes.TIME_LOG_TYPE.MANUAL;
        startAndEndTime.startTime = moment.utc(startAndEndTime.startTime).format("YYYY-MM-DDTHH:mm:ssZ");
        startAndEndTime.endTime = moment.utc(startAndEndTime.endTime).format("YYYY-MM-DDTHH:mm:ssZ");
        dispatch(addTimeLogToServiceTicket(startAndEndTime)).then((res) => {
          dispatch(readServiceTimeLog(ticketId));
          setShowTimelogDialog(false);
          setStartAndEndTime({ startTime: null, endTime: null });
          setTimelogNotes("");
          toast.success("Time log added.");
        });
      } else return toast.warn("", "Timelog cannot be less than 1 minute.");
    } else return toast.warn("Please enter required fields!");
  };

  const onPunchInOrOut = async () => {
    const punchoutPending = serviceTimeLogs.find((entry) => !entry.endTime);
    const punchinObj = {
      serviceTicketId: ticketId,
      type: appConstants.allTypes.TIME_LOG_TYPE.PUNCH_IN_PUNCH_OUT,
    };
    if (punchoutPending) {
      if (!manualStartAndEndTime.endTime) return toast.warn("Please enter end time!");
      if (moment(manualStartAndEndTime.endTime).diff(moment(manualStartAndEndTime.startTime), "seconds") <= 60)
        return toast.warn("Timelog cannot be less than 1 minute!");
      if (moment(manualStartAndEndTime.startTime).isAfter(moment(manualStartAndEndTime.endTime)))
        return toast.warn("End time should be after start time!");
      if (!timelogNotes || !timelogNotes.trim()) return toast.warn("Please enter timelog notes!");
      if (timelogNotes) {
        const translatedDescription = await translateText(timelogNotes);
        if (translatedDescription[0]?.detectedSourceLanguage !== "en")
          punchinObj.timelogNotes = translatedDescription[0]?.translatedText || "";
        else punchinObj.timelogNotes = timelogNotes;
      }
      punchinObj.startTime = punchoutPending.startTime;
      punchinObj.timelogId = punchoutPending.id;
      punchinObj.endTime = moment.utc(manualStartAndEndTime.endTime).format("YYYY-MM-DDTHH:mm:ssZ");
    } else {
      punchinObj.startTime = moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ssZ");
    }

    dispatch(addTimeLogToServiceTicket(punchinObj)).then((res) => {
      if (res && res?.payload && res?.payload?.data) {
        toast.success(`Time log added.`);
        dispatch(readServiceTimeLog(ticketId)).then(() => {});
      }
    });
    setManualStartAndEndTime({ startTime: null, endTime: null });
    setStartAndEndTime({ startTime: null, endTime: null });
    setShowTimelogConfirmDialog(false);
    setShowTimelogDialog(false);
  };
  const confirmMessageContent = () => {
    const type = serviceTimeLogs.find((entry) => !entry.endTime) ? `Punch Out` : `Punch In`;
    return (
      <div>
        <Typography fontWeight={"bold"}>{`${type}!`}</Typography>
        <Typography>{`Are you sure that you want to ${type} ?`}</Typography>
      </div>
    );
  };

  if (isLoading)
    return (
      <div
        style={{
          textAlign: "center",
          paddingTop: 50,
          paddingBottom: 50,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  return (
    <div>
      <div>
        <Dialog open={showTimelogConfirmDialog} sx={{ marginTop: -2 }}>
          <DialogContent sx={{ mb: 1 }}>{confirmMessageContent()}</DialogContent>
          <Stack direction="row" justifyContent={"flex-end"} mb={2} mx={2} columnGap={2}>
            <Button
              variant="contained"
              onClick={() => {
                setManualStartAndEndTime({
                  endTime: null,
                  startTime: serviceTimeLogs.find((entry) => !entry.endTime)?.startTime || null,
                });
                setStartAndEndTime({ startTime: null, endTime: null });
                setShowTimelogConfirmDialog(false);
              }}
            >
              Cancel
            </Button>
            <Button variant="contained" onClick={() => onPunchInOrOut()}>
              Submit
            </Button>
          </Stack>
        </Dialog>
      </div>
      <div>
        <Dialog open={showTimelogDialog} sx={{ marginTop: -25 }}>
          <DialogTitle fontWeight={"bold"}>Add timelog</DialogTitle>
          <DialogContent sx={{ mb: 3 }}>
            {serviceTimeLogs.find((entry) => !entry.endTime) ? (
              <Stack direction={"row"} my={1} columnGap={3} alignItems={"center"}>
                <Button
                  onClick={() => {
                    serviceTimeLogs.find((entry) => !entry.endTime)
                      ? setManualStartAndEndTime({
                          ...manualStartAndEndTime,
                          endTime: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ssZ"),
                        })
                      : setShowTimelogConfirmDialog(true);
                  }}
                >
                  {serviceTimeLogs.find((entry) => !entry.endTime) ? "Punch out" : "Punch in"}
                </Button>
                <Typography>Or</Typography>
                {serviceTimeLogs.find((entry) => !entry.endTime) ? (
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateTimePicker
                      slotProps={{ textField: { size: "small" } }}
                      minDate={moment(moment(serviceTimeLogs.find((entry) => !entry.endTime)?.startTime || new Date()).toDate())}
                      maxDate={moment(new Date())}
                      label="End time"
                      format={"DD MMM YYYY HH:mm"}
                      value={manualStartAndEndTime.endTime ? moment(manualStartAndEndTime.endTime) : null}
                      closeOnSelect={false}
                      variant="standard"
                      sx={{
                        minWidth: 220,
                        maxWidth: 600,
                        "& .Mui-error": {
                          color: "black",
                        },
                        "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
                          borderColor: "#C8C8C8",
                          borderBlockColor: "#C8C8C8",
                          color: "black",
                        },
                        "& .MuiInputLabel-root": {
                          color: "#888888",
                        },
                      }}
                      onChange={(newValue) => setManualStartAndEndTime({ ...manualStartAndEndTime, endTime: newValue })}
                    />
                  </LocalizationProvider>
                ) : null}
              </Stack>
            ) : (
              <Stack>
                <Button
                  onClick={() => {
                    serviceTimeLogs.find((entry) => !entry.endTime)
                      ? setManualStartAndEndTime({
                          ...manualStartAndEndTime,
                          endTime: moment.utc(new Date()).format("YYYY-MM-DDTHH:mm:ssZ"),
                        })
                      : setShowTimelogConfirmDialog(true);
                  }}
                >
                  {serviceTimeLogs.find((entry) => !entry.endTime) ? "Punch out" : "Punch in"}
                </Button>
                <Stack alignItems={"center"} my={1}>
                  <Typography>Or</Typography>
                </Stack>
              </Stack>
            )}
            {manualStartAndEndTime.endTime ? (
              <Stack alignItems={"center"} my={2}>
                <Typography>Start time : {moment(manualStartAndEndTime.startTime).format("DD MMM YY HH:mm")}</Typography>
                <Typography>
                  You are logging{" "}
                  {manualStartAndEndTime.endTime
                    ? minutes_to_hhmm(
                        moment.duration(
                          moment(moment(manualStartAndEndTime.endTime).format("DD MMM YY HH:mm"), "DD MMM YY hh:mm").diff(
                            moment(moment(manualStartAndEndTime.startTime).format("DD MMM YY HH:mm"), "DD MMM YY hh:mm"),
                            "minutes"
                          ),
                          "minutes"
                        )
                      )
                    : "-"}{" "}
                </Typography>
              </Stack>
            ) : null}
            {serviceTimeLogs.find((entry) => !entry.endTime) ? null : (
              <Stack direction={"row"} my={1} columnGap={2}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    slotProps={{ textField: { size: "small" } }}
                    minDate={moment(moment(serviceTicketById.dateCreated).toDate())}
                    maxDate={moment(new Date())}
                    label="Start time"
                    format={"DD MMM YYYY HH:mm"}
                    value={startAndEndTime.startTime ? moment(startAndEndTime.startTime) : null}
                    // onChange={calculateDuedate}
                    closeOnSelect={false}
                    variant="standard"
                    sx={{
                      minWidth: 250,
                      maxWidth: 600,
                      "& .Mui-error": {
                        color: "black",
                      },
                      "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#C8C8C8",
                        borderBlockColor: "#C8C8C8",
                        color: "black",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#888888",
                      },
                    }}
                    onChange={(newValue) => setStartAndEndTime({ ...startAndEndTime, startTime: newValue, endTime: null })}
                  />
                </LocalizationProvider>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DateTimePicker
                    slotProps={{ textField: { size: "small" } }}
                    minDateTime={moment(startAndEndTime.startTime).add("minute", 2)}
                    minDate={moment(startAndEndTime.startTime)}
                    maxDate={moment(startAndEndTime.startTime)}
                    label="End time"
                    format={"DD MMM YYYY HH:mm"}
                    disabled={startAndEndTime.startTime ? false : true}
                    value={startAndEndTime.endTime ? moment(startAndEndTime.endTime) : null}
                    // onChange={calculateDuedate}
                    closeOnSelect={false}
                    variant="standard"
                    sx={{
                      minWidth: 250,
                      maxWidth: 600,
                      "& .Mui-error": {
                        color: "black",
                      },
                      "& .MuiOutlinedInput-root.Mui-error .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#C8C8C8",
                        borderBlockColor: "#C8C8C8",
                        color: "black",
                      },
                      "& .MuiInputLabel-root": {
                        color: "#888888",
                      },
                    }}
                    onChange={(newValue, e) => setStartAndEndTime({ ...startAndEndTime, endTime: newValue })}
                  />
                </LocalizationProvider>
              </Stack>
            )}
            <TextField
              sx={{ mt: 1 }}
              label="Timelog notes"
              fullWidth
              multiline
              maxRows={8}
              onChange={(e) => setTimelogNotes(e.target.value)}
            />
          </DialogContent>
          <Stack direction="row" justifyContent={"flex-end"} mb={2} mx={2} columnGap={2}>
            <Button
              variant="contained"
              onClick={() => {
                setManualStartAndEndTime({
                  endTime: null,
                  startTime: serviceTimeLogs.find((entry) => !entry.endTime)?.startTime || null,
                });
                setStartAndEndTime({ startTime: null, endTime: null });
                setShowTimelogDialog(false);
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              onClick={() => (serviceTimeLogs.find((entry) => !entry.endTime) ? onPunchInOrOut() : onPressSubmit())}
            >
              Submit
            </Button>
          </Stack>
        </Dialog>
      </div>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
        <Typography variant="h5" fontWeight={600} mb={1}>
          Timelogs
        </Typography>
        {serviceTicketById.assignees?.length &&
        serviceTicketById.assignees
          ?.map((item) => item.id)
          .includes(appConstants?.appUsers?.find((users) => users.userName == user.username)?.userId) ? (
          <Tooltip title="Add timelog">
            <Button sx={{ height: "self" }} variant="contained" onClick={() => setShowTimelogDialog(true)}>
              Add
            </Button>
          </Tooltip>
        ) : null}
      </Stack>
      <Stack mb={1}>
        <List
          sx={{
            overflow: "auto",
            maxHeight: 500,
          }}
        >
          {serviceTimeLogs.length
            ? serviceTimeLogs.map((timeLog) => (
                <Paper
                  sx={{
                    mt: 2,
                    p: 1,
                    border: "1px solid #ddd",
                    boxShadow: "0 10px 20px rgba(0,0,0,0.19), 0 4px 4px rgba(0,0,0,0.03)",
                    // background: "rgb(247,247,247)",
                    // background: "linear-gradient(90deg, rgba(247,247,247,1) 0%, rgba(255,255,255,1) 92%, rgba(247,247,247,1) 100%)",
                    borderRadius: 2,
                  }}
                >
                  <Stack flexDirection="row">
                    <Typography>
                      Hours :{" "}
                      {timeLog.endTime
                        ? minutes_to_hhmm(
                            moment.duration(
                              moment(moment(timeLog.endTime).format("DD MMM YY HH:mm"), "DD MMM YY hh:mm").diff(
                                moment(moment(timeLog.startTime).format("DD MMM YY HH:mm"), "DD MMM YY hh:mm"),
                                "minutes"
                              ),
                              "minutes"
                            )
                          )
                        : " -"}
                    </Typography>
                    <Typography ml={2}>
                      {appConstants && Object.keys(appConstants).length
                        ? appConstants.allAppUsers.find((user) => user.userName === timeLog.serviceEngineer).name
                        : ` [${timeLog.serviceEngineer}]`}
                      {` [${timeLog.serviceEngineer}]`}
                    </Typography>
                  </Stack>
                  <Typography>Start time : {timeLog?.startTime ? moment(timeLog.startTime).format("DD MMM HH:mm") : "N.A"}</Typography>
                  <Typography>End time : {timeLog?.endTime ? moment(timeLog.endTime).format("DD MMM HH:mm") : "N.A"}</Typography>
                </Paper>
              ))
            : "No timelogs."}
        </List>
      </Stack>
    </div>
  );
};

export default Timelogs;
