import { Link } from "react-router-dom";
import { Links } from "utils/pageLinkNames.js";
import moment from "moment";
import { Button, IconButton, Tooltip, useTheme } from "@mui/material";
import { ZoomIn as ViewIcon, Edit as EditIcon } from "@mui/icons-material";

const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));

export const barPrimaryXAxis = {
  valueType: "Category",
  interval: 1,
  majorGridLines: { width: 1 },
};
export const barPrimaryYAxis = {
  majorGridLines: { width: 1 },
  majorTickLines: { width: 1 },
  lineStyle: { width: 1 },
  labelStyle: { color: "#000" },
};

export const barChartData = [
  [
    { status: "Assign Engineer", count: 58 },
    { status: "Assigned", count: 157 },
    { status: "On hold", count: 6 },
    { status: "Payment pending", count: 2 },
    { status: "Quote Approved", count: 1 },
    { status: "Request Feedback", count: 155 },
  ],
];

export const barCustomSeries = [
  {
    dataSource: barChartData[0],
    xName: "status",
    yName: "count",
    name: "Total Tickets",
    type: "Column",
    marker: {
      dataLabel: {
        visible: true,
        position: "Top",
        font: { fontWeight: "600", color: "#ffffff" },
      },
    },
  },
];

export const gridTicketEdit = (args) => {
  return (
    <div>
      <Link to={`/${Links.SERVICE_TICKET}/` + args.row.ticketId}>
        <IconButton sx={{ mr: 1 }}>
          <Tooltip title="View">
            <ViewIcon />
          </Tooltip>
        </IconButton>
      </Link>
    </div>
  );
};

const gridUserAction = (args) => {
  return (
    <div>
      <Link to={`/${Links.EDIT_USER}/` + args.row.userId}>
        {/* <Button variant="contained">Edit</Button> */}
        <IconButton sx={{ mr: 1 }}>
          <Tooltip title="View">
            <ViewIcon />
          </Tooltip>
        </IconButton>
      </Link>
      <Link to={{ pathname: `/${Links.EDIT_USER}/` + args.row.userId, states: { enableEditing: true } }}>
        <IconButton sx={{ mr: 1 }}>
          <Tooltip title="Edit">
            <EditIcon />
          </Tooltip>
        </IconButton>
      </Link>
    </div>
  );
};

export const userGrid = [
  {
    field: "name",
    headerName: "Name",
    minWidth: 50,
    flex: 0.3,
  },
  {
    field: "email",
    headerName: "Email",
    minWidth: 50,
    flex: 0.3,
  },
  {
    field: "userMobileNumber",
    headerName: "Mobile Number",
    minWidth: 50,
    flex: 0.3,
  },
  {
    field: "userRole",
    headerName: "Role",
    minWidth: 50,
    flex: 0.3,
  },
  {
    field: "department",
    headerName: "Department",
    minWidth: 50,
    flex: 0.3,
  },
  {
    field: "userName",
    headerName: "User Name",
    minWidth: 30,
    flex: 0.2,
  },
  {
    field: "enabled",
    headerName: "Active",
    minWidth: 20,
    flex: 0.1,
    valueGetter: (params) => (params.row[params.field] ? "Yes" : "No"),
  },
  {
    headerName: "",
    minWidth: 40,
    flex: 0.2,
    renderCell: gridUserAction,
  },
];

export const TimespentByServicetTicketTableAttributes = [
  {
    field: "dateCreated",
    headerName: "Created",
    minWidth: 60,
    flex: 0.3,
    sortComparator: dateComparator,
  },
  {
    field: "ticketId",
    headerName: "Ticket",
    minWidth: 30,
    flex: 0.2,
  },
  {
    field: "maintenanceCode",
    headerName: "Maintenance code",
    minWidth: 60,
    flex: 0.3,
  },
  {
    field: "modelName",
    headerName: "Model",
    minWidth: 60,
    flex: 0.3,
  },
  {
    field: "serialNo",
    headerName: "Serial no",
    minWidth: 60,
    flex: 0.3,
  },
  {
    field: "hoursSpent",
    headerName: "Hours spent",
    minWidth: 60,
    flex: 0.3,
  },
  {
    field: "ticketCategory",
    headerName: "Category",
    minWidth: 60,
    flex: 0.3,
  },
  {
    field: "department",
    headerName: "Department",
    minWidth: 60,
    flex: 0.3,
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 60,
    flex: 0.3,
  },
  {
    field: "edit",
    headerName: "",
    minWidth: 60,
    flex: 0.3,
    sortable: false,
    filterable: false,
    renderCell: gridTicketEdit,
  },
];

export const serviceTicketForMachineTableAttributes = [
  {
    field: "dateCreated",
    headerName: "Created",
    minWidth: 60,
    flex: 0.3,
    sortComparator: dateComparator,
  },
  {
    field: "ticketId",
    headerName: "Ticket Id",
    minWidth: 60,
    flex: 0.3,
  },
  {
    field: "ticketCategory",
    headerName: "Category",
    minWidth: 60,
    flex: 0.3,
  },
  {
    field: "status",
    headerName: "Status",
    minWidth: 60,
    flex: 0.3,
  },
  {
    field: "edit",
    headerName: "",
    minWidth: 60,
    flex: 0.3,
    renderCell: gridTicketEdit,
  },
];

export const ordersGrid = [
  {
    field: "openDateConverted",
    headerText: "Open Date",
    width: "50",
    editType: "dropdownedit",
    textAlign: "Center",
  },

  {
    field: "ticketId",
    headerText: "Service Ticket Id",
    width: "50",
    editType: "dropdownedit",
    textAlign: "Center",
  },
  {
    field: "customerName",
    headerText: "Customer Name",
    width: "80",
    textAlign: "left",
  },
  {
    field: "status",
    headerText: "Status",
    format: "C2",
    textAlign: "Center",
    editType: "numericedit",
    width: "50",
  },
  {
    field: "ticketCategory",
    headerText: "Category",
    width: "50",
    textAlign: "Center",
  },
];

export const contextMenuItems = [
  "AutoFit",
  "AutoFitAll",
  "SortAscending",
  "SortDescending",
  // "Copy",
  // "Edit",
  // "Delete",
  "Save",
  "Cancel",
  // "PdfExport",
  // "ExcelExport",
  // "CsvExport",
  "FirstPage",
  "PrevPage",
  "LastPage",
  "NextPage",
];
