import { useMemo, useEffect, useState } from "react";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { themeSettings } from "./app/theme";

import { Route, Routes } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";

import Layout from "pages/Layout/index";

import Login from "pages/login";
import RequireAuth from "pages/RequireAuth";
// import ToBeImplemented from "pages/ToBeImplemented";

import { isUserLoggedIn, logout } from "features/auth/auth.slice";
import { readAsyncStorageValues } from "features/service/service.slice";

import DashBoard from "pages/DashBoard";

import ServiceDashboard from "pages/ServiceDashboard";
import TimespentByServicetTicket from "pages/Service/TimespentByServicetTicket/TimespentByServicetTicket";

import Reports from "pages/Reports";
import ViewReports from "pages/Reports/ViewReports/viewReports";

import AllServiceTickets from "pages/Service/AllServiceTickets";
import ServiceTickets from "pages/Service/ServiceTickets";
import ServiceTicket from "pages/Service/ServiceTicket";

import Settings from "pages/Settings";

import Machines from "pages/Assets/Machines";
import AddEditMachine from "pages/Assets/Machines/Machine";
import ServiceTicketForMachine from "pages/Assets/Machines/SvcTcktForMachine";

import MachineModels from "pages/Assets/MachineModels";
import MachineModel from "pages/Assets/MachineModels/MachineModel";
import CreateEditMachineModel from "pages/Assets/MachineModels/CreateEditMachineModel";

import Locations from "pages/Locations";

import Users from "pages/Users";
import AddEditUser from "pages/Users/User";

import SpareParts from "pages/Assets/SpareParts";
import AddEditSpares from "pages/Assets/SpareParts/SparePart";

import PlantOverview from "pages/PlantOverview";
import Breakdown from "pages/Breakdown";
import Scheduling from "pages/Scheduling";

import Vendors from "pages/Vendors";
import AddEditVendor from "pages/Vendors/AddEditVendor";

import Templates from "pages/Templates";
import CreateEditTemplate from "pages/Templates/CreateEditTemplate";

import { Links } from "utils/pageLinkNames";
import AddEditLocation from "pages/Locations/AddEditLocations";
import NotFoundPage from "pages/NotFound";
import LoadingIndicator from "pages/loadingPage";
import MaintenanceReport from "pages/Reports/MaintenanceReport";

import TemplateForm from "pages/ReportForms/TemplateForm";
import EquipmentSupportForm from "pages/ReportForms/EquipmentSupportForm";
import SubmitTicketForm from "pages/ReportForms/SubmitTicketForm";

function App() {
  const dispatch = useDispatch();

  const mode = useSelector((state) => state.globalState.mode);
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const [isLoad, setIsLoad] = useState(true);
  const { appConstants, isLoading } = useSelector((state) => state.service);

  useEffect(() => {
    dispatch(isUserLoggedIn());
    if (isAuthenticated) {
      dispatch(readAsyncStorageValues()).then((res) => {
        if (res && res.payload && res.payload.status === 200 && res.payload.data?.resetPassword) {
          toast.warning(
            "You are logged out because you requested a reset password. Please set the new password from mobile app to continue.",
            { autoClose: 10000 }
          );
          dispatch(logout());
        }
      });
    }
    setIsLoad(false);
  }, [isAuthenticated]);

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes>
          <Route path="/" element={<Layout />}>
            {/* public routes */}
            <Route index path="/" element={<Login />} />
            <Route index path={Links.LOGIN} element={<Login />} />

            {/* protected routes */}
            <Route element={<RequireAuth isAuthenticated={isAuthenticated} />}>
              {user?.roles?.includes(appConstants?.allTypes?.USER_ROLES?.ROLE_MAINTENANCE_SUPERVISOR) && (
                <>
                  {/* <Route path={Links.E_PLANT_OVERVIEW} element={<DashBoard path={Links.E_PLANT_OVERVIEW} />} />
                  <Route path={Links.E_BREAKDOWN} element={<DashBoard path={Links.E_BREAKDOWN} />} />
                  <Route path={Links.E_SERVICE_OVERVIEW} element={<DashBoard path={Links.E_SERVICE_OVERVIEW} />} /> */}
                  <Route path={Links.SERVICE_DASHBOARD} element={<ServiceDashboard />} />
                  {/* <Route path={Links.HOURS} element={<ToBeImplemented />} /> */}
                  <Route path={Links.PLANT_OVERVIEW} element={<PlantOverview />} />
                  <Route path={Links.BREAKDOWN} element={<Breakdown />} />
                  <Route path={Links.MAINTENANCE_REPORT} element={<MaintenanceReport />} />
                  <Route path={Links.VIEW_REPORT} element={<ViewReports />} />
                  <Route path={Links.REPORTS} element={<Reports />} />

                  <Route path={Links.SERVICE_TICKETS} element={<ServiceTickets />} />
                  <Route path={Links.ASSIGN_TECHNICIAN_SERVICE_TICKETS} element={<ServiceTickets />} />
                  <Route path={Links.ACTION_REQUIRED_SERVICE_TICKETS} element={<ServiceTickets />} />
                  <Route path={Links.PENDING_SERVICE_TICKETS} element={<ServiceTickets />} />
                  <Route path={Links.PERIODIC_SERVICE_TICKETS} element={<ServiceTickets />} />
                  <Route path={Links.ONE_TIME_SERVICE_TICKETS} element={<ServiceTickets />} />

                  <Route path={Links.SERVICE_TICKET + "/:ticketId"} element={<ServiceTicket />} />
                  <Route path={Links.ASSETS} element={<Machines />} />
                  <Route path={Links.LOCATIONS} element={<Locations />} />
                  <Route path={Links.ADD_LOCATION} element={<AddEditLocation />} />
                  <Route path={Links.EDIT_LOCATION + "/:id"} element={<AddEditLocation />} />
                  <Route path={Links.SCHEDULING} element={<Scheduling />} />
                  <Route path={Links.INVENTORY} element={<SpareParts />} />
                  <Route path={Links.EDIT_SPARE_PART + "/:id"} element={<AddEditSpares />} />
                  <Route path={Links.ADD_SPARE_PART} element={<AddEditSpares />} />
                  {/* <Route path={Links.INSPECTION} element={<ToBeImplemented />} /> */}
                  <Route path={Links.TIME_SPENT_BY_SERVICE_TICKETS} element={<TimespentByServicetTicket />} />
                  <Route path={Links.ADD_MACHINE} element={<AddEditMachine />} />
                  <Route path={Links.EDIT_MACHINE + "/:id"} element={<AddEditMachine />} />
                  <Route path={Links.MACHINE_SERVICE_TICKET + "/:id"} element={<ServiceTicketForMachine />} />
                  <Route path={Links.USER} element={<Users />} />
                  <Route path={Links.ADD_USER} element={<AddEditUser />} />
                  <Route path={Links.EDIT_USER + "/:id"} element={<AddEditUser />} />
                  <Route path={Links.REPORTS + "/:id"} element={<ViewReports />} />
                  <Route path={Links.VENDORS} element={<Vendors />} />
                  <Route path={Links.ADD_VENDOR} element={<AddEditVendor />} />
                  <Route path={Links.EDIT_VENDOR + "/:id"} element={<AddEditVendor />} />
                  {/* <Route path={Links.CREATE_SERVICE_TICKET} element={<CreateTicket />} /> */}
                  <Route path={Links.CREATE_SERVICE_TICKET} element={<ServiceTicket />} />
                  <Route path={Links.UPDATE_SERVICE_TICKET + "/:ticketId"} element={<ServiceTicket />} />
                  <Route path={Links.TEMPLATES} element={<Templates />} />
                  <Route path={Links.CREATE_TEMPLATE} element={<CreateEditTemplate />} />
                  <Route path={Links.EDIT_TEMPLATE + "/:id"} element={<CreateEditTemplate />} />
                  <Route path={Links.MACHINE_MODELS} element={<MachineModels />} />
                  <Route path={Links.MACHINE_MODEL} element={<MachineModel />} />
                  <Route path={Links.MACHINE_MODEL + "/:id"} element={<MachineModel />} />
                  <Route path={Links.EDIT_MACHINE_MODELS + "/:id"} element={<CreateEditMachineModel />} />
                  <Route path={Links.SETTINGS} element={<Settings />} />
                  <Route path={Links.SERVICE_TICKET + "/:ticketId/" + Links.SUBMIT_TICKET_FORM} element={<SubmitTicketForm />} />
                </>
              )}
              {user?.roles?.includes(appConstants?.allTypes?.USER_ROLES?.ROLE_FINANCE) && (
                <>
                  <Route path={Links.ASSETS} element={<Machines />} />
                  <Route path={Links.ADD_MACHINE} element={<AddEditMachine />} />
                  <Route path={Links.EDIT_MACHINE + "/:id"} element={<AddEditMachine />} />
                </>
              )}
              {user?.roles?.includes(appConstants?.allTypes?.USER_ROLES?.ROLE_STORES) && (
                <>
                  <Route path={Links.INVENTORY} element={<SpareParts />} />
                  <Route path={Links.EDIT_SPARE_PART + "/:id"} element={<AddEditSpares />} />
                  <Route path={Links.ADD_SPARE_PART} element={<AddEditSpares />} />
                  <Route path={Links.SPARES_RECOMMENDED} element={<ServiceTickets />} />
                  <Route path={Links.SPARES_REQUIRED} element={<ServiceTickets />} />
                  <Route path={Links.ASSIGNED} element={<ServiceTickets />} />
                  <Route path={Links.SERVICE_TICKET + "/:ticketId"} element={<ServiceTicket />} />
                  <Route path={Links.UPDATE_SERVICE_TICKET + "/:ticketId"} element={<ServiceTicket />} />
                  <Route path={Links.SERVICE_TICKET + "/:ticketId/" + Links.SUBMIT_TICKET_FORM} element={<SubmitTicketForm />} />
                </>
              )}
              {user?.roles?.includes(appConstants?.allTypes?.USER_ROLES?.ROLE_IT) && (
                <>
                  <Route path={Links.USER} element={<Users />} />
                  <Route path={Links.ADD_USER} element={<AddEditUser />} />
                  <Route path={Links.EDIT_USER + "/:id"} element={<AddEditUser />} />
                  <Route path={Links.SERVICE_TICKET + "/:ticketId/" + Links.SUBMIT_TICKET_FORM} element={<SubmitTicketForm />} />
                </>
              )}
              {user?.roles?.includes(appConstants?.allTypes?.USER_ROLES?.ROLE_SERVICE_TECHNICIAN) && (
                <>
                  <Route path={Links.ALL_SERVICE_TICKETS} element={<AllServiceTickets />} />
                  <Route path={Links.SERVICE_TICKET + "/:ticketId"} element={<ServiceTicket />} />
                  <Route path={Links.UPDATE_SERVICE_TICKET + "/:ticketId"} element={<ServiceTicket />} />
                  <Route path={Links.SERVICE_TICKET + "/:ticketId/" + Links.TEMPLATE_FORM} element={<TemplateForm />} />
                  <Route path={Links.SERVICE_TICKET + "/:ticketId/" + Links.SUBMIT_TICKET_FORM} element={<SubmitTicketForm />} />
                  <Route path={Links.REPORTS + "/:id"} element={<ViewReports />} />
                  <Route path={Links.CREATE_SERVICE_TICKET} element={<ServiceTicket />} />
                </>
              )}
              <Route path="*" element={isLoading || isLoad ? <LoadingIndicator /> : <NotFoundPage />} />
            </Route>
          </Route>
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
