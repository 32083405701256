import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Paper, Typography, useTheme } from "@mui/material";
import { ComposedChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, Label, Cell, ResponsiveContainer } from "recharts";

import { getOutOfServiceMachinesByCategory } from "features/service/service.slice";

const ChartOutOfServiceMachinesByTicketCategory = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { outOfServiceMachinesByCategory, isLoading } = useSelector((state) => state.service);
  const { mode } = useSelector((state) => state.globalState);

  const fetchData = () => {
    dispatch(getOutOfServiceMachinesByCategory());
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Paper elevation={8} sx={{ padding: 2 }}>
      {/* <Typography variant="h5" align="center" sx={{ mb: 1, mt: 1 }}>
        Out of Service Critical Machines by Ticket Category
      </Typography> */}
      <ResponsiveContainer width="99%" aspect={3}>
        <ComposedChart data={outOfServiceMachinesByCategory} margin={{ top: 20, right: 20, bottom: 20, left: 20 }} cursor="pointer">
          <CartesianGrid stroke="#d6d6d6" />
          <XAxis dataKey="ticketCategory" stroke={mode === "dark" ? "#fff" : "#000"} />
          <YAxis allowDecimals={false} stroke={mode === "dark" ? "#fff" : "#000"}>
            <Label angle={270} position="insideLeft" offset={10} value="Count" />
          </YAxis>
          <Tooltip />
          <Legend />
          <Bar dataKey="count" name="Machines Count" barSize={50} label={{ position: "top" }} isAnimationActive={false}>
            {outOfServiceMachinesByCategory?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill="#7d4d1e" />
            ))}
          </Bar>
        </ComposedChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default ChartOutOfServiceMachinesByTicketCategory;
