import api from "app/api";
import multiInstance from "app/multiPartApi";

const updatingOrAddingServiceProblemCategory = async (category) => {
  const response = await api.post("/serviceapi/updatingOrAddingServiceProblemCategory", category);
  return response.data;
};

const Settings = {
  updatingOrAddingServiceProblemCategory,
};

export default Settings;
