import React from 'react';

const NotFoundPage = () => {
  return (
    <div
      style={{
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
        margin: "100px",
        flexDirection:"column"
      }}
    >
      <h1>404 - Page Not Found</h1>
      <p>The page you are looking for does not exist or cannot be accessed.</p>
    </div>
  );
};

export default NotFoundPage;
