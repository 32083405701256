import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useLocation, Link } from "react-router-dom";
import { Paper, Box, Stack, Typography, Button, Tooltip, IconButton, useTheme, CircularProgress } from "@mui/material";
import { ZoomIn as ViewIcon } from "@mui/icons-material";
import moment from "moment";

import Header from "component/Header";
import DataGridTable from "component/DataGridTable";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { getAllUsers } from "features/appUsers/appUsers.slice";
import { readAsyncStorageValues, getServiceTicketsByStatusList, getServiceTicketsByUserId } from "features/service/service.slice";
import AddEditUser from "../AddEditUser";
import { Links } from "utils/pageLinkNames.js";

const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return "";
  // Remove non-numeric characters
  const digitsOnly = phoneNumber.replace(/\D/g, "");

  // Assuming the country code is the first two digits
  const countryCode = digitsOnly.slice(0, 2);
  const localNumber = digitsOnly.slice(2);

  if (localNumber.length === 10) {
    return `+${countryCode} ${localNumber.slice(0, 3)} ${localNumber.slice(3, 6)} ${localNumber.slice(6, 10)}`;
  } else if (localNumber.length === 8) {
    return `+${countryCode} ${localNumber.slice(0, 4)} ${localNumber.slice(4, 8)}`;
  } else {
    return phoneNumber;
  }
};

const User = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { state } = useLocation();
  const theme = useTheme();

  const [initialFormData, setInitialFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    department: "",
    userRole: "",
    userName: "",
    password: "",
    confirmPassword: "",
  });
  const [enableEditing, setEnableEditing] = useState(false);
  const [serviceTickets, setServiceTickets] = useState([]);
  const [selectedStartDate, setSelectedStartDate] = useState(null);

  const { appConstants, serviceTicketsByUserId, serviceTicketsByStatusList, isLoading } = useSelector((state) => state.service);
  const { allUsers } = useSelector((state) => state.appUsers);
  const { user } = useSelector((state) => state.auth);

  useEffect(() => {
    if (id && serviceTicketsByUserId) setServiceTickets(serviceTicketsByUserId.data);
  }, [serviceTicketsByUserId]);

  useEffect(() => {
    dispatch(readAsyncStorageValues());
    dispatch(getAllUsers());
    if (!id || state?.enableEditing) {
      setEnableEditing(true);
    }
  }, [dispatch, initialFormData.name]);

  useEffect(() => {
    const payload = {
      userId: id,
    };
    if (selectedStartDate) payload.timeStamp = JSON.stringify(moment(selectedStartDate).format("YYYY-MM-DD"));
    if (id) dispatch(getServiceTicketsByUserId(payload));
  }, [dispatch, selectedStartDate]);

  useEffect(() => {
    if (id && allUsers.length && Object.keys(appConstants).length) {
      const info = allUsers.find((user) => user.userId == id);
      setInitialFormData({ ...info, mobileNumber: info.userMobileNumber || "", password: "", confirmPassword: "" });
    }
  }, [appConstants, allUsers]);

  const dateComparator = (v1, v2) => moment(v1).diff(moment(v2));
  const gridTicketEdit = (args) => {
    return (
      <div>
        {user.roles[0] === appConstants?.allTypes?.USER_ROLES.ROLE_MAINTENANCE_SUPERVISOR ? (
          <Link to={`/${Links.SERVICE_TICKET}/` + args.row.ticketId}>
            <IconButton sx={{ mr: 1 }}>
              <Tooltip title="View">
                <ViewIcon sx={{ color: theme.palette.primary[50] }} />
              </Tooltip>
            </IconButton>
          </Link>
        ) : null}
      </div>
    );
  };
  const serviceTicketForMachineTableAttributes = [
    {
      field: "dateCreated",
      headerName: "Created",
      minWidth: 60,
      flex: 0.3,
      sortComparator: dateComparator,
    },
    {
      field: "ticketId",
      headerName: "Ticket Id",
      minWidth: 60,
      flex: 0.3,
    },
    {
      field: "ticketCategory",
      headerName: "Category",
      minWidth: 60,
      flex: 0.3,
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 60,
      flex: 0.3,
    },
    {
      field: "edit",
      headerName: "",
      minWidth: 60,
      flex: 0.3,
      sortable: false,
      filterable: false,
      renderCell: gridTicketEdit,
    },
  ];

  return (
    <div>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header titles={["User", id ? `${initialFormData.name}  [${initialFormData.userName}]` : ""]} />
      </Stack>
      <Box sx={{ mx: 2 }}>
        {enableEditing ? (
          <Paper sx={{ borderRadius: 2, mb: 1.5, p: 2 }}>
            <AddEditUser initialFormData={initialFormData} setEnableEditing={setEnableEditing} />
          </Paper>
        ) : (
          <Paper sx={{ borderRadius: 2, mb: 1.5, p: 3 }}>
            <Stack justifyContent={"space-between"} direction={"row"} flexWrap={"wrap"}>
              <Stack rowGap={1}>
                <Typography>Name : {initialFormData.name}</Typography>
                <Typography>User name : {initialFormData.userName}</Typography>
                <Typography>Role : {initialFormData.userRole}</Typography>
                <Typography>Active : {initialFormData.enabled ? "Yes" : "No"}</Typography>
              </Stack>
              <Stack rowGap={1}>
                <Typography>Email : {initialFormData.email}</Typography>
                <Typography>Mobile number : {formatPhoneNumber(initialFormData.mobileNumber)}</Typography>
                <Typography>Department : {initialFormData.department}</Typography>
              </Stack>
              <Stack rowGap={1}>
                <Tooltip title="Edit asset">
                  <Button onClick={() => setEnableEditing(true)} variant="contained">
                    Edit
                  </Button>
                </Tooltip>
              </Stack>
            </Stack>
          </Paper>
        )}
        {id ? (
          <Paper sx={{ p: 2 }}>
            <Stack direction={"row"} alignItems={"center"} mb={2}>
              <Typography variant="h5" fontWeight={600}>
                Tickets created after :
              </Typography>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  maxDate={moment(new Date())}
                  label="Start date"
                  format={"DD MMM YYYY"}
                  value={selectedStartDate ? moment(selectedStartDate) : null}
                  onChange={(newValue) => setSelectedStartDate(newValue)}
                  sx={{ minWidth: 200, maxWidth: 600, ml: 2 }}
                />
              </LocalizationProvider>
            </Stack>
            {serviceTicketsByUserId.isLoading ? (
              <CircularProgress size={30} color="secondary" />
            ) : (
              <DataGridTable rowId={"ticketId"} data={serviceTickets} columnAttributes={serviceTicketForMachineTableAttributes} />
            )}
          </Paper>
        ) : null}
      </Box>
    </div>
  );
};

export default User;
