import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { useLocation, useParams } from "react-router-dom";
import { getMachines, getMachineDepartments, readSpareParts, updateSpareParts } from "features/service/service.slice";
import {
  Card,
  Stack,
  Paper,
  Typography,
  List,
  Button,
  Dialog,
  Tooltip,
  DialogContent,
  DialogActions,
  Box,
  DialogTitle,
  IconButton,
} from "@mui/material";
import { QrCode as QRCodeIcon, Download as DownloadIcon, FileUpload as FileUploadIcon, Close as CloseIcon } from "@mui/icons-material";
import Header from "component/Header";
import AddEditSpare from "pages/Assets/SpareParts/AddEditSpare";
import AuthorizedImage from "component/AuthorizedImage";
import moment from "moment/moment";
import ViewImageVideoModal from "component/ViewImageVideoModal";
import {
  ZoomIn as ZoomInIcon,
  ZoomOut as ZoomOutIcon,
  ChevronLeft as LeftArrowIcon,
  ChevronRight as RightArrowIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import ConfirmDeleteModal from "component/ConfirmDeleteModal";

const SparePart = (props) => {
  const dispatch = useDispatch();
  const { state } = useLocation();
  const { id } = useParams();
  const formDataToBe = new FormData();
  const [initialFormData, setInitialFormData] = useState({
    partCode: "",
    partImages: [],
  });
  const [enableEditing, setEnableEditing] = useState(false);
  const { isLoading, machineDepartments, spareParts } = useSelector((state) => state.service);
  const { appConstants } = useSelector((state) => state.service);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [showDialogView, setShowDialogView] = useState(false);
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  useEffect(() => {
    dispatch(getMachineDepartments());
    dispatch(readSpareParts());
    if (!id || state?.enableEditing) setEnableEditing(true);
  }, [dispatch, initialFormData.partCode]);
  useEffect(() => {
    if (id && spareParts.length && machineDepartments.length) {
      const sparePartInfo = spareParts.find((spare) => spare.id == id);
      const info = {
        partInfo: sparePartInfo?.info?.partInfo || null,
        partImages: sparePartInfo?.info?.partImages || null,
        expiryOrCalibrationDate: sparePartInfo?.info?.expiryOrCalibrationDate || null,
      };
      setInitialFormData({ ...sparePartInfo, ...info, ...sparePartInfo.location });
    }
  }, [spareParts, machineDepartments]);

  const handleFileSelect = (event) => {
    setSelectedFiles([...event.target.files]);
    setShowDialog(true);
  };
  const handleViewFile = (partImageIndex) => {
    setCurrentIndex(partImageIndex);
    setShowDialogView(true);
  };
  const handleCloseDialog = () => {
    setShowDialog(false);
    setSelectedFiles([]);
    // fileInputRef.current.value = null;
  };
  const handleCloseDialogView = () => {
    setCurrentIndex(null);
    setShowDialogView(false);
  };

  const submitData = (data = initialFormData) => {
    // const updateObj = { manufacturer: initialFormData.manufacturer, modelName: initialFormData.model, modelId: id };
    data.modelPartId = parseInt(data.id);
    formDataToBe.append("spareDetails", JSON.stringify(data));
    if (selectedFiles.length) {
      selectedFiles.forEach((item, index) => {
        formDataToBe.append("partImages", item);
      });

      setShowDialog(false);
      setSelectedFiles([]);
    }

    dispatch(updateSpareParts(formDataToBe)).then((res) => {
      if (res && res.error) return toast.error("Something went wrong!");
      if (res && res.payload.data && res.payload.status === 200) {
        dispatch(readSpareParts()).then((res) => {
          toast.success("Spare part Image(s) updated successfully!", { autoClose: 1500 });
        });
      } else return toast.error("Something went wrong!");
    });
  };
  const goToPrevSlide = () => setCurrentIndex(currentIndex > 0 ? currentIndex - 1 : 0);
  const goToNextSlide = () => setCurrentIndex(currentIndex === selectedFiles.length - 1 ? 0 : currentIndex + 1);

  const handleQRCodeClick = async () => {
    const data = `
      partCode:${initialFormData.partCode}
      maker:${initialFormData.maker}
      description:${initialFormData.description}`;

    const qrCodeApiUrl = `https://api.qrserver.com/v1/create-qr-code/?size=400x400&data=${encodeURIComponent(data)}`;

    try {
      const response = await fetch(qrCodeApiUrl);
      if (!response.ok) {
        console.error("Failed to generate QR code.");
        return;
      }

      const blob = await response.blob();
      const blobUrl = URL.createObjectURL(blob);

      const downloadLink = document.createElement("a");
      downloadLink.href = blobUrl;
      const custName = appConstants?.customers
        ?.filter((customer) => customer.id == 5284)[0]
        ?.customerName.split(" ")
        .join("_");
      downloadLink.download = `${custName}_qr_code_sparePart_${initialFormData.partCode}.png`;

      downloadLink.click();

      URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };
  const renderLabel = (label, value) => (
    <Stack direction={"row"}>
      <Typography sx={{ fontWeight: "bold" }}>{label}</Typography>
      <Typography flexWrap={"wrap"} sx={{ ml: 0.5 }}>
        <span style={{ fontWeight: "bold", flexWrap: "wrap" }}>: </span> {value}
      </Typography>
    </Stack>
  );

  const onClickDeleteArtifact = (artifact) => {
    const { partImages } = initialFormData?.info;
    if (partImages && initialFormData?.info) {
      setSelectedFile(artifact);
      setShowConfirmDeleteModal(true);
    }
  };
  const onConfirmDeleteArtifact = () => {
    const { partImages } = initialFormData?.info;
    const newFormData = {
      ...initialFormData,
      info: { ...initialFormData?.info, partImages: partImages.filter((item) => item !== selectedFile) || [] },
    };
    setSelectedFile(null);
    setInitialFormData(newFormData);
    closeConfirmDeleteModal();
    submitData(newFormData);
  };
  const closeConfirmDeleteModal = () => setShowConfirmDeleteModal(false);
  return (
    <>
      <Stack sx={{ ml: 2, mt: 2, mb: 1 }}>
        <Header titles={["Spare part", id ? initialFormData.partCode : ""]} />
      </Stack>
      <div>
        <ConfirmDeleteModal
          showConfirmDeleteModal={showConfirmDeleteModal}
          closeConfirmDeleteModal={closeConfirmDeleteModal}
          modalTextContent={` Please press "Confirm Delete" to delete the selected item.`}
          onConfirmDelete={onConfirmDeleteArtifact}
        />
      </div>
      <div>
        <Dialog open={showDialog} onClose={handleCloseDialog}>
          <DialogContent>
            {/* File preview */}
            {showDialog && selectedFiles.length ? (
              selectedFiles[currentIndex].type.includes("image") ? (
                <img
                  src={URL.createObjectURL(selectedFiles[currentIndex])}
                  alt="Selected File"
                  style={{ maxWidth: "100%", maxHeight: "500px", minHeight: "200px" }}
                />
              ) : selectedFiles[currentIndex].type.includes("pdf") ? (
                <embed src={URL.createObjectURL(selectedFiles[currentIndex])} width="800" height="700" />
              ) : (
                <span>Selected File is Incorrect</span>
              )
            ) : null}
          </DialogContent>

          <Stack direction={"row"} alignItems={"center"} justifyContent="center" sx={{ mt: 2, mb: 2, mx: 3, height: "100%" }}>
            <Button onClick={goToPrevSlide} sx={{ mr: 7 }}>
              <LeftArrowIcon />
              Prev
            </Button>
            <Button onClick={handleCloseDialog}>Cancel</Button>
            {selectedFiles.length ? (
              <Button variant="contained" onClick={() => submitData()} sx={{ ml: 2, mr: 7 }}>
                Upload
              </Button>
            ) : null}
            <Button onClick={goToNextSlide}>
              Next <RightArrowIcon />
            </Button>
          </Stack>
        </Dialog>
      </div>
      <div>
        {showDialogView && (
          <ViewImageVideoModal
            handleCloseDialogView={handleCloseDialogView}
            showDialogView={showDialogView}
            data={initialFormData?.info?.partImages.length ? initialFormData?.info?.partImages : []}
            selectedFileIndex={currentIndex}
          />
        )}
      </div>
      <Box sx={{ mx: 2 }}>
        {enableEditing ? (
          <Paper sx={{ borderRadius: 2, mb: 1.5, p: 2 }}>
            <AddEditSpare initialFormData={initialFormData} setEnableEditing={setEnableEditing} />
          </Paper>
        ) : (
          <Paper sx={{ borderRadius: 2, mb: 1.5, p: 2 }}>
            <Stack direction={"row"}>
              <Stack mr={3}>
                <Box sx={{}}>
                  {initialFormData.image ? <AuthorizedImage path={initialFormData.image} height={200} width={200} /> : null}
                </Box>
              </Stack>
              <Stack sx={{ width: "90%" }}>
                <Stack justifyContent={"space-between"} ml={3} direction={"row"} flexWrap={"wrap"}>
                  <Stack rowGap={1}>
                    {renderLabel("Maker ", initialFormData.maker)}
                    {renderLabel("Part code ", initialFormData.partCode)}
                    {renderLabel("Unit ", initialFormData.unit)}
                    <Stack direction={"row"}>
                      <Typography sx={{ fontWeight: "bold" }}>Description</Typography>
                      <Typography flexWrap={"wrap"} sx={{ ml: 0.5, width: 300 }}>
                        <span style={{ fontWeight: "bold", flexWrap: "wrap" }}>: </span> {initialFormData.description}
                      </Typography>
                    </Stack>
                    {renderLabel(
                      "Expiry or calibration date ",
                      initialFormData.expiryOrCalibrationDate ? moment(initialFormData.expiryOrCalibrationDate).format("DD MMM YYYY") : "-"
                    )}
                  </Stack>
                  <Stack rowGap={1}>
                    {renderLabel("Reorder level ", initialFormData.reorderLevel || "-")}
                    {renderLabel("Reorder quantity ", initialFormData.reorderQuantity)}
                    {renderLabel("Current stock ", initialFormData.currentStock)}
                    {renderLabel("Issued ", initialFormData.issued)}
                    {renderLabel("Returned ", initialFormData.returned)}
                  </Stack>
                  <Stack rowGap={1}>
                    {renderLabel("Storage Room ", initialFormData.storageRoom)}
                    {renderLabel("Rack ", initialFormData.rack)}
                    {renderLabel("Level", initialFormData.level)}
                    {renderLabel("Bin", initialFormData.bin)}
                  </Stack>
                  <Stack rowGap={1}>
                    <Tooltip title="Edit spare part" placement="left">
                      <Button variant="contained" onClick={() => setEnableEditing(true)}>
                        Edit
                      </Button>
                    </Tooltip>
                    <Tooltip title="Download QR" placement="left">
                      <Button variant="contained" endIcon={<DownloadIcon />} onClick={handleQRCodeClick}>
                        <QRCodeIcon />
                      </Button>
                    </Tooltip>
                  </Stack>
                </Stack>
                <Stack rowGap={1} ml={3} mt={2}>
                  {renderLabel("Part info ", initialFormData.partInfo || "-")}
                </Stack>
              </Stack>
            </Stack>
          </Paper>
        )}
      </Box>
      {id && (
        <Box sx={{ mx: 2 }}>
          <Paper sx={{ p: 2 }}>
            <Stack direction={"row"} justifyContent={"space-between"}>
              <Typography variant="h5" fontWeight={600} mb={1}>
                Spare Part Images
              </Typography>
              <input type="file" accept="image/*" style={{ display: "none" }} onChange={handleFileSelect} id="fileInputImages" multiple />
              <Tooltip title="Add file" placement="left">
                <Button
                  sx={{ height: "self" }}
                  variant="contained"
                  color="primary"
                  disabled={enableEditing}
                  onClick={() => {
                    setCurrentIndex(0);
                    document.getElementById("fileInputImages").click();
                  }}
                >
                  Add
                </Button>
              </Tooltip>
            </Stack>

            {initialFormData?.info && initialFormData?.info?.partImages ? (
              <Stack direction="row" spacing={2} sx={{ overflow: "auto", pb: 1 }}>
                {initialFormData.info.partImages.map((partImage, index) => (
                  <Paper sx={{ borderRadius: 2, p: 2, position: "relative", width: 200, height: 200, boxSizing: "border-box" }}>
                    <IconButton
                      disabled={enableEditing}
                      color="error"
                      sx={{
                        position: "absolute",
                        top: -9,
                        right: -9,
                        minWidth: 0,
                        padding: 0,
                        height: 40,
                        width: 40,
                      }}
                      onClick={() => onClickDeleteArtifact(partImage)}
                      aria-label="Delete"
                    >
                      <DeleteIcon fontSize="medium" />
                    </IconButton>
                    <Stack rowGap={1} onClick={() => handleViewFile(index)}>
                      <AuthorizedImage path={partImage} width={160} height={165} />
                    </Stack>
                  </Paper>
                ))}
              </Stack>
            ) : null}
          </Paper>
        </Box>
      )}
    </>
  );
};

export default SparePart;
