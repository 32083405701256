const config = {
  appName: "HALMAN",
  // URI: "http://localhost:8080/techapps",
  // URI: "http://192.168.29.19:8080/techapps/",
  URI: "https://api.teraobjects.com/halman/",
  // URI: "https://api.techenablesme.com/mainman/",
  GOOGLE_TRANSLATE_API_URL: "https://translation.googleapis.com/language/translate/v2",
  GOOGLE_CLOUD_API_KEY: "AIzaSyAMYoBuJMw3IhRX7m26AHqmMA8Y0NUgDsc",
};

export default config;
