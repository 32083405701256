import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Paper, Stack, useTheme, Autocomplete, TextField, Card } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ComposedChart, Label, ResponsiveContainer } from "recharts";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Links } from "utils/pageLinkNames";

import { getServiceTicketsStatusByTechId, readAsyncStorageValues } from "features/service/service.slice";

// Define the TechnicianReports component
const TechnicianReports = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { serviceTicketsStatusByTech, appConstants } = useSelector((state) => state.service);
  const [ticketsCount, setTickets] = useState([]);

  const [technician, setTechnician] = useState(null);
  const [startDate, setStartDate] = useState(moment().subtract(3, "months").toDate());
  const [endDate, setEndDate] = useState(moment().toDate());
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [showEndDatePicker, setShowEndDatePicker] = useState(false);

  const { mode } = useSelector((state) => state.globalState);

  useEffect(() => {
    const payload = { startDate: moment(startDate).format("YYYY-MM-DD"), endDate: moment(endDate).format("YYYY-MM-DD") };
    if (technician && technician?.userId) {
      payload.userId = technician?.userId;
    }
    dispatch(getServiceTicketsStatusByTechId(payload));
  }, [technician, startDate, endDate]);

  useEffect(() => {
    if (appConstants?.allTypes?.SERVICE_TICKET_STATUS) {
      const { ASSIGNED, COMPLETED, SUPERVISOR_APPROVED } = appConstants?.allTypes?.SERVICE_TICKET_STATUS;
      const svcTicketStatusList = [ASSIGNED, COMPLETED, SUPERVISOR_APPROVED];
      const dataCount = svcTicketStatusList.map((status) => {
        const itemCount = serviceTicketsStatusByTech.data.find((item) => item.status === status);
        return {
          count: itemCount?.count || 0,
          status,
        };
      });
      setTickets(dataCount);
    }
  }, [serviceTicketsStatusByTech]);

  const handleStartDateChange = (date) => {
    setStartDate(date);
    setShowDatePicker(false);
  };

  const handleEndDateChange = (date) => {
    setEndDate(date);
    setShowEndDatePicker(false);
  };

  // Generate the list of technicians
  const technicianList = appConstants.appUsers.filter(
    (user) =>
      user.userRole === appConstants.allTypes.USER_ROLES.ROLE_SERVICE_TECHNICIAN ||
      user.userRole === appConstants.allTypes.USER_ROLES.ROLE_MAINTENANCE_SUPERVISOR
  );

  return (
    <Paper elevation={8} sx={{ padding: 1, height: 550, overflow: "hidden" }}>
      <Typography variant="h5" align="center" sx={{ mb: 1, mt: 1 }}>
        Tickets by Technician
      </Typography>

      <div style={{ display: "flex", justifyContent: "center" }}>
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          onChange={(e, newValue) => setTechnician(newValue)}
          value={technician || null}
          options={technicianList}
          sx={{ minWidth: 400, maxWidth: 800 }}
          isOptionEqualToValue={(option, value) => option.nameEmpno == value.nameEmpno}
          getOptionLabel={(option) => option.nameEmpno}
          renderInput={(params) => <TextField {...params} label="All Technician(s)" />}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 10,
          justifyContent: "center",
          alignItems: "center",
          alignContent: "center",
        }}
      >
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={moment(startDate)}
            onChange={handleStartDateChange}
            onClose={() => setShowDatePicker(false)}
            label="Start Date"
            format={"DD MMM YYYY"}
            variant="standard"
            disableFuture // Disable future dates
            sx={{ width: 200, paddingRight: 5 }}
          />
        </LocalizationProvider>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <DatePicker
            value={moment(endDate)}
            onChange={handleEndDateChange}
            onClose={() => setShowEndDatePicker(false)}
            label="End Date"
            format={"DD MMM YYYY"}
            variant="standard"
            disableFuture // Disable future dates
            sx={{ width: 150 }}
          />
        </LocalizationProvider>
      </div>

      <div style={{ height: 420 }}>
        <ResponsiveContainer width="99%" height="90%">
          <ComposedChart
            data={ticketsCount}
            margin={{
              top: 20,
              right: 10,
              bottom: 10,
              left: 10,
            }}
            cursor="pointer"
          >
            <CartesianGrid stroke="#d6d6d6" />
            <XAxis dataKey="status" stroke={mode === "dark" ? "#fff" : "#000"} />
            <YAxis allowDecimals={false} stroke={mode === "dark" ? "#fff" : "#000"}>
              <Label
                style={{
                  textAnchor: "middle",
                }}
                angle={270}
                value={"Count"}
                stroke={mode === "dark" ? "#fff" : "#7F7F7F"}
              />
            </YAxis>
            <Tooltip />
            <Legend />
            <Bar
              dataKey="count"
              name="Tickets"
              barSize={35}
              fill={theme.palette.secondary[200]}
              label={{ position: "top" }}
              isAnimationActive={false}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </Paper>
  );
};

// Export the TechnicianReports component
export default TechnicianReports;
