import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ComposedChart, Line, XAxis, YAxis, CartesianGrid, Tooltip } from "recharts";
import { Button, Typography, Paper, Stack, useTheme, Box } from "@mui/material";
import { getMonthlyBreakdownHoursPercent, getWeeklyBreakdownHoursPercent } from "features/service/service.slice";

const Breakdown = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const { monthlyBreakdownHoursPercent, weeklyBreakdownHoursPercent } = useSelector((state) => state.service);
  const [weeklyBreakdown, setWeeklyBreakdown] = useState([]);
  const [monthlyBreakdown, setMonthlyBreakdown] = useState([]);
  const [weeklyAnalysis, setWeeklyAnalysis] = useState(true);
  const [selectedNumOfWeeks, setSelectedNumOfWeeks] = useState(6);
  const [selectedNumOfYears, setSelectedNumOfYears] = useState(1);
  const { mode } = useSelector((state) => state.globalState);

  useEffect(() => {
    if (weeklyBreakdownHoursPercent.data) {
      const listLength = weeklyBreakdownHoursPercent.data.length;
      setWeeklyBreakdown([...weeklyBreakdownHoursPercent.data].splice(listLength - 6 > 0 ? listLength - 6 : 0, listLength));
    }
  }, [weeklyBreakdownHoursPercent]);
  useEffect(() => {
    if (monthlyBreakdownHoursPercent.data) {
      const listLength = monthlyBreakdownHoursPercent.data.length;
      setMonthlyBreakdown([...monthlyBreakdownHoursPercent.data].splice(listLength - 12 > 0 ? listLength - 12 : 0, listLength));
    }
  }, [monthlyBreakdownHoursPercent]);
  useEffect(() => {
    dispatch(getMonthlyBreakdownHoursPercent());
    dispatch(getWeeklyBreakdownHoursPercent());
  }, [dispatch]);

  const downTimeFormat = (time) => {
    const formattedTime = time.split(":");
    return `${formattedTime[0]} hours ${formattedTime[1]} minutes`;
  };
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <Stack p={1}>
          <div className="custom-tooltip">
            <Typography variant="h6">{`${label}`}</Typography>
            <Typography variant="h6">{`Downtime : ${downTimeFormat(payload[0].payload.hours_minutes)} `}</Typography>
            <Typography>{`Hours in Percentage : ${payload[0].payload.downtime_percent}%`}</Typography>
          </div>
        </Stack>
      );
    }
    return null;
  };

  const minValue = Math.min(...weeklyBreakdown.map((data) => data.downtime_percent));
  const maxValue = Math.max(...weeklyBreakdown.map((data) => data.downtime_percent));
  const numberOfYears = { 1: 12, 2: 24, 3: 36 };
  return (
    <Stack alignItems={"center"} sx={{ mt: 5 }}>
      <Box width={1120}>
        <Paper elevation={8} sx={{ padding: 1 }}>
          <Typography variant="h4" align="center" sx={{ mb: 2, mt: 2 }}>
            Critical Machine Breakdown Time (%)
          </Typography>
          <Stack alignItems={"center"} direction={"row"} spacing={2} sx={{ padding: 2, justifyContent: "center" }}>
            <Button
              // fullWidth
              size="small"
              variant={weeklyAnalysis ? "contained" : "outlined"}
              onClick={() => setWeeklyAnalysis(true)}
              sx={[{ fontWeight: "bold", width: 200 }, weeklyAnalysis ? {} : { backgroundColor: "#fff" }]}
            >
              Weekly
            </Button>
            <Button
              // fullWidth
              size="small"
              variant={!weeklyAnalysis ? "contained" : "outlined"}
              onClick={() => setWeeklyAnalysis(false)}
              sx={[{ fontWeight: "bold", width: 200 }, !weeklyAnalysis ? {} : { backgroundColor: "#fff" }]}
            >
              Monthly
            </Button>
          </Stack>
          <Stack alignItems={"center"} m={1}>
            {weeklyAnalysis ? (
              <div>
                <Stack direction={"row"} gap={2} alignItems={"center"}>
                  {[24, 12, 6].map((numOfWeeks) => (
                    <Button
                      variant={selectedNumOfWeeks === numOfWeeks ? "contained" : "outlined"}
                      sx={[{}, selectedNumOfWeeks === numOfWeeks ? {} : { backgroundColor: "#fff" }]}
                      onClick={() => {
                        const listLength = weeklyBreakdownHoursPercent.data.length;
                        const numOfWeeksLimit =
                          listLength - numOfWeeks > 0 && listLength - numOfWeeks < listLength ? listLength - numOfWeeks : 0;
                        const filteredData = [...weeklyBreakdownHoursPercent.data].splice(numOfWeeksLimit, listLength);
                        setWeeklyBreakdown(filteredData);
                        setSelectedNumOfWeeks(numOfWeeks);
                      }}
                    >
                      {numOfWeeks} week
                    </Button>
                  ))}
                </Stack>
              </div>
            ) : (
              <div>
                <Stack direction={"row"} gap={2} alignItems={"center"}>
                  {Object.keys(numberOfYears)
                    .reverse()
                    .map((numOfYears) => (
                      <Button
                        variant={selectedNumOfYears === parseInt(numOfYears) ? "contained" : "outlined"}
                        sx={[{}, selectedNumOfYears === parseInt(numOfYears) ? {} : { backgroundColor: "#fff" }]}
                        onClick={() => {
                          const listLength = monthlyBreakdownHoursPercent.data.length;
                          const numOYearsLimit =
                            listLength - numberOfYears[numOfYears] > 0 && listLength - numberOfYears[numOfYears] < listLength
                              ? listLength - numberOfYears[numOfYears]
                              : 0;
                          const filteredData = [...monthlyBreakdownHoursPercent.data].splice(numOYearsLimit, listLength);
                          setMonthlyBreakdown(filteredData);
                          setSelectedNumOfYears(parseInt(numOfYears));
                        }}
                      >
                        {numOfYears} Year
                      </Button>
                    ))}
                </Stack>
              </div>
            )}
          </Stack>
          <ComposedChart
            width={1050}
            height={400}
            data={weeklyAnalysis ? weeklyBreakdown : monthlyBreakdown}
            margin={{
              top: 30,
              right: 20,
              bottom: 10,
              left: 20,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey={weeklyAnalysis ? "week" : "month"} tick={{ fontSize: 11 }} stroke={mode === "dark" ? "#fff" : "#000"} />
            <YAxis
              label="%"
              domain={[0 > minValue - 5 ? 0 : minValue - 5, Math.ceil(Math.ceil((maxValue + 5) * 100) / 100)]}
              stroke={mode === "dark" ? "#fff" : "#000"}
            />
            <Tooltip content={<CustomTooltip />} />
            <Line
              type="monotone"
              dataKey="downtime_percent"
              name="Hours in Percentage"
              stroke="#8884d8"
              strokeWidth={3}
              label={{ position: "top", fill: mode === "dark" ? "#fff" : "#000" }}
            />
            <Line type="monotone" dataKey="baseline" name="Baseline" stroke="red" strokeWidth={1.5} />
          </ComposedChart>
        </Paper>
        {/* <Grid container spacing={1} justifyContent="center" marginTop={2}>
          {formattedData.map((weekData) => (
            <Grid xs={12} sm={6} md={4} mb={2}>
              <Card sx={{ height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
                <CardContent>
                  <Typography variant="subtitle1" color="textSecondary">
                    {weeklyAnalysis ? `${weekData.week_start_date} - ${weekData.week_end_date}` : `${weekData.month}`}
                  </Typography>
                  <ul style={{ padding: 0 }}>{weekData.breakdownHours}</ul>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid> */}
      </Box>
    </Stack>
  );
};

export default Breakdown;
