import React, { useEffect, useState, useRef } from "react";
import api from "app/api";
import { Buffer } from "buffer";
import { CircularProgress } from "@mui/material";

const AuthorizedImage = ({ path, width, height, zoomLevel, onClick }) => {
  const [imageData, setImageData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [isDragging, setIsDragging] = useState(false);
  const [startPoint, setStartPoint] = useState({ x: 0, y: 0 });
  const imgRef = useRef(null);

  const onMouseDown = (e) => {
    setIsDragging(true);
    setStartPoint({ x: e.clientX - position.x, y: e.clientY - position.y });
  };

  const onMouseMove = (e) => {
    if (!isDragging) return;
    const x = e.clientX - startPoint.x;
    const y = e.clientY - startPoint.y;
    setPosition({ x, y });
  };

  const onMouseUp = () => {
    setIsDragging(false);
  };

  const onMouseLeave = () => {
    setIsDragging(false);
  };
  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await api.get(`/serviceapi/writeImageToClient?location=${path}`, {
          responseType: "arraybuffer",
        });
        const base64Data = Buffer.from(response.data, "binary").toString("base64");
        const imageDataUrl = `data:${response.headers["content-type"]};base64,${base64Data}`;
        setIsLoading(false);
        setImageData(imageDataUrl);
      } catch (error) {
        console.error("Error fetching image:", error);
        setImageData(null);
        setIsLoading(false);
      }
    };

    fetchImage();
  }, [path]);

  const imageStyle = {
    width: width || null,
    height: height || null,
    maxWidth: "100%",
    maxHeight: "100%",
    minWidth: 70,
    minHeight: 70,
  };
  if (isLoading)
    return (
      <div
        style={{
          textAlign: "center",
          paddingTop: 50,
          paddingBottom: 50,
        }}
      >
        <CircularProgress color="secondary" />
      </div>
    );
  return imageData ? (
    <div
      style={{
        textAlign: "center",
        userSelect: "none",
      }}
      onMouseMove={onMouseMove}
      onMouseUp={onMouseUp}
      onMouseLeave={onMouseLeave}
    >
      <div
        style={{
          display: "inline-block",
          overflow: "hidden",
          cursor: isDragging ? "grabbing" : "grab",
        }}
        onMouseDown={onMouseDown}
      >
        <img
          src={imageData}
          alt=""
          ref={imgRef}
          style={{
            ...imageStyle,
            transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`,
            transition: isDragging ? "none" : "transform 0.2s ease-in-out",
          }}
          draggable={false}
          onClick={onClick}
        />
      </div>
    </div>
  ) : null;
};

export default AuthorizedImage;
