export const Constants = {
  ticketStatus: {
    ASSIGNED: "Assigned",
    ON_HOLD: "On hold",
    ASSIGN_TECHNICIAN: "Assign Technician",
    COMPLETED: "Completed",
    PAYMENT_PENDING: "Payment pending",
    QUOTE_APPROVED: "Quote Approved",
    REQUEST_FEEDBACK: "Request Feedback",
  },

  //roles
  userRoles: {
    ROLE_ADMIN: "Admin",
    ROLE_MAINTENANCE_SUPERVISOR: "Supervisor",
    ROLE_OPERATOR: "Operator",
    ROLE_SERVICE_TECHNICIAN: "Technician",
    ROLE_STORES: "Stores",

  },
};
