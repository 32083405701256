import React, { useEffect, useState } from "react";
import { Container, Typography, Link, Box } from "@mui/material";
import styled from "@emotion/styled";
import LoginForm from "component/loginForm";
import { motion } from "framer-motion";
import { useSelector, useDispatch } from "react-redux";
import { useLocation, Navigate, Link as RouterLink } from "react-router-dom";
import { Links } from "utils/pageLinkNames";
import { reset } from "features/global/global.slice";

const RootStyle = styled("div")({
  background: "rgb(249, 250, 251)",
  height: "100vh",
  display: "grid",
  placeItems: "center",
});

const HeadingStyle = styled(Box)({
  textAlign: "center",
});

const ContentStyle = styled("div")({
  maxWidth: 480,
  padding: 25,
  margin: "auto",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  background: "#fff",
  boxShadow: "rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px",
});

let easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
  initial: {
    y: 60,
    opacity: 0,
    transition: { duration: 0.6, ease: easing },
  },
  animate: {
    y: 0,
    opacity: 1,
    transition: {
      duration: 0.6,
      ease: easing,
    },
  },
};

const Login = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const { appConstants, isLoading } = useSelector((state) => state.service);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!isAuthenticated) dispatch(reset());
  }, [dispatch, isAuthenticated]);

  useEffect(() => {
    if (Object.keys(appConstants).length && user) setLoading(false);
  }, [dispatch, appConstants, user]);

  if (isAuthenticated && !loading) {
    if (user.roles.includes(appConstants?.allTypes?.USER_ROLES?.ROLE_MAINTENANCE_SUPERVISOR)) {
      return <Navigate to={`/${Links.PLANT_OVERVIEW}`} state={{ from: location }} replace />;
    } else if (user.roles.includes(appConstants?.allTypes?.USER_ROLES?.ROLE_FINANCE)) {
      return <Navigate to={`/${Links.ASSETS}`} state={{ from: location }} replace />;
    } else if (user.roles.includes(appConstants?.allTypes?.USER_ROLES?.ROLE_STORES)) {
      return <Navigate to={`/${Links.SPARES_REQUIRED}`} state={{ from: location }} replace />;
    } else if (user.roles.includes(appConstants?.allTypes?.USER_ROLES?.ROLE_SERVICE_TECHNICIAN)) {
      return <Navigate to={`/${Links.ALL_SERVICE_TICKETS}`} state={{ from: location }} replace />;
    } else {
      return <Navigate to={`/${Links.USER}`} state={{ from: location }} replace />;
    }
  }

  return (
    <RootStyle>
      <Container maxWidth="sm">
        <ContentStyle>
          <HeadingStyle component={motion.div} {...fadeInUp}>
            <Box>
              <Link to="/">
                <Box
                  sx={{ width: 300, resizeMode: "contain", mt: 5 }}
                  component="img"
                  src={require("app/assets/images/customer_logo.png")}
                  alt="logo"
                />
              </Link>
            </Box>
            <Typography sx={{ color: "text.secondary", my: 3 }}>Login to your account</Typography>
          </HeadingStyle>

          <LoginForm />

        
        </ContentStyle>
      </Container>
    </RootStyle>
  );
};

export default Login;
